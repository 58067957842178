import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData, ChartOptions } from 'chart.js';
import { Stat } from '../../models/reportModels';
import { User } from '../../models/userModels';

ChartJS.register(ArcElement, Tooltip, Legend);

const options: ChartOptions<'doughnut'> = {
  cutout: '70%',
  plugins: {
    legend: {
      display: false,
    }
  },
  maintainAspectRatio: false,
}

type DoughnutChartProps = {
  stats: Stat,
  user: User
}

const getRoleSpecificData = (role: string, stats: Stat) => {
  switch (role) {
    case "trainee": return {
      labels: ['erledigt', 'eingereicht', 'abgelehnt', 'offen'],
      colors: [
        'rgba(10, 226, 173, 1)',
        'rgba(0, 141, 201, 1)',
        'rgba(255, 125, 35, 1)',
        'rgba(240, 186, 24, 1)',
      ],
      data: [stats.completed, stats.submitted, stats.dismissed, stats.open],
      ariaLabel: "Ringdiagramm über die Verteilung der Status 'erledigt', 'eingereicht', 'abgelehnt' und 'offen'"
    }
    default: return {
      labels: ['unkontrolliert', 'abgelehnt', 'offen'],
      colors: [
        'rgba(0, 141, 201, 1)',
        'rgba(255, 125, 35, 1)',
        'rgba(240, 186, 24, 1)',
      ],
      data: [stats.submitted, stats.dismissed, stats.open],
      arialabel: "Ringdiagramm über die Verteilung der Status 'unkontrolliert', 'abgelehnt' und 'offen'"
    }
  }
}

const DoughnutChart: React.FC<DoughnutChartProps> = ({ stats, user }: DoughnutChartProps) => {
  const userSpecificData = getRoleSpecificData(user.role, stats)

  let data: ChartData<'doughnut'> = {
    labels: userSpecificData.labels,
    datasets: [
      {
        data: userSpecificData.data,
        backgroundColor: userSpecificData.colors,
        borderWidth: 0,
      },
    ]
  }

  let ariaLabel = userSpecificData.ariaLabel

  let todoAmount = stats.open + stats.dismissed;

  /* return (
    <div className="doughnutContainer">
        <Doughnut data={data} options={options} role='img' aria-label={ariaLabel} updateMode='resize'/>
        <div className="doughnutInner">
          <div>{user && user.role === "trainee" ? todoAmount : stats.submitted}</div>
          <div>Einträge zu <br />bearbeiten</div>
        </div>
    </div>
  ) */

  if(user.role === null){
    return (<div>Test</div>)
  }

  return (
    <div className="testDoughnutContainerOuter">
      <div className="testDoughnutContainerInner">
        <Doughnut data={data} options={options} role='img' aria-label={ariaLabel} />
      </div>
      <div className="doughnutInner">
        <div>{user && user.role === "trainee" ? todoAmount : stats.submitted}</div>
        <div>Einträge zu <br />bearbeiten</div>
      </div>

    </div>
  )
}

export default DoughnutChart;