import { IonAvatar, IonItem } from "@ionic/react";
import { User } from "../../../models/userModels";
import "./TraineeEntry.scss";
import AzubiProgressBar from "../azubiProgressBar/AzubiProgressBar";
import { mapAzubiStateName, stateList } from "../../../util/report-helper";

interface TraineeEntryProps {
    trainee: User
}

const TraineeEntry: React.FC<TraineeEntryProps> = ({ trainee }: TraineeEntryProps) => {

    return (
        <IonItem routerLink={`/tabs/azubis/${trainee.id}`} lines="none">
            <div className="traineeEntry">
                <div className="traineeFirstRow">
                    <div className="profile">
                        <IonAvatar>
                            <img src={trainee.image ? trainee.image : "./assets/images/profile_placeholder.jpg"} alt="Profilbild" />
                        </IonAvatar>
                        <div className="info">
                            <div className="name">{trainee.first_name} {trainee.name}</div>
                            <div className="attributeRow">
                                {trainee.job && <div>{trainee.job}</div>}
                                {trainee.trainee_year !== 0 && <div>{trainee.trainee_year}. Lehrjahr</div>}
                                {trainee.location_id && <div>{trainee.location_name}</div>}
                                {trainee.department_id && <div>{trainee.department_name}</div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: 10, marginBottom: 7 }}>
                    {trainee.stats && <AzubiProgressBar data={trainee.stats} />}
                </div>
                <div className="progressBarDescription">
                    {stateList.map(state => {
                        return (
                            <div key={state}><b>{trainee.stats && trainee.stats[state]}</b> {mapAzubiStateName(state)}</div>
                        )
                    })}
                </div>
            </div>
        </IonItem>
    )
}

export default TraineeEntry;
