import { CapacitorHttp, HttpOptions, HttpResponse } from "@capacitor/core";
import baseHttpOptions from "../../util/http-common";

const getTrainees = async (group: string, sort: string) => {
  let url: string = `report-data-list-for-trainer-v2?group=${group}&sort=${sort}`;

  const options: HttpOptions = await baseHttpOptions(url);
  const response: HttpResponse = await CapacitorHttp.get(options);

  const { data } = response;
  //console.log(data)
  return data;
};

const getTraineesWithOpenEntries = async () => {
  const options: HttpOptions = await baseHttpOptions(
    `report-data-list-for-trainer`
  );
  const response: HttpResponse = await CapacitorHttp.get(options);

  const { data } = response;
  //console.log(data)
  return data;
};

const TraineeService = {
  getGroupedAndSortedTrainees: getTrainees,
  getTrainees: getTraineesWithOpenEntries
};

export default TraineeService;
