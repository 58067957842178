import { createSlice } from "@reduxjs/toolkit";
import { ReportDay } from "../../models/reportModels";
/* import type { RootState } from "./store"; */

/* import { ReportDay } from "../../models/reportModels"; */
/* import ReportService from "../services/ReportService"; */

const initialState = {
  reportDay: {
    created_at: "",
    date: "",
    entries: [],
    id: null,
    report_week_id: null,
    updated_at: "",
    user_id: null,
  } as ReportDay,
};

export const reportDaySlice = createSlice({
  name: "reportDay",
  initialState,
  reducers: {
    setReportDay: (state, action) => {
      state.reportDay = action.payload;
    },
    /* resetReportDay: (state) => {
      state.reportDay = null;
    }, */
    addEntry: (state, action) => {
      state.reportDay.entries.push(action.payload);
    },
    editEntry: (state, action) => {
      let index = state.reportDay.entries.findIndex(
        (entry) => entry.id === action.payload.id
      );
      if (index !== -1) {
        let temporaryarray = state.reportDay.entries.slice();
        temporaryarray[index] = action.payload;
        state.reportDay.entries = temporaryarray;
      }
    },
    deleteEntry: (state, action) => {
      let newEntries = state.reportDay.entries.filter((entry) => {
        return entry.id !== action.payload.id;
      });
      state.reportDay.entries = newEntries;
    },
  },

});

export const { setReportDay, addEntry, deleteEntry, editEntry } =
  reportDaySlice.actions;

export default reportDaySlice.reducer;
