import React, { useRef, useState } from "react";
import { RouteComponentProps } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../data/redux/hooks";
import { IonAvatar, IonButton, IonCard, IonCardContent, IonCol, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPage, IonRow, useIonViewWillLeave } from "@ionic/react";
import { BackButtonRow, ImageCropper, PageContainer } from "../../../components";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { copyRequiredUserData } from "../../../util/profile-helper";
import { changePassword, changeProfileImage, editUser } from "../../../data/redux/userSlice";
import { cameraOutline, eyeOffOutline, eyeOutline, imageOutline, personOutline, trashBinOutline } from "ionicons/icons";
import { isValidPassword } from "../../../util/helper";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import DeleteUserModal from "./components/DeleteUserModal";


interface AccountSettingsProps extends RouteComponentProps { };

interface AccountFormData {
    name: string,
    first_name: string
}

const UserDataForm: React.FC = () => {
    const { user } = useAppSelector((state) => state.user)
    const { control, handleSubmit, formState: { errors } } = useForm<AccountFormData>({
        mode: "onTouched",
        reValidateMode: "onChange",
        defaultValues: {
            name: (user && user.name) ? user.name : "",
            first_name: (user && user.first_name) ? user.first_name : "",
        }
    });
    const dispatch = useAppDispatch();

    const onSubmit: SubmitHandler<AccountFormData> = (data: AccountFormData) => {
        console.log(data)
        let userClone = copyRequiredUserData(user!);
        userClone.name = data.name;
        userClone.first_name = data.first_name;

        dispatch(editUser(userClone))
    }

    return (
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            {/* Vorname */}
            <IonItem className="settingsInput">
                <Controller
                    name="first_name"
                    control={control}
                    rules={{
                        required: { value: true, message: (user && user.role === "trainee") ? "Bitte gib einen Vornamen an" : "Bitte geben Sie einen Vornamen an" },
                    }}
                    render={({ field }) => <IonInput
                        label="Vorname"
                        labelPlacement="floating"
                        type="text"
                        value={field.value}
                        onIonChange={(e) => field.onChange(e.detail.value)}
                        onIonBlur={() => field.onBlur()}

                    />}
                />
            </IonItem>
            <div className="errorText">{errors.first_name?.message}</div>

            {/* Nachname */}
            <IonItem className="settingsInput">
                <Controller
                    name="name"
                    control={control}
                    rules={{
                        required: { value: true, message: (user && user.role === "trainee") ? "Bitte gib einen Nachnamen an" : "Bitte geben Sie einen Nachnamen an" },
                    }}
                    render={({ field }) => <IonInput
                        label="Nachname"
                        labelPlacement="floating"
                        type="text"
                        value={field.value}
                        onIonChange={(e) => field.onChange(e.detail.value)}
                        onIonBlur={() => field.onBlur()}

                    />}
                />
            </IonItem>
            <div className="errorText">{errors.name?.message}</div>

            <div className="ion-text-right">
                <IonButton type="submit">speichern</IonButton>
            </div>

        </form>
    )
}

interface ChangePasswordFormData {
    old_password: string | null | undefined,
    new_password1: string | null | undefined,
    new_password2: string | null | undefined
}

const ChangePasswordForm: React.FC = () => {
    const { control, handleSubmit, formState: { errors }, watch, reset } = useForm<ChangePasswordFormData>({
        mode: "onTouched",
        reValidateMode: "onChange",
        defaultValues: {
            old_password: "",
            new_password1: "",
            new_password2: ""
        }
    });
    const dispatch = useAppDispatch();
    const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
    const [showNewPassword1, setShowNewPassword1] = useState<boolean>(false);
    const [showNewPassword2, setShowNewPassword2] = useState<boolean>(false);

    const onSubmit: SubmitHandler<ChangePasswordFormData> = (data: ChangePasswordFormData) => {
        //console.log(data)
        dispatch(changePassword(data));
    }

    useIonViewWillLeave(() => {
        reset();
    });

    const handlePasswordValidation = (password: string) => {
        const isValid = isValidPassword(password);

        if (isValid) {
            return isValid;
        } else {
            return "Das Passwort muss mindestens einen Buchstaben und eine Zahl enthalten"
        }
    }

    const handlePasswordConfirmValidation = (passwordConfirm: string) => {
        if (watch("new_password1") !== passwordConfirm) {
            return "Die Passwörter stimmen nicht überein"
        }
    }

    return (
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

            {/* altes Password */}
            <IonItem className="registerInput">
                <Controller
                    name="old_password"
                    control={control}
                    rules={{
                        required: { value: true, message: "Feld darf nicht leer sein" },
                    }}

                    render={({ field }) => <IonInput
                        label="Altes Passwort"
                        labelPlacement="floating"
                        aria-invalid={!!errors.old_password}
                        value={field.value}
                        type={showOldPassword ? "text" : "password"}
                        onIonChange={(e) => field.onChange(e.detail.value)}
                        onIonBlur={() => field.onBlur()}
                        clearOnEdit={false}
                    />}
                />

                <IonButton fill="clear" aria-label="Passwort anzeigen" onClick={() => setShowOldPassword(!showOldPassword)}>
                    <IonIcon color="dark" aria-hidden="true" icon={showOldPassword ? eyeOffOutline : eyeOutline} />
                </IonButton>
            </IonItem>
            <div className="errorText">{errors.old_password?.message}</div>

            {/* neues Passwort */}
            <IonItem className="registerInput">
                <Controller
                    name="new_password1"
                    control={control}
                    rules={{
                        required: { value: true, message: "Feld darf nicht leer sein" },
                        /* @ts-ignore */
                        validate: { value: handlePasswordValidation },
                        minLength: { value: 8, message: "Das Passwort muss mindestens 8 Zeichen lang sein" }

                    }}

                    render={({ field }) => <IonInput
                        label="Neues Passwort"
                        labelPlacement="floating"
                        aria-invalid={!!errors.new_password1}
                        type={showNewPassword1 ? "text" : "password"}
                        clearOnEdit={false}
                        onIonChange={(e) => field.onChange(e.detail.value)}
                        onIonBlur={() => field.onBlur()}

                    />}
                />

                <IonButton fill="clear" aria-label="Passwort anzeigen" onClick={() => setShowNewPassword1(!showNewPassword1)}>
                    <IonIcon color="dark" aria-hidden="true" icon={showNewPassword1 ? eyeOffOutline : eyeOutline} />
                </IonButton>
            </IonItem>
            {errors.new_password1?.message ? <div className="errorText">{errors.new_password1?.message}</div> : <div className="errorText" style={{ color: "var(--ion-color-medium)" }}>Erlaubte Sonderzeichen @$!%*#?&_</div>}

            {/* neues Passwort Confirmation */}
            <IonItem className="registerInput">
                <Controller
                    name="new_password2"
                    control={control}
                    rules={{
                        required: { value: true, message: "Feld darf nicht leer sein" },
                        /* @ts-ignore */
                        validate: { handlePasswordConfirmValidation }
                    }}

                    render={({ field }) => <IonInput
                        label="Neues Passwort wiederholen"
                        labelPlacement="floating"
                        aria-invalid={!!errors.new_password1}
                        type={showNewPassword2 ? "text" : "password"}
                        onIonChange={(e) => field.onChange(e.detail.value)}
                        onIonBlur={() => field.onBlur()}
                        clearOnEdit={false}
                    />}
                />

                <IonButton fill="clear" aria-label="Passwort anzeigen" onClick={() => setShowNewPassword2(!showNewPassword2)}>
                    <IonIcon color="dark" aria-hidden="true" icon={showNewPassword2 ? eyeOffOutline : eyeOutline} />
                </IonButton>
            </IonItem>
            <div className="errorText">{errors.new_password2?.message}</div>



            <div className="ion-text-right">
                <IonButton type="submit">Passwort ändern</IonButton>
            </div>
        </form>
    )
}

const AccountSettings: React.FC<AccountSettingsProps> = () => {
    const { user, updating } = useAppSelector((state) => state.user);
    const modal = useRef<HTMLIonModalElement>(null);
    const dispatch = useAppDispatch();
    const [isSheetOpen, setIsSheetOpen] = useState<boolean>(false)
    const [imageCropperOpen, setImageCropperOpen] = useState<boolean>(false)
    const [image, setImage] = useState<any>();
    const [deleteUserModalOpen, setDeleteUserModalOpen] = useState<boolean>(false);


    const takePhoto = async (fromCamera: boolean) => {
        setIsSheetOpen(false)
        try {
            const capture = await Camera.getPhoto({
                resultType: CameraResultType.DataUrl,
                source: fromCamera ? CameraSource.Camera : CameraSource.Photos,
                quality: 100,
            });
            setImage(capture.dataUrl)
            setImageCropperOpen(true)
        } catch (err) {
            console.log(err)
        }

    }

    const imageCropperCallback = async (img: string) => {
        let blob = await fetch(img).then(r => r.blob());
        dispatch(changeProfileImage({ id: user!.id, image: blob }));
        setImageCropperOpen(false)
    }

    const removeProfileImage = () => {
        dispatch(changeProfileImage({ id: user!.id }))
        setIsSheetOpen(false)
    }

    return (
        <IonPage>
            <PageContainer name="Einstellungen" backButton>
                <>
                    <IonGrid>
                        <BackButtonRow path="/tabs/profil" />
                        <IonRow>
                            {/* Profilbild */}
                            <IonCol size="12">
                                <IonCard className="changeProfileCard">
                                    <IonCardContent>

                                        <div className="inner">
                                            <IonAvatar>
                                                <img alt="Profilbild" src={user?.image ? user.image : "./assets/images/profile_placeholder.jpg"}/* src="" */ />
                                            </IonAvatar>
                                        </div>
                                        <IonItem button onClick={() => setIsSheetOpen(true)} detail={false}>
                                            <IonLabel>
                                                Profilbild bearbeiten
                                            </IonLabel>
                                            <IonIcon icon={personOutline} slot="start" />
                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>

                            {/* Vorname & Nachname */}
                            <IonCol size="12">
                                <IonCard>
                                    <IonCardContent>
                                        <IonLabel>
                                            <h3><b>Profil bearbeiten</b></h3>
                                            <p>{(user && user.role === "trainee") ? "Hier kannst du deinen Vor- und Nachnamen bearbeiten." : "Hier können Sie Ihren Vor- und Nachnamen bearbeiten."}</p>
                                        </IonLabel>
                                        <UserDataForm />
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>

                            {/* Passwort */}
                            <IonCol size="12">
                                <IonCard>
                                    <IonCardContent>
                                        <IonLabel>
                                            <h3><b>Passwort ändern</b></h3>
                                        </IonLabel>
                                        <ChangePasswordForm />
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>


                            {/* Benutzer löschen */}
                            {user && user.role === "trainee" && <IonCol size="12">
                                <IonCard>
                                    <IonCardContent>
                                        <IonLabel>
                                            <h3><b>Account löschen</b></h3>
                                        </IonLabel>
                                        <p>Gefahrenzone: Hier kannst du deinen Account unwiderruflich löschen. Dabei werden all deine Daten vollständig und dauerhaft entfernt. Dieser Schritt kann nicht rückgängig gemacht werden. Zur Bestätigung musst du dein Passwort eingeben.</p>
                                        <div className="ion-text-right">
                                            <IonButton color="danger" onClick={() => setDeleteUserModalOpen(true)}>Account löschen</IonButton>
                                        </div>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>}
                        </IonRow>
                    </IonGrid>

                    <IonLoading
                        isOpen={updating}
                        message="Lädt..."
                    />

                    <IonModal className="changeProfileImageSheet" ref={modal} isOpen={isSheetOpen} onDidDismiss={() => setIsSheetOpen(false)} initialBreakpoint={1} breakpoints={[0, 1]}>
                        <div className="block ion-padding">
                            <IonList>
                                <IonItem button detail={false} onClick={() => takePhoto(true)}>
                                    <IonIcon slot="start" icon={cameraOutline} />
                                    <IonLabel>Foto aufnehmen</IonLabel>
                                </IonItem>
                                <IonItem button detail={false} onClick={() => takePhoto(false)}>
                                    <IonIcon slot="start" icon={imageOutline} />
                                    <IonLabel>Foto aus Galerie</IonLabel>
                                </IonItem>
                                <IonItem button detail={false} onClick={removeProfileImage}>
                                    <IonIcon slot="start" icon={trashBinOutline} />
                                    <IonLabel>Foto entfernen</IonLabel>
                                </IonItem>
                            </IonList>
                        </div>
                    </IonModal>

                    <ImageCropper
                        isOpen={imageCropperOpen}
                        setIsOpen={setImageCropperOpen}
                        img={image}
                        imageCropperCallback={imageCropperCallback}
                    />

                    <DeleteUserModal isOpen={deleteUserModalOpen} setIsOpen={setDeleteUserModalOpen} />

                </>
            </PageContainer>
        </IonPage>
    )
}

export default AccountSettings;