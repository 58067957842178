import { CapacitorHttp, HttpOptions, HttpResponse } from "@capacitor/core";
import baseHttpOptions from "../../util/http-common";
import { Preferences } from "@capacitor/preferences";

const getCurrentUser = async () => {
  const options: HttpOptions = await baseHttpOptions("current-user");
  const response: HttpResponse = await CapacitorHttp.get(options);

  const { data } = response;
  return data;
};

const editUser = async (reqData: any) => {
  const options: HttpOptions = await baseHttpOptions("update-user");
  options.data = reqData;

  const response: HttpResponse = await CapacitorHttp.post(options);

  const { data } = response;
  return data;
};

const inviteTrainee = async (reqData: any) => {
  const options: HttpOptions = await baseHttpOptions("invite");
  options.data = reqData;

  const response: HttpResponse = await CapacitorHttp.post(options);

  const { data } = response;
  return data;
};

const deleteTraineeInvitation = async (reqData: any) => {
  const options: HttpOptions = await baseHttpOptions("invite");

  options.data = reqData;

  const response: HttpResponse = await CapacitorHttp.delete(options);
  const { data } = response;
  return data;
}

const connectTraineeToCompany = async (reqData: any) => {
  const options: HttpOptions = await baseHttpOptions("connect-user-to-company");
  options.data = reqData;

  const response: HttpResponse = await CapacitorHttp.post(options);

  const { data } = response;
  return data;
}

const changePassword = async (reqData: any) => {
  const options: HttpOptions = await baseHttpOptions("password-update");
  options.data = reqData;

  const response: HttpResponse = await CapacitorHttp.post(options);

  const { data } = response;
  return data;
}

const changeProfileImage = async (reqData: any) => {
  const { value } = await Preferences.get({ key: 'token' })

  const options: HttpOptions = await baseHttpOptions("profile-image");
  options.data = reqData;
  options.dataType = "file";

  options.headers = {
    "Content-Type": "multipart/form-data",
    'Authorization': `Bearer ${value}`
  }

  const response: HttpResponse = await CapacitorHttp.post(options);

  const { data } = response;
  return data;
}

const deleteUser = async (reqData: any) => {
  const options: HttpOptions = await baseHttpOptions("delete-user-with-all-data");
  options.data = reqData;

  const response: HttpResponse = await CapacitorHttp.delete(options);

  const { data } = response;
  return data;
}


const UserService = {
  getCurrentUser,
  editUser,
  inviteTrainee,
  connectTraineeToCompany,
  changePassword,
  changeProfileImage,
  deleteTraineeInvitation,
  deleteUser
};

export default UserService;
