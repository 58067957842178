import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonIcon, IonItemDivider, IonItemGroup, IonLabel, IonList, IonLoading, IonPage, IonRow, IonSelect, IonSelectOption } from "@ionic/react";
import { RouteComponentProps } from "react-router";
import { PageContainer, TraineeEntry, TraineeEntryMobil } from "../../components";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../data/redux/hooks";
import { getGroupedAndSortedTrainees } from "../../data/redux/traineeListSlice";
import { filterOutline } from "ionicons/icons";
import FilterModal from "./components/FilterModal";

const TraineesPreview: React.FC<RouteComponentProps> = () => {
    const [group, setGroup] = useState("");
    const [sort, setSort] = useState("first_name");
    const { groupedAndSortedTrainees: groupedTrainees, loading } = useAppSelector((state) => state.traineeList)
    const dispatch = useAppDispatch();
    const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false)


    useEffect(() => {
        dispatch(getGroupedAndSortedTrainees({ group: group, sort: sort }))
    }, [group, sort, dispatch])


    return (
        <IonPage>
            <PageContainer name="Meine Azubis" backButton={false}>
                <>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <IonCard>
                                    <IonCardHeader>
                                        <div className="reportDesktopHeader">
                                            <div>
                                                <IonCardTitle className='headline'>Meine Azubis</IonCardTitle>
                                            </div>
                                            <div className="filterTraineesBtn">
                                                <IonButton fill="clear" color="dark" onClick={() => setFilterModalOpen(true)}>
                                                    <IonIcon icon={filterOutline} />
                                                </IonButton>
                                            </div>
                                            <div className="headerSelects">
                                                {/* Gruppierung */}
                                                <IonSelect
                                                    interface="popover"
                                                    placeholder="Gruppierung"
                                                    aria-label="Gruppierung"
                                                    onIonChange={(ev) => setGroup(ev.detail.value)}
                                                    value={group}
                                                >
                                                    <IonSelectOption value="">Keine Gruppierung</IonSelectOption>
                                                    <IonSelectOption value="job">Berufsgruppe</IonSelectOption>
                                                    <IonSelectOption value="trainee_year">Lehrjahr</IonSelectOption>
                                                    <IonSelectOption value="location_id">Standort</IonSelectOption>
                                                    <IonSelectOption value="department_id">Abteilung</IonSelectOption>
                                                </IonSelect>

                                                {/* Sortierung */}
                                                <IonSelect
                                                    interface="popover"
                                                    placeholder="Sortierung"
                                                    aria-label="Sortierung"
                                                    value={sort}
                                                    onIonChange={(ev) => setSort(ev.detail.value)}
                                                >
                                                    <IonSelectOption value="submitted">Einträge unkontrolliert</IonSelectOption>
                                                    <IonSelectOption value="open">Einträge offen</IonSelectOption>
                                                    <IonSelectOption value="first_name">Name alphabetisch</IonSelectOption>
                                                </IonSelect>
                                            </div>
                                        </div>

                                    </IonCardHeader>
                                    <IonCardContent>
                                        {groupedTrainees.length === 0 && <p>Ihnen sind derzeit keine Azubis zugeordnet.</p>}
                                        <IonList className="ion-hide-md-down">
                                            {groupedTrainees.map((groupedTrainee) => {
                                                return (
                                                    <IonItemGroup key={groupedTrainee.group_name}>
                                                        {groupedTrainee.group_name &&
                                                            <IonItemDivider>
                                                                <IonLabel>{groupedTrainee.group_name}</IonLabel>
                                                            </IonItemDivider>
                                                        }
                                                        {groupedTrainee.users.map(trainee =>
                                                            <TraineeEntry key={trainee.id} trainee={trainee} />
                                                        )}
                                                    </IonItemGroup>
                                                )
                                            })}
                                        </IonList>

                                        <IonList className="ion-hide-md-up">
                                            {groupedTrainees.map((groupedTrainee) => {
                                                return (
                                                    <IonItemGroup key={groupedTrainee.group_name}>
                                                        {groupedTrainee.group_name &&
                                                            <IonItemDivider>
                                                                <IonLabel>{groupedTrainee.group_name}</IonLabel>
                                                            </IonItemDivider>
                                                        }
                                                        {groupedTrainee.users.map(trainee =>
                                                            <TraineeEntryMobil key={trainee.id} trainee={trainee} />
                                                        )}
                                                    </IonItemGroup>
                                                )
                                            })}
                                        </IonList>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <IonLoading isOpen={loading} message={"Lädt..."} />
                    <FilterModal
                        isOpen={filterModalOpen}
                        setIsOpen={setFilterModalOpen}
                        group={group}
                        setGroup={setGroup}
                        sort={sort}
                        setSort={setSort}
                    />
                </>
            </PageContainer>
        </IonPage>
    )
}

export default TraineesPreview;