import { createSlice } from "@reduxjs/toolkit";
import { NetworkError } from "../../models/errorModel";


const initialState = {
    error: {
        isOpen: false,
        message: "",
        moreInfo: ""
    } as NetworkError
};



export const networkErrorSlice = createSlice({
    name: "networkError",
    initialState,
    reducers: {
        setError: (state, action) => {
            state.error = action.payload;
        },
    },
});



export const { setError } =
    networkErrorSlice.actions;

export default networkErrorSlice.reducer;