import { CapacitorHttp, HttpOptions, HttpResponse } from "@capacitor/core";
import baseHttpOptions from "../../util/http-common";

const getMessages = async (amount?: number, read?: number) => {
  let url = "messages";

  if (amount !== undefined) {
    url = url + `?amount=${amount}`;
    if (read !== undefined) {
      url = url + `&read=${read}`;
    }
  } else {
    if (read !== undefined) {
      url = url + `?read=${read}`;
    }
  }

  const options: HttpOptions = await baseHttpOptions(url);
  const response: HttpResponse = await CapacitorHttp.get(options);

  const { data } = response;
  return data;
};

const markAsRead = async (id: number, amount = 3, read = 0) => {
  const url = `message-read`;
  const options: HttpOptions = await baseHttpOptions(url);

  options.data = {
    id: id,
    amount: amount,
    read: read,
  };

  const response: HttpResponse = await CapacitorHttp.post(options);

  const { data } = response;
  return data;
};

const MessageService = {
  getMessages,
  markAsRead,
};

export default MessageService;
