import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonImg, IonInput, IonItem, IonList, IonLoading, IonPage, IonRouterLink, IonRow, IonToast } from "@ionic/react";
import { RouteComponentProps } from "react-router";
import { RoundedButton } from "../../../components";
import { useState } from "react";
import { Toast } from "../../../models/helperModels";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { ResetPasswordData, ResetPasswordFormData } from "../../../models/userModels";
import AuthService from "../../../data/services/AuthService";
import { isValidEmail, isValidPassword } from "../../../util/helper";
import { eyeOffOutline, eyeOutline } from "ionicons/icons";
import Footer from "../components/Footer";
import { error500Text } from "../../../util/error-helper";

interface ResetPasswordProps extends RouteComponentProps<{
    passwordForgottenToken: string
}> { }

const ResetPassword: React.FC<ResetPasswordProps> = ({ match, history }) => {
    const [toast, setToast] = useState<Toast>({ isOpen: false, message: "", color: "danger" })
    const { control, handleSubmit, formState: { errors }, watch } = useForm<ResetPasswordFormData>({
        mode: "onTouched",
        reValidateMode: "onChange"
    });
    const { passwordForgottenToken } = match.params;
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showPasswordConfirmation, setShowPasswordConfirmation] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const onSubmit: SubmitHandler<ResetPasswordFormData> = async (data: ResetPasswordFormData) => {
        //console.log(data)
        setLoading(true)
        const dataToSubmit: ResetPasswordData = {
            ...data, ...{ password_forgotten_token: passwordForgottenToken }
        }

        try {
            const res = await AuthService.resetPassword(dataToSubmit);
            if (res.error) {
                console.log(res.error)
                setToast({ isOpen: true, message: res.error, color: "danger" })
            } else if (res.success) {
                //TODO
                setToast({ isOpen: true, message: res.success, color: "success" })
                history.push("/login")
            }
        } catch (err) {
            console.log(err)
            setToast({ isOpen: true, message: error500Text, color: "danger" })
        } finally {
            setLoading(false);
        }
    };

    const handleEmailValidation = (email: string) => {
        const isValid = isValidEmail(email);

        if (isValid) {
            return isValid;
        } else {
            return "Keine gültige E-Mail Adresse"
        }
    };

    const handlePasswordValidation = (password: string) => {
        const isValid = isValidPassword(password);

        if (isValid) {
            return isValid;
        } else {
            return "Das Password muss mindestens einen Buchstaben und eine Zahl enthalten"
        }
    }

    const handlePasswordConfirmValidation = (passwordConfirm: string) => {
        if (watch("password") !== passwordConfirm) {
            return "Die Passwörter stimmen nicht überein"
        }
    }

    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <div className="centeredPreAuthContainer">
                                <div className="imageContainer">
                                    <IonImg
                                        src="./assets/images/logo_woodbook_login_dark.png"
                                        alt="Woodbook Logo"
                                    ></IonImg>
                                </div>
                                <div className="centeredPreAuthContent">
                                    <h3>Passwort zurücksetzen</h3>
                                    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                                        <IonList lines="inset" className="inputList">
                                            <IonItem className="centeredPreAuthInput">
                                                <Controller
                                                    name="email"
                                                    control={control}
                                                    rules={{
                                                        required: { value: true, message: "Bitte gib eine E-Mail-Adresse an" },
                                                        validate: { value: handleEmailValidation }
                                                    }}

                                                    render={({ field }) => <IonInput
                                                        label="E-Mail"
                                                        labelPlacement="floating"
                                                        type="email"
                                                        aria-invalid={!!errors.email}
                                                        /* @ts-ignore */
                                                        onIonChange={(e) => field.onChange(e.detail.value)}
                                                        onIonBlur={() => field.onBlur()}
                                                    />}
                                                />
                                            </IonItem>
                                            <div className="errorText">{errors.email && errors.email.message}</div>


                                            {/* Password */}
                                            <IonItem className="registerInput">
                                                <Controller
                                                    name="password"
                                                    control={control}
                                                    rules={{
                                                        required: { value: true, message: "Bitte gib ein Passwort ein" },
                                                        validate: { value: handlePasswordValidation },
                                                        minLength: { value: 8, message: "Das Passwort muss mindestens 8 Zeichen lang sein" }
                                                    }}

                                                    render={({ field }) => <IonInput
                                                        label="Passwort*"
                                                        labelPlacement="floating"
                                                        aria-invalid={!!errors.password}
                                                        type={showPassword ? "text" : "password"}
                                                        /* @ts-ignore */
                                                        onIonChange={(e) => field.onChange(e.detail.value)}
                                                        onIonBlur={() => field.onBlur()}
                                                    />}
                                                />

                                                <IonButton fill="clear" aria-label="Passwort anzeigen" onClick={() => setShowPassword(!showPassword)}>
                                                    <IonIcon color="dark" aria-hidden="true" icon={showPassword ? eyeOffOutline : eyeOutline} />
                                                </IonButton>
                                            </IonItem>
                                            <div className="errorText">{errors.password?.message}</div>


                                            {/* Password Confirmation */}
                                            {/* Password */}
                                            <IonItem className="registerInput">
                                                <Controller
                                                    name="password_confirmation"
                                                    control={control}
                                                    rules={{
                                                        required: { value: true, message: "Bitte bestätige dein Passwort" },
                                                        validate: { handlePasswordConfirmValidation }
                                                    }}
                                                    render={({ field }) => <IonInput
                                                        label="Passwort bestätigen*"
                                                        labelPlacement="floating"
                                                        aria-invalid={!!errors.password_confirmation}
                                                        type={showPasswordConfirmation ? "text" : "password"}
                                                        /* @ts-ignore */
                                                        onIonChange={(e) => field.onChange(e.detail.value)}
                                                        onIonBlur={() => field.onBlur()}
                                                    />}
                                                />

                                                <IonButton fill="clear" aria-label="Passwort anzeigen" onClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)}>
                                                    <IonIcon color="dark" aria-hidden="true" icon={showPasswordConfirmation ? eyeOffOutline : eyeOutline} />
                                                </IonButton>
                                            </IonItem>
                                            <div className="errorText">{errors.password_confirmation?.message}</div>




                                        </IonList>

                                        {/* Workaround weil shadow dom submit button onEnter kein submit event abfeuert */}
                                        <input type="submit" className="submit-enter" />

                                        <RoundedButton type="submit">neues Passwort setzen</RoundedButton>
                                        <div style={{ marginTop: 30 }}>
                                            <IonRouterLink color="dark" routerLink="/login">Zurück zum Login</IonRouterLink>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>

                <Footer />

                <IonLoading
                    isOpen={loading}
                    message="Lädt..."
                />

                <IonToast
                    duration={3000}
                    message={toast.message}
                    isOpen={toast.isOpen}
                    color={toast.color}
                    onDidDismiss={() => setToast({ isOpen: false, message: "", color: "danger" })}
                />
            </IonContent>
        </IonPage>
    )
}

export default ResetPassword;