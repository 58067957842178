import { IonButton, IonCol, IonIcon, IonRow } from "@ionic/react"
import { arrowBackOutline } from "ionicons/icons"
import { ReactNode } from "react"
import { useHistory } from "react-router"

interface BackButtonRowProps {
    path?: string,
    children?: ReactNode
}

const BackButtonRow: React.FC<BackButtonRowProps> = ({ path, children }: BackButtonRowProps) => {
    const history = useHistory();
    return (
        <IonRow className="ion-hide-lg-down" style={{ margin: "6px 10px 0 10px" }}>
            <IonCol>
                {path ? <IonButton fill="outline" routerLink={path} routerDirection="back">
                    <IonIcon slot="start" icon={arrowBackOutline} /> zurück
                </IonButton> :
                    <IonButton fill="outline" routerDirection="back" onClick={() => history.go(-1)}>
                        <IonIcon slot="start" icon={arrowBackOutline} /> zurück
                    </IonButton>}
            </IonCol>
            {children && <IonCol style={{textAlign: "right"}}>
                {children}
            </IonCol>}
        </IonRow>
    )
}

export default BackButtonRow;