import { forwardRef, useEffect, useRef } from "react";
import Quill from 'quill';
import "quill/dist/quill.snow.css";
import { Delta } from 'quill/core';

import "./quill.scss"

interface QuillDisplayerProps {
    defaultValue: Delta,
}

export type Ref = HTMLDivElement;

const QuillDisplayer = forwardRef<Ref, QuillDisplayerProps>(
    ({ defaultValue }, ref) => {
        const containerRef = useRef(null);
        const defaultValueRef = useRef(defaultValue);

        useEffect(() => {
            /* @ts-ignore */
            ref.current?.setContents(defaultValue)
        }, [ref, defaultValue])

        useEffect(() => {
            const container = containerRef.current;
            const editorContainer = container.appendChild(
                container.ownerDocument.createElement('div'),
            );
            const quill = new Quill(editorContainer, {
                modules: {
                    toolbar: false,
                }
            });
            /* @ts-ignore */
            ref.current = quill;

            quill.enable(false)

            if (defaultValueRef.current) {
                quill.setContents(defaultValueRef.current);
            }

            return () => {
                /* @ts-ignore */
                ref.current = null;
                container.innerHTML = '';
            };
        }, [ref]);

        return <div className="quillDisplayEditor" ref={containerRef}></div>;
    },
);


export default QuillDisplayer;