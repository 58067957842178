import { IonButton, IonCard, IonCardContent, IonCheckbox, IonDatetime, IonDatetimeButton, IonItem, IonLabel, IonList, IonModal } from "@ionic/react"
import { ReportFormData, User } from "../../../../models/userModels";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { format } from "date-fns";
import { useAppDispatch } from "../../../../data/redux/hooks";
import { copyRequiredUserData } from "../../../../util/profile-helper";
import { editUser } from "../../../../data/redux/userSlice";
import { getReportStats } from "../../../../data/redux/reportStatsSlice";
import ReportService from "../../../../data/services/ReportService";
import { setReportList } from "../../../../data/redux/reportListSlice";
import { getLatestReports } from "../../../../data/redux/latestReportsSlice";

interface ReportSettingsFormProps {
    user: User | undefined
}

const ReportSettingsForm: React.FC<ReportSettingsFormProps> = ({ user }: ReportSettingsFormProps) => {
    const { control, handleSubmit, watch, formState: { errors } } = useForm<ReportFormData>({
        mode: "onTouched",
        reValidateMode: "onChange",
        defaultValues: {
            differentStart: (user && user.report_start === null) ? false : true,
            report_start: (user && user.report_start !== null) ? user.report_start : undefined,
        }
    });
    const dispatch = useAppDispatch();

    const selectedDifferentStart = watch("differentStart");

    const fetchReports = async (userId: number) => {
        const res = await ReportService.getReports(userId, 1, 25);
        if (res.report) {
            dispatch(setReportList(res.report.data))
        }
    }

    const onSubmit: SubmitHandler<ReportFormData> = async (data: ReportFormData) => {
        let userClone = copyRequiredUserData(user!);
        if (data.differentStart === false || data.report_start === undefined) {
            userClone.report_start = null
        } else {
            userClone.report_start = format(new Date(data.report_start!), "yyyy-MM-dd")
        }
        dispatch(editUser(userClone)).then(async () => {
            dispatch(getReportStats())
            dispatch(getLatestReports({ userId: userClone.id }))
            fetchReports(userClone.id)
        })

    }

    return (
        <>
            <IonCard>
                <IonCardContent>
                    <div>
                        <h3><b>Berichtsheft-Einstellungen</b></h3>
                    </div>
                    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                        <IonList>

                            <IonItem className="registerInput">
                                <Controller
                                    name="differentStart"
                                    control={control}
                                    render={({ field }) => {
                                        return (
                                            <IonCheckbox checked={field.value} onIonChange={(e) => field.onChange(e.detail.checked)} justify="start" labelPlacement="end"><span className="ion-text-wrap">Berichtsheft soll nicht mit dem Ausbildungsstart beginnen.</span></IonCheckbox>
                                        )
                                    }}
                                />
                            </IonItem>
                            <div className="errorText">{errors.differentStart?.message}</div>

                            {selectedDifferentStart === true &&
                                <>
                                    <IonItem className="registerInput">
                                        <IonLabel>Berichtsheft-Beginn</IonLabel>
                                        <Controller
                                            name="report_start"
                                            control={control}
                                            /* @ts-ignore */
                                            render={({ field }) => <>
                                                <IonDatetimeButton datetime="report_start"></IonDatetimeButton>

                                                <IonModal keepContentsMounted={true}>
                                                    <IonDatetime
                                                        presentation="date"
                                                        id="report_start"
                                                        firstDayOfWeek={1}
                                                        aria-invalid={!!errors.report_start}
                                                        onIonChange={(e: any) => field.onChange(e.detail.value)}
                                                        showDefaultButtons={true}
                                                        doneText="OK"
                                                        cancelText="Abbrechen"
                                                        value={field.value}
                                                    ></IonDatetime>
                                                </IonModal>
                                            </>}
                                        />
                                    </IonItem>
                                    <div className="errorText">{errors.report_start?.message}</div>
                                </>
                            }
                        </IonList>
                        <div style={{ textAlign: "right" }}>
                            <IonButton type="submit">Speichern</IonButton>
                        </div>
                    </form>
                </IonCardContent>
            </IonCard>
        </>
    )
}

export default ReportSettingsForm