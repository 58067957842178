import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonInput, IonInputPasswordToggle, IonItem, IonModal, IonTitle, IonToolbar } from "@ionic/react"
import { closeOutline } from "ionicons/icons";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch } from "../../../../data/redux/hooks";
import { deleteUser } from "../../../../data/redux/userSlice";

interface DeleteUserModalProps {
    isOpen: boolean,
    setIsOpen: (val: boolean) => void
}

interface PasswordConfirmationForm {
    password_confirm: string
}

const DeleteUserModal: React.FC<DeleteUserModalProps> = ({ isOpen, setIsOpen }) => {
    const { control, handleSubmit, formState: { errors }, reset } = useForm<PasswordConfirmationForm>({
        mode: "onTouched",
        reValidateMode: "onChange",
        defaultValues: { password_confirm: "" }
    });
    const dispatch = useAppDispatch();

    const onSubmit: SubmitHandler<PasswordConfirmationForm> = (formData) => {
        dispatch(deleteUser(formData))

        setIsOpen(false)
        reset({ password_confirm: "" })
    }

    return (
        <IonModal isOpen={isOpen} onDidDismiss={() => setIsOpen(false)}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Account löschen</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => setIsOpen(false)}>
                            <IonIcon icon={closeOutline} slot="icon-only" />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <p>Bitte gib zur Bestätigung dein Passwort ein.</p>
                <IonItem className="centeredPreAuthInput">
                    <Controller
                        name="password_confirm"
                        control={control}
                        rules={{
                            required: { value: true, message: "Passwort erforderlich" },
                        }}
                        render={({ field }) => <IonInput
                            labelPlacement="floating"
                            type="password"
                            label="Sicherheitsabfrage Passwort"
                            aria-invalid={!!errors.password_confirm}
                            value={field.value}
                            onIonInput={(e) => field.onChange(e.detail.value)}
                            onIonBlur={() => field.onBlur()}
                        >
                            <IonInputPasswordToggle slot="end"></IonInputPasswordToggle>
                        </IonInput>}
                    />
                </IonItem>
                <div className="errorText">{errors.password_confirm?.message}</div>
            </IonContent>
            <IonFooter>
                <IonToolbar >
                    <IonButtons slot="end">
                        <IonButton role="cancel" onClick={() => setIsOpen(false)}>Abbrechen</IonButton>
                        <IonButton fill="solid" color="danger" type="submit" onClick={handleSubmit(onSubmit)}>löschen</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonFooter>
        </IonModal>
    )
}

export default DeleteUserModal;