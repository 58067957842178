import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from "@ionic/react"
import { closeOutline } from "ionicons/icons";
import { QuillEditor } from "../../components";
import { useRef, useState } from "react";

import { Delta } from 'quill/core';

interface ReportWeekEntryDialogProps {
    isOpen: boolean,
    setIsOpen: (val: boolean) => void,
    callback: (content) => void,
    value: null | string,
    type: string
}

const mapTypeToName = (type: string) => {
    switch (type) {
        case "company_activities":
            return "Betriebliche Tätigkeiten";
        case "school_activities":
            return "Berufsschule (Unterrichtsthemen)";
        case "other_activities":
            return "Unterweisungen, Lehrgespräche, betrieblicher Unterricht, außerbetriebliche Schulungsveranstaltungen, Zwischenprüfung";
        default: return "";
    }
}

const ReportWeekEntryDialog: React.FC<ReportWeekEntryDialogProps> = ({ isOpen, setIsOpen, callback, value, type }) => {
    const quillRef = useRef();
    const [content, setContent] = useState(value);

    const onSubmit = () => {
        callback(content)
        setIsOpen(false)
    }


    return (
        <IonModal isOpen={isOpen} onDidDismiss={() => setIsOpen(false)}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{mapTypeToName(type)} bearbeiten</IonTitle>
                    <IonButtons slot="end" >
                        <IonButton onClick={() => setIsOpen(false)}>
                            <IonIcon icon={closeOutline} slot="icon-only" />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <QuillEditor
                    ref={quillRef}
                    onTextChange={setContent}
                    defaultValue={value ? JSON.parse(value) : new Delta().insert("")}
                />

            </IonContent>
            <IonFooter>
                <IonToolbar>
                    <IonButtons slot="end" >
                        <IonButton onClick={() => setIsOpen(false)}>Abbrechen</IonButton>
                        <IonButton fill="solid" color="primary" type="submit" onClick={onSubmit}>Speichern</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonFooter>
        </IonModal>
    )
}

export default ReportWeekEntryDialog;