import { IonAlert } from "@ionic/react";
import { Location } from "../../../models/companyModels";
import { useAppDispatch } from "../../../data/redux/hooks";
import { deleteDepartment } from "../../../data/redux/companySlice";

interface DeleteDepartmentAlertProps {
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void,
    selectedDepartment?: Location
}

const DeleteDepartmentAlert: React.FC<DeleteDepartmentAlertProps> = ({ isOpen, setIsOpen, selectedDepartment }) => {
    const dispatch = useAppDispatch();

    const handleDelete = () => {
        if (selectedDepartment) {
            dispatch(deleteDepartment({ id: selectedDepartment.id }))
        }
    }

    return (
        <IonAlert
            isOpen={isOpen}
            header={`Abteilung entfernen`}
            message={`Sind Sie sicher, dass Sie <b>${selectedDepartment?.name}</b> entfernen möchten?`}
            buttons={[
                {
                    text: "Abbrechen",
                    role: "cancel"
                }, {
                    text: "Entfernen",
                    handler: handleDelete
                },
            ]}
            onDidDismiss={() => setIsOpen(false)}
        >
        </IonAlert>
    )
}

export default DeleteDepartmentAlert;