import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonPage, IonRouterLink, IonRow } from "@ionic/react";
import { RouteComponentProps } from "react-router";
import { useTheme } from "../../data/context/ThemeContext";
import React from "react";
import { PageContainer } from "../../components";

import "./404.scss";

export const NotFoundPage: React.FC<RouteComponentProps> = () => {
    return (
        <IonPage>
            <IonContent>
                <NotFoundContent />
            </IonContent>
        </IonPage>
    )
}

const NotFoundContent = () => {
    const { theme } = useTheme();

    return (
        <div style={{ backgroundImage: theme === "dark" ? "url('./assets/images/forest-dark.jpg')" : "url('./assets/images/forest-light.jpg')", width: "100vw", height: "100vh", backgroundSize: "cover" }}>
            <div style={{ color: "white", textAlign: "center", height: "100%" }}>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <div style={{ fontSize: 50, marginBottom: 30, letterSpacing: 5 }}>404</div>
                    <div style={{ fontSize: 20 }}>Du weißt nicht wo du bist?</div>
                    <div style={{ fontSize: 20 }}>Kein Problem, <IonRouterLink href="/" color={theme === "dark" ? "dark" : "light"}>hier</IonRouterLink> gehts zurück zu Woodbook.</div>
                </div>

            </div>

        </div>
    )
}

export const NotFoundPageWithAuth: React.FC<RouteComponentProps> = () => {
    const { theme } = useTheme();

    return <IonPage>
        <PageContainer name="404" backButton={false}>
            <IonGrid>
                <IonRow className="ion-hide-lg-down">
                    <IonCol>
                        <IonCard>
                            <IonCardHeader>
                                <IonCardTitle className="headline">Seite nicht gefunden</IonCardTitle>
                            </IonCardHeader>
                        </IonCard>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <IonCard id="notFoundCard" style={{ backgroundImage: theme === "dark" ? "url('./assets/images/forest-dark.jpg')" : "url('./assets/images/forest-light.jpg')", minHeight: 400 }}>
                            <IonCardContent style={{ color: "white", textAlign: "center", height: "100%" }}>
                                <div >
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: 400 }}>
                                        <div style={{ fontSize: 50, marginBottom: 30, letterSpacing: 5 }}>404</div>
                                        <div style={{ fontSize: 20 }}>Du weißt nicht wo du bist?</div>
                                        <div style={{ fontSize: 20 }}>Kein Problem, <IonRouterLink routerLink="/" color={theme === "dark" ? "dark" : "light"}>hier</IonRouterLink> gehts zurück zu Woodbook.</div>
                                    </div>

                                </div>
                            </IonCardContent>
                            {/* <img src={theme === "dark" ? "./assets/images/forest-dark.jpg" : "./assets/images/forest-light.jpg"}/> */}
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </PageContainer>
    </IonPage >
}

