import { IonButton, IonCard, IonCardContent, IonCol, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonLoading, IonPage, IonRow, IonToast } from "@ionic/react";
import { RouteComponentProps } from "react-router";
import { BackButtonRow, PageContainer } from "../../../components";
import { clipboardOutline } from "ionicons/icons";
import { Clipboard } from '@capacitor/clipboard';
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../data/redux/hooks";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { connectTraineeWithCompany, editUser } from "../../../data/redux/userSlice";
import { copyRequiredUserData } from "../../../util/profile-helper";
import { Toast } from "../../../models/helperModels";

interface CompanySettingsProps extends RouteComponentProps<{}> { }

interface CustomCompanyFormData {
    custom_company_name: string | null | undefined,
    custom_trainer_name: string | null | undefined
}

const CompanySettings: React.FC<CompanySettingsProps> = () => {
    const [companyToken, setCompanyToken] = useState<string | number | null | undefined>();
    const { user, updating } = useAppSelector((state) => state.user)
    const dispatch = useAppDispatch();
    const [toast, setToast] = useState<Toast>({ isOpen: false, message: "", color: "danger" })

    const { control, handleSubmit, reset } = useForm<CustomCompanyFormData>({
        mode: "onTouched",
        reValidateMode: "onChange",
    });

    useEffect(() => {
        reset({
            custom_company_name: user?.custom_company_name,
            custom_trainer_name: user?.custom_trainer_name
        })
    }, [user, reset])

    const readFromClipboard = async () => {
        try {
            const { type, value } = await Clipboard.read();
            if (type === "text/plain") {
                setCompanyToken(value)
            }
        } catch (err) {
            console.log(err)
            if (err.name === 'NotAllowedError') {
                setToast({ isOpen: true, message: "Bitte erlaube Woodbook in den Browsereinstellungen den Zugriff auf die Zwischenablage.", color: "danger" })
            } else {
                setToast({ isOpen: true, message: err, color: "danger" })
            }

        }
    }

    const onConnectClick = () => {
        if (user) {
            dispatch(connectTraineeWithCompany({
                user_id: user.id,
                company_token: companyToken
            }))
        }
    }

    const onSubmit: SubmitHandler<CustomCompanyFormData> = async (data: CustomCompanyFormData) => {
        if (user) {
            let editedUser = copyRequiredUserData(user);

            if (data.custom_company_name !== undefined) {
                editedUser.custom_company_name = data.custom_company_name
            }

            if (data.custom_trainer_name !== undefined) {
                editedUser.custom_trainer_name = data.custom_trainer_name;
            }

            dispatch(editUser(editedUser))
        }
    }



    return (
        <IonPage>
            <PageContainer name="Einstellungen" backButton={true}>
                <>
                    <IonGrid>
                        <BackButtonRow path="/tabs/profil" />
                        <IonRow>
                            {/* connect with company via companyToken */}
                            {(user && !user.company_id) && <IonCol size="12">
                                <IonCard>
                                    <IonCardContent>
                                        <IonLabel>
                                            <h3><b>Mit Unternehmen verknüpfen</b></h3>
                                            <p>Wenn dein Ausbildungsbetrieb auch bei Woodbook ist, dann kann dein Personalleiter dir einen Code generieren, mit dem du dich mit deinem Unternehmen verknüfen kannst. Diesen Code kannst du hier eingeben.</p>
                                        </IonLabel>
                                        <IonItem >
                                            <IonInput
                                                labelPlacement="floating"
                                                label="Unternehmens-Code"
                                                value={companyToken}
                                                type="text"
                                                onIonChange={(e) => setCompanyToken(e.detail.value)}
                                            />
                                            <IonButton fill="clear" onClick={readFromClipboard}><IonIcon icon={clipboardOutline} /></IonButton>
                                        </IonItem>
                                        <div style={{ textAlign: "right", marginTop: 10 }}>
                                            <IonButton type="submit" onClick={onConnectClick}>Verbinden</IonButton>
                                        </div>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>}

                            {/* manual company info */}
                            {(user && !user.company_id) && <IonCol>
                                <IonCard>
                                    <IonCardContent>
                                        <IonLabel>
                                            <h3><b>Dein Unternehmen ist nicht bei Woodbook?</b></h3>
                                            <p>Hier kannst du optional Daten über dein Unternehmen angeben, die dann in deinem Berichtsheft angezeigt werden.</p>
                                        </IonLabel>
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <IonItem >
                                                <Controller
                                                    name="custom_company_name"
                                                    control={control}
                                                    render={({ field }) => <IonInput
                                                        label="Name des Unternehmens"
                                                        labelPlacement="floating"
                                                        type="text"
                                                        value={field.value}
                                                        onIonChange={(e) => field.onChange(e.detail.value)}
                                                        onIonBlur={() => field.onBlur()}
                                                    />}
                                                />
                                            </IonItem>
                                            <IonItem >
                                                <Controller
                                                    name="custom_trainer_name"
                                                    control={control}
                                                    render={({ field }) => <IonInput
                                                        label="Name deines Ausbilders"
                                                        labelPlacement="floating"
                                                        type="text"
                                                        value={field.value}
                                                        onIonChange={(e) => field.onChange(e.detail.value)}
                                                        onIonBlur={() => field.onBlur()}
                                                    />}
                                                />
                                            </IonItem>
                                            <div style={{ textAlign: "right", marginTop: 10 }}>
                                                <IonButton type="submit">Speichern</IonButton>
                                            </div>
                                        </form>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>}

                            {(user && user.company_id) && <IonCol>
                                <IonCard>
                                    <IonCardContent>
                                        <IonLabel>
                                            <h3><b>{user.role === "trainee" ? "Dein Ausbildungsbetrieb" : "Ihr Unternehmen"}</b></h3>
                                            <p>{user.company_name}</p>
                                        </IonLabel>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>}
                        </IonRow>
                    </IonGrid>
                    <IonLoading
                        message="Lädt..."
                        isOpen={updating}
                    />
                    <IonToast
                        message={toast.message}
                        isOpen={toast.isOpen}
                        color={toast.color}
                        onDidDismiss={() => setToast({ isOpen: false, message: "", color: "danger" })}
                        duration={5000}
                    />
                </>
            </PageContainer>
        </IonPage>
    )
}

export default CompanySettings;