//@ts-nocheck 
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonPage, IonRow } from "@ionic/react";
import React, { useEffect, useRef } from "react";
import { RouteComponentProps } from "react-router";
import { BackButtonRow, PageContainer } from "../../components";
import { useNewsArticle } from "../../data/customHooks/useNewsArticle";
import { register } from 'swiper/element/bundle';

import "./News.scss";

interface NewsDetailProps extends RouteComponentProps<{
    id: string
}> { }


const NewsDetail: React.FC<NewsDetailProps> = ({ match }) => {
    const { id } = match.params;
    const newsArticle = useNewsArticle(id);
    const swiperRef = useRef<HTMLLinkElement | any>(null);
    const imageLoadCount = useRef(0);

    useEffect(() => {
        // Register Swiper web component
        register();

        const swiperElement = swiperRef.current;

        if (swiperElement && newsArticle) {
            // Object with parameters
            const params = {
                pagination: true,
                paginationClickable: true,
                navigation: true,
                observer: true,
                observeParents: true,
            };

            // Assign it to swiper element
            Object.assign(swiperElement, params);

            const images = document.querySelectorAll('swiper-slide img');
            imageLoadCount.current = 0;

            images.forEach((img) => {
                img.onload = () => {
                    imageLoadCount.current++;
                    if (imageLoadCount.current === images.length) {
                        // Alle Bilder sind geladen, Swiper initialisieren
                        swiperElement.initialize();
                    }
                };
            });

            // Cleanup function
            return () => {
                // Remove the onload event listener to avoid memory leaks
                images.forEach((img) => {
                    img.onload = null;
                });

                // Optionally destroy the Swiper instance if it exists
                if (swiperElement && swiperElement.destroy) {
                    swiperElement.destroy(true, true);
                }

                if(imageLoadCount && imageLoadCount.current){
                    imageLoadCount.current = 0;
                }
            };
        }



    }, [newsArticle]);

    return (
        <IonPage>
            <PageContainer name="News" backButton={true}>
                <IonGrid>
                    <BackButtonRow />

                    <IonRow className="ion-hide-lg-down">
                        <IonCol>
                            <IonCard>
                                <IonCardHeader>
                                    <IonCardTitle className='headline'>News</IonCardTitle>
                                </IonCardHeader>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            {newsArticle &&
                                <IonCard key={id}>
                                    <div className="swiperContainer">

                                        <swiper-container init="false" ref={swiperRef} >
                                            {newsArticle.images?.map(image => {
                                                return (
                                                    <swiper-slide key={image.id} >
                                                        <img src={image.img} alt="Newsartikel-Bild"/>
                                                    </swiper-slide>
                                                )
                                            })}
                                        </swiper-container>
                                    </div>

                                    <IonCardHeader>
                                        <IonCardTitle>
                                            {newsArticle?.headline}
                                        </IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent className="formattedText">
                                        <div dangerouslySetInnerHTML={{ __html: newsArticle.text }}></div>
                                    </IonCardContent>
                                </IonCard>}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </PageContainer>
        </IonPage>
    )
}

export default NewsDetail