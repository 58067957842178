import { Stat } from "../../../models/reportModels"
import { mapColorIonVar } from "../../../util/report-helper"
import "./AzubiProgressBar.scss"

type AzubiProgressBarProps = {
    data?: Stat
}

const testData: Stat = {
    open: 1,
    submitted: 1,
    dismissed: 0,
    completed: 0
}

const AzubiProgressBar: React.FC<AzubiProgressBarProps> = ({ data = testData }: AzubiProgressBarProps) => {

    const vals = (Object.keys(data) as (keyof Stat)[]).map(function (key) {
        return data[key];
    });

    const amount = vals.reduce((a, b) => a + b, 0)

    const count = vals.filter((v) => (v !== 0)).length;

    return (
        <div className="azubiProgressBar">
            {count > 0 && <>
                {/* Completed */}{data.completed > 0 && <div style={{ background: mapColorIonVar("completed"), width: "calc(" + (data["completed"] / amount) * 100 + "% - " + ((count - 1) * 10 / count) + "px)" }} ></div>}
                {/* submitted */}{data.submitted > 0 && <div style={{ background: mapColorIonVar("submitted"), width: "calc(" + (data["submitted"] / amount) * 100 + "% - " + ((count - 1) * 10 / count) + "px)" }} ></div>}
                {/* dismissed */}{data.dismissed > 0 && <div style={{ background: mapColorIonVar("dismissed"), width: "calc(" + (data["dismissed"] / amount) * 100 + "% - " + ((count - 1) * 10 / count) + "px)" }} ></div>}
                {/* open */}{data.open > 0 && <div style={{ background: mapColorIonVar("open"), width: "calc(" + (data["open"] / amount) * 100 + "% - " + ((count - 1) * 10 / count) + "px)" }} ></div>}
            </>}

            {/* alte variante: */}
            {/* {data.completed > 0 && <div style={{ background: mapColorIonVar("completed"), width: "calc(" + (data["completed"] / amount) * 100 + "% - 10px)" }} ></div>} */}
        </div>
    )
}

export default AzubiProgressBar;