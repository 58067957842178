import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ReportService from "../services/ReportService";
import { Report } from "../../models/reportModels";
import { setError } from "./errorSlice";
import { error500Text } from "../../util/error-helper";

interface LatestReportsState {
    latestReports: Report[],
    loading: boolean,
    error: undefined | string
}

const initialState: LatestReportsState = {
    latestReports: [],
    loading: false,
    error: undefined
};

export const getLatestReports = createAsyncThunk(
    "latestReports/getLatestReports",
    async (data: any, { rejectWithValue, dispatch }) => {
        try {
            const res = await ReportService.getReports(data.userId, 1, 6);
            if (res.error) {
                dispatch(setError({
                    isOpen: true,
                    message: res.error
                }))
                return rejectWithValue(res.error);
            } else if (res.report) {
                return res.report.data;
            } else {
                dispatch(setError({
                    isOpen: true,
                    message: error500Text
                }))
                return rejectWithValue(error500Text);
            }
        } catch (err) {
            dispatch(setError({
                isOpen: true,
                message: error500Text
            }))
            return rejectWithValue("500 (Interner Server Fehler)");
        }
    }
)

export const latestReportsSlice = createSlice({
    name: "reportStats",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        /* getLatestReports */
        builder.addCase(getLatestReports.pending, (state) => {
            state.loading = true;
            state.error = undefined;
        });
        builder.addCase(getLatestReports.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.latestReports = payload;
        });
        builder.addCase(getLatestReports.rejected, (state, { payload }) => {
            state.loading = false;
            /* @ts-ignore */
            state.error = payload;
        });
    }
});

/* export const { } = latestReportsSlice.actions; */

export default latestReportsSlice.reducer;
