/* import { useAuth } from "../../data/context/AuthContext" */
import { Redirect, RouteComponentProps } from "react-router";
import { useAppSelector } from "../../data/redux/hooks";

import "./ProtectedRoute.scss";
import EducationFormPage from "../../pages/preAuth/register/EducationFormPage";
import { LoadingPage } from "../../pages";


interface ProtectedRouteProps extends RouteComponentProps<{
}> {
    children: React.ReactElement
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }, props: ProtectedRouteProps) => {
    const { user, loading } = useAppSelector((state) => state.user);

    if (user === undefined) {
        
        if (loading) {
            return (
                <LoadingPage />
            )
        }
        return <Redirect exact to="/login" />
    } else if (user !== undefined && user.role === "trainee" && user.trainee_start === null) {
        return (
            <EducationFormPage {...props} />
        )
    }
    return children;

    /* return <EducationFormPage {...props} /> */
}

export default ProtectedRoute;