import { createContext, useContext, useEffect, useState } from 'react';
import { Preferences } from '@capacitor/preferences';

type ThemeContextType = {
    theme: string | null,
    changeTheme: (val: string) => void
};

const ThemeContext = createContext<ThemeContextType>({
    theme: null,
    changeTheme: () => { },
});


export const ThemeProvider = ({ children }: any) => {
    const [theme, setTheme] = useState<string | null>(null)

    const changeTheme = (newTheme: string) => {
        setTheme(newTheme);
        setThemeInStorage(newTheme);
        if (newTheme === "dark") {
            document.documentElement.classList.toggle('ion-palette-dark', true);
        } else {
            document.documentElement.classList.toggle('ion-palette-dark', false);
        }
    }

    useEffect(() => {
        let ignore = false;
        const getInitialTheme = async () => {
            let { value } = await Preferences.get({ key: 'theme' })
            if (!ignore) {
                if (value !== null) {
                    changeTheme(value)
                } else {
                    changeTheme(window.matchMedia('(prefers-color-scheme: dark)').matches ? "dark" : "light")
                }
            }

        }

        getInitialTheme();
        return () => {
            ignore = true;
        };
    }, [])

    const value = { theme, changeTheme }

    return (
        <ThemeContext.Provider value={value}>
            {children}
        </ThemeContext.Provider>
    )
}

export function useTheme() {
    return useContext(ThemeContext);
}


const setThemeInStorage = async (val: string) => {
    await Preferences.set({
        key: 'theme',
        value: val,
    });
}



