import { InfiniteScrollCustomEvent, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonPage, IonRow, } from "@ionic/react";
import { RouteComponentProps } from "react-router";
import { BackButtonRow, FilterButtonBar, PageContainer } from "../../components";
import { printOutline } from "ionicons/icons";

import ReportWeek from "../../components/reportComponents/ReportWeek";
import { useEffect, useRef, useState } from "react";
import ReportService from "../../data/services/ReportService";
import { Stat } from "../../models/reportModels";
import { User } from "../../models/userModels";
import UserSelect from "../admin/userSelect/UserSelect";
import { useAppDispatch, useAppSelector } from "../../data/redux/hooks";
import { addToReportList, setReportList } from "../../data/redux/reportListSlice";
import { setError } from "../../data/redux/errorSlice";
import { error500Text } from "../../util/error-helper";

interface RecordBookPreviewProps extends RouteComponentProps<{
    filter?: string
}> {
    selectedUser: User,
    stats: Stat | undefined,
    setSelectedUser?: (id: string) => void,
    userList?: User[]
}

const iniStat = {
    completed: 0, dismissed: 0, submitted: 0, open: 0
}

const RecordBookPreviewContent: React.FC<RecordBookPreviewProps> = ({ selectedUser, stats, setSelectedUser, userList, ...props }: RecordBookPreviewProps) => {
    const [filter, setFilter] = useState<Stat>(iniStat);
    const pageRef = useRef(1);
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);
    const amount = 25;
    const { user } = useAppSelector((state) => state.user)
    const reportList = useAppSelector((state) => state.reportList.reportList);
    const dispatch = useAppDispatch();
    const { filter: filterParam } = props.match.params;

    useEffect(() => {
        const handleFilterClick = (val?: string) => {
            let temp: Stat = {
                completed: 0, dismissed: 0, submitted: 0, open: 0
            };
            if (val) {
                temp[val] = 1;
            }
            setFilter(temp);
        }

        handleFilterClick(props.match.params.filter)

    }, [props.match.params])

    useEffect(() => {
        let ignore = false;
        pageRef.current = 1;

        const fetchReports = async () => {
            const res = await ReportService.getReports(selectedUser.id, pageRef.current, amount, filter);
            if (!ignore) {
                if (res.report) {
                    dispatch(setReportList(res.report.data))
                    if (res.report.last_page === 1) {
                        setDisableInfiniteScroll(true)
                    } else {
                        setDisableInfiniteScroll(false)
                    }
                } else if (res.error) {
                    console.log(res.error)
                    dispatch(setError({
                        isOpen: true,
                        message: res.error
                    }))
                } else {
                    dispatch(setError({
                        isOpen: true,
                        message: error500Text
                    }))
                }
            }
        }

        if (selectedUser) {
            if (!filterParam) {
                fetchReports()
            } else if (filterParam) {
                fetchReports()
            }

        }

        return () => {
            ignore = true;
        }
    }, [selectedUser, filter, dispatch, filterParam])

    const updateOnScroll = async (ev: InfiniteScrollCustomEvent) => {
        pageRef.current = pageRef.current + 1;
        ReportService.getReports(selectedUser.id, pageRef.current, amount, filter)
            .then((res) => {
                if (res.report) {
                    dispatch(addToReportList(res.report.data))
                    if (res.report.last_page === pageRef.current) {
                        setDisableInfiniteScroll(true)
                    }
                } else if (res.error) {
                    dispatch(setError({
                        isOpen: true,
                        message: res.error
                    }))
                } else {
                    dispatch(setError({
                        isOpen: true,
                        message: error500Text
                    }))
                }
            }).catch((err) => {
                dispatch(setError({
                    isOpen: true,
                    message: error500Text
                }))
                console.log(err)
            })
            .finally(() => {
                ev.target.complete()
            })
    }


    const userSelectCallback = (id: string) => {
        if (setSelectedUser) {
            setSelectedUser(id)
        }
    }

    const onOtherActionBtnClick = () => {
        if (user && user.role === "trainee") {
            props.history.push("/tabs/berichtsheft-drucken")
        } else {
            props.history.push(`/tabs/azubis/${selectedUser.id}/berichtsheft-drucken`)
        }
    }



    return (
        <IonPage>
            <PageContainer
                name={user && user.role === "trainee" ? "Berichtsheft" : "Meine Azubis"}
                backButton={user && user.role === "trainee" ? false : true}
                otherButton={true}
                otherButtonIcon={printOutline}
                otherButtonAction={onOtherActionBtnClick}
                reportToolbar={true}
                reportToolbarCallback={setFilter}
                reportToolbarVal={filter}
                reportToolbarStats={stats}
            >
                <IonGrid>
                    {(user && user.role !== "trainee") &&
                        <BackButtonRow path="/tabs/azubis" >
                            <IonButton fill="outline" routerLink={`/tabs/azubis/${selectedUser.id}/berichtsheft-drucken`}>
                                <IonIcon slot="icon-only" icon={printOutline}></IonIcon>
                            </IonButton>
                        </BackButtonRow>
                    }

                    <IonRow>
                        <IonCol>
                            <IonCard>
                                <IonCardHeader className="ion-hide-lg-down">

                                    <div className="reportDesktopHeader">
                                        <div className="minWidthCol">
                                            {user && <IonCardTitle className='headline'>{user.role === "trainee" ? "Berichtsheft" : "Meine Azubis"}</IonCardTitle>}
                                        </div>
                                        <div>
                                            <FilterButtonBar filter={filter} setFilter={setFilter} stats={stats} />
                                        </div>
                                        <div className="minWidthCol" style={{ textAlign: "right" }}>
                                            {user && user.role === "trainee" && <IonButton fill="clear" routerLink="/tabs/berichtsheft-drucken">
                                                <IonIcon icon={printOutline} />
                                            </IonButton>}
                                            {user && user.role !== "trainee" && userList &&
                                                <UserSelect selectedUserId={selectedUser.id} setSelectedUserId={userSelectCallback} userList={userList} />
                                            }


                                        </div>
                                    </div>
                                </IonCardHeader>

                                {user && user.role !== "trainee" &&
                                    <IonCardHeader className="ion-hide-lg-up" style={{ display: "flex", alignItems: "flex-end" }}>
                                        {user && user.role !== "trainee" && userList &&
                                            <UserSelect selectedUserId={selectedUser.id} setSelectedUserId={userSelectCallback} userList={userList} />
                                        }
                                    </IonCardHeader>
                                }
                                <IonCardContent role="feed" className="reportList">
                                    {(user && reportList.length === 0 && user.trainee_start && new Date(user.trainee_start) > new Date()) && "Dein Ausbildungsstart liegt in der Zukunft. Sobald deine Ausbildung beginnt, kannst du hier dein Berichtsheft pflegen. Den Startzeitpunkt kannst du in den Einstellungen ändern."}
                                    {user && reportList.map((report) => {
                                        return (
                                            <ReportWeek key={report.id} report={report} {...props} role={user.role} />
                                        )
                                    })}
                                    <IonInfiniteScroll
                                        onIonInfinite={(ev) => {
                                            updateOnScroll(ev)
                                        }}
                                        disabled={disableInfiniteScroll}
                                    >
                                        <IonInfiniteScrollContent />
                                    </IonInfiniteScroll>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </PageContainer>
        </IonPage >
    )
}

export default RecordBookPreviewContent;