import { IonButton, IonIcon } from "@ionic/react"
import { chevronBackCircleOutline, chevronForwardCircleOutline } from "ionicons/icons"
import { mapColorIonVar } from "../../util/report-helper";
import { endOfDay, format, lastDayOfWeek, nextMonday, parse, previousMonday } from "date-fns";
import { de } from "date-fns/locale";
import { WeekSwitcherProps } from "../../models/reportModels";

const WeekSwitcher: React.FC<WeekSwitcherProps> = ({ year, week, changeWeek, state, trainingStart }: WeekSwitcherProps) => {
    // startdatum ausbildung, jetziges datum, gewähltes datum status
    const startdate = parse(year + '-' + week, 'YYYY-w', new Date(), {
        useAdditionalWeekYearTokens: true,
        locale: de
    })
    const endDate = lastDayOfWeek(startdate, {locale: de})
    const today = new Date()

    const handleNextWeekClick = (method: string) => {
        let newWeekDate;
        if (method === "next") {
            newWeekDate = nextMonday(startdate)
        } else {
            newWeekDate = previousMonday(startdate)
        }

        const newWeek = format(newWeekDate, "I")
        const newYear = format(newWeekDate, "yyyy")

        changeWeek(newYear, newWeek);
    }

    const isPrevDisabled = () => {
        if (trainingStart) {
            if (new Date(trainingStart) > startdate) {
                return true
            }
            return false
        } return false
    }

    return (
        <div className="weekSwitcher">
            <IonButton fill="clear" onClick={() => handleNextWeekClick("previous")} disabled={isPrevDisabled()}>
                <IonIcon icon={chevronBackCircleOutline} />
            </IonButton>

            {state && <div className="weekSwitcherWeek" style={{ background: mapColorIonVar(state) }}>
                KW{week} {format(new Date(startdate), "dd.MM")} - {format(new Date(endDate), "dd.MM.yyyy")}
            </div>}
            <IonButton fill="clear" disabled={today < endOfDay(endDate)} onClick={() => handleNextWeekClick("next")}>
                <IonIcon icon={chevronForwardCircleOutline} />
            </IonButton>
        </div>
    )
}

export default WeekSwitcher;