import { createSlice } from "@reduxjs/toolkit";
import { Report } from "../../models/reportModels";

interface ReportListState {
  reportList: Report[]
}

const initialState: ReportListState = {
  reportList: [],
};


export const reportListSlice = createSlice({
  name: "reportList",
  initialState,
  reducers: {
    setReportList: (state, action) => {
      state.reportList = action.payload;
    },
    addToReportList: (state, action) => {
      state.reportList = [...state.reportList, ...action.payload];
    },
    changeReportListWeekState: (state, action) => {
      let index = state.reportList.findIndex(
        (report) => report.id === action.payload.id
      );
      if (index !== -1) {
        state.reportList[index].state = action.payload.state;
        state.reportList[index].updated_at = action.payload.updated_at;
      }
    },
  }
});

export const { setReportList, addToReportList, changeReportListWeekState } =
  reportListSlice.actions;

export default reportListSlice.reducer;
