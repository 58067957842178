import { useEffect, useState } from "react";
import { NewsArticle } from "../../models/newsModel";
import NewsService from "../services/NewsService";
import { useAppDispatch } from "../redux/hooks";
import { error500Text } from "../../util/error-helper";
import { setError } from "../redux/errorSlice";

export const useNewsArticle = (id: string) => {
  const [newsArticle, setNewsArticle] = useState<NewsArticle>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    let ignore = false;

    const fetchSingleNews = async () => {
      const res = await NewsService.getSingleNews(id);
      if (!ignore) {
        if (res.article) {
          setNewsArticle(res.article);
        } else if (res.error) {
          dispatch(setError({
            isOpen: true,
            message: res.error
          }))
        } else {
          dispatch(setError({
            isOpen: true,
            message: error500Text
          }))
        }

      }
    };

    fetchSingleNews();

    return () => {
      ignore = false;
    };
  }, [id, dispatch]);

  return newsArticle;
};
