import { IonIcon, IonItem, IonLabel, IonList } from "@ionic/react"
import { Stat } from "../../models/reportModels"
import { mapColor, mapIcon } from "../../util/report-helper"
import { User } from "../../models/userModels"


type DoughnutChartLegendProps = {
    stats: Stat,
    user: User
}

const DoughnutChartLegend: React.FC<DoughnutChartLegendProps> = ({ stats, user }: DoughnutChartLegendProps) => {
    
    return (
        <div className='legend'>
            {user && user.role === "trainee" ?
                <IonList>
                    {user.company_id &&
                        <IonItem routerLink="/tabs/berichtsheft/completed">
                            <IonIcon slot="start" color={mapColor("completed")} icon={mapIcon("completed")} />
                            <IonLabel><b>{stats.completed}</b> erledigt</IonLabel>
                        </IonItem>
                    }
                    <IonItem routerLink="/tabs/berichtsheft/submitted">
                        <IonIcon slot="start" color={mapColor("submitted")} icon={mapIcon("submitted")} />
                        <IonLabel><b>{stats.submitted}</b> {user.company_id ? "eingereicht" : "erledigt"}</IonLabel>
                    </IonItem>
                    {user.company_id && <IonItem routerLink="/tabs/berichtsheft/dismissed">
                        <IonIcon slot="start" color={mapColor("dismissed")} icon={mapIcon("dismissed")} />
                        <IonLabel><b>{stats.dismissed}</b> abgelehnt</IonLabel>
                    </IonItem>}
                    <IonItem routerLink="/tabs/berichtsheft/open">
                        <IonIcon slot="start" color={mapColor("open")} icon={mapIcon("open")} />
                        <IonLabel><b>{stats.open}</b> offen</IonLabel>
                    </IonItem>
                </IonList> :

                /* Ausbilder / Admin */
                <IonList>
                    <IonItem>
                        <IonIcon slot="start" color={mapColor("submitted")} icon={mapIcon("submitted")} />
                        <IonLabel><b>{stats.submitted}</b> unkontrolliert</IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonIcon slot="start" color={mapColor("dismissed")} icon={mapIcon("dismissed")} />
                        <IonLabel><b>{stats.dismissed}</b> abgelehnt</IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonIcon slot="start" color={mapColor("open")} icon={mapIcon("open")} />
                        <IonLabel><b>{stats.open}</b> offen</IonLabel>
                    </IonItem>
                </IonList>
            }
        </div>
    )
}

export default DoughnutChartLegend;