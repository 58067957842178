import { InfiniteScrollCustomEvent, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonGrid, IonInfiniteScroll, IonInfiniteScrollContent, IonPage, IonRefresher, IonRefresherContent, IonRow, IonSkeletonText, RefresherEventDetail } from "@ionic/react";
import { RouteComponentProps } from "react-router";
import { PageContainer } from "../../components";
import { useEffect, useRef, useState } from "react";
import NewsService from "../../data/services/NewsService";
import { NewsArticle } from "../../models/newsModel";
import { format } from "date-fns";
import { trimHtml } from "../../util/helper";

import "./News.scss";
import { useAppDispatch } from "../../data/redux/hooks";
import { setError } from "../../data/redux/errorSlice";
import { error500Text } from "../../util/error-helper";


const NewsPreview: React.FC<RouteComponentProps> = () => {
    const pageRef = useRef(1);
    const amount = 25;
    const disableInfiniteScrollRef = useRef(false);
    const [news, setNews] = useState<NewsArticle[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const skeletonCardAmount = 6;
    const dispatch = useAppDispatch();



    useEffect(() => {
        let ignore = false;
        pageRef.current = 1;
        const fetchNews = async () => {
            setLoading(true)
            const res = await NewsService.getNews(pageRef.current, amount);
            if (!ignore) {
                if (res.result) {
                    setNews(res.result.data)
                    if (res.result.last_page === 1) {
                        disableInfiniteScrollRef.current = true
                    } else {
                        disableInfiniteScrollRef.current = false;
                    }
                } else if (res.error) {
                    dispatch(setError({
                        isOpen: true,
                        message: res.error
                    }))
                } else {
                    dispatch(setError({
                        isOpen: true,
                        message: error500Text
                    }))
                }
            }
            setLoading(false)
        }

        fetchNews()

        return () => {
            ignore = true;
        }
    }, [dispatch])

    const updateOnScroll = async (ev: InfiniteScrollCustomEvent) => {
        pageRef.current = pageRef.current + 1;
        NewsService.getNews(pageRef.current, amount)
            .then((res) => {
                if (res.result) {
                    //TODO
                    if (res.result.last_page === pageRef.current) {
                        disableInfiniteScrollRef.current = true
                    }

                    setNews(oldNews => [...oldNews, ...res.result.data])

                }
            })
            .finally(() => ev.target.complete())
    }

    const returnSkeletons = (amount: number) => {
        const skeletons = [];

        for (let i = 0; i < amount; i++) {
            skeletons.push(
                <IonCol key={i} sizeXs="12" sizeSm="6" sizeMd="6" sizeLg="6" sizeXl="4">
                    <IonCard className="newsCard">
                        <IonSkeletonText animated={true} className="newsImage" style={{ marginTop: 0 }} />
                        <IonCardHeader>
                            <IonCardSubtitle>
                                <IonSkeletonText animated={true} style={{ width: "30%" }}></IonSkeletonText>
                            </IonCardSubtitle>
                            <IonCardTitle>
                                <IonSkeletonText animated={true}></IonSkeletonText>
                                <IonSkeletonText animated={true} style={{ width: "50%" }}></IonSkeletonText>
                            </IonCardTitle>

                        </IonCardHeader>
                        <IonCardContent>
                            <div >
                                <IonSkeletonText animated={true}></IonSkeletonText>
                                <IonSkeletonText animated={true}></IonSkeletonText>
                                <IonSkeletonText animated={true}></IonSkeletonText>
                                <IonSkeletonText animated={true}></IonSkeletonText>
                                <IonSkeletonText animated={true}></IonSkeletonText>
                            </div>
                        </IonCardContent>
                    </IonCard>
                </IonCol>
            );
        }
        return skeletons;
    }

    const handleRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        pageRef.current = 1;
        NewsService.getNews(pageRef.current, amount).then((res) => {
            if (res.result) {
                // TODO
                setNews(res.result.data)
                if (res.result.last_page === 1) {
                    disableInfiniteScrollRef.current = true
                } else {
                    disableInfiniteScrollRef.current = false;
                }
            }
        }).finally(() => {
            event.detail.complete();
        });
    }


    return (
        <IonPage>
            <PageContainer name="News" backButton={false}>
                <>
                    <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
                        <IonRefresherContent></IonRefresherContent>
                    </IonRefresher>
                    <IonGrid>
                        <IonRow className="ion-hide-lg-down">
                            <IonCol>
                                <IonCard>
                                    <IonCardHeader>
                                        <IonCardTitle className='headline'>News</IonCardTitle>
                                    </IonCardHeader>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow style={{ marginBottom: 20 }}>

                            {!loading ? news.map(newsEntry =>

                                <IonCol role="article" key={newsEntry.id} sizeXs="12" sizeSm="6" sizeMd="6" sizeLg="6" sizeXl="4">

                                    <IonCard className="newsCard" routerLink={`/tabs/news/${newsEntry.id}`}>
                                        <img className="newsImage" alt="Artikelbild" src={newsEntry.image ? newsEntry.image : "./assets/images/placeholder.jpg"} />
                                        <IonCardHeader>
                                            <IonCardSubtitle>
                                                {format(new Date(newsEntry.datum), "dd.MM.yyyy")}
                                            </IonCardSubtitle>
                                            <IonCardTitle>{newsEntry.headline}</IonCardTitle>

                                        </IonCardHeader>
                                        <IonCardContent>
                                            <div dangerouslySetInnerHTML={{ __html: trimHtml(newsEntry.text, { limit: 200 }).html }}></div>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            ) :
                                returnSkeletons(skeletonCardAmount)
                            }

                            <IonInfiniteScroll
                                onIonInfinite={(ev) => {
                                    updateOnScroll(ev)
                                }}
                                disabled={disableInfiniteScrollRef.current}
                            >

                                <IonInfiniteScrollContent />
                            </IonInfiniteScroll>
                        </IonRow>
                    </IonGrid>
                </>
            </PageContainer>
        </IonPage>
    )
}

export default NewsPreview;