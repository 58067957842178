import { IonIcon } from "@ionic/react"
import { Report } from "../../models/reportModels"
import { mapColorIonVar, mapIcon } from "../../util/report-helper"
import { RouteComponentProps, useHistory } from "react-router"

interface ReportWeekProps extends RouteComponentProps {
    report: Report,
    role: string
}

const ReportWeek: React.FC<ReportWeekProps> = ({ report, role }: ReportWeekProps) => {
    const history = useHistory();

    const handleReportWeekClick = (e: { preventDefault: () => void }) => {
        e.preventDefault()
        if (role === "trainee") {
            history.push(`/tabs/berichtsheft/${report.year}/${report.week}`)
        } else {
            history.push(`/tabs/azubis/${report.user_id}/${report.year}/${report.week}`)
        }

    }

    return (
        <div onClick={handleReportWeekClick} role="article" className="reportWeekEntry" style={{ background: mapColorIonVar(report.state) }}>
            <div className="calendarWeek">KW {report.week}</div>
            <div>{report.datestring}</div>
            <IonIcon className="reportWeekIcon" icon={mapIcon(report.state)} />
        </div>
    )
}

export default ReportWeek