import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol } from "@ionic/react";
import { EditReportWeekParams, ReportWeek } from "../../models/reportModels";
import { useRef, useState } from "react";
import ReportWeekEntryDialog from "./ReportWeekEntryDialog";
import { useAppDispatch } from "../../data/redux/hooks";
import ReportService from "../../data/services/ReportService";
import { setError } from "../../data/redux/errorSlice";
import { error500Text } from "../../util/error-helper";
import { QuillDisplayer } from "../../components";
import { setReportWeek } from "../../data/redux/reportWeekSlice";
import sanitizeHtml from 'sanitize-html';


interface RecordBookWeekProps {
    reportWeek: ReportWeek | undefined;
    editable: boolean
}


const RecordBookWeek: React.FC<RecordBookWeekProps> = ({ reportWeek, editable }: RecordBookWeekProps) => {
    const companyDisplayRef = useRef();
    const schoolDisplayRef = useRef();
    const otherDisplayRef = useRef();
    const dispatch = useAppDispatch();
    const selectedTypeRef = useRef<string>("")
    const [editDialog, setEditDialog] = useState<boolean>(false);


    const callback = async (content) => {

        let editorContent = JSON.stringify(content)
        if (editorContent === "[{\"insert\":\"\\n\"}]") {
            editorContent = null
        }

        let data: EditReportWeekParams = {
            year: reportWeek.year,
            week: reportWeek.week,
            company_activities: reportWeek.company_activities,
            school_activities: reportWeek.school_activities,
            other_activities: reportWeek.other_activities
        }
        data[selectedTypeRef.current] = sanitizeHtml(editorContent)

        try {
            const res = await ReportService.editReportWeekEntry(data)
            if (res.report) {
                dispatch(setReportWeek(res.report))
            } else if (res.error) {
                dispatch(setError({
                    isOpen: true,
                    message: res.error
                }))
            } else {
                dispatch(setError({
                    isOpen: true,
                    message: error500Text
                }))
            }

        } catch (err) {
            console.log(err)
            dispatch(setError({
                isOpen: true,
                message: error500Text
            }))
        }
    }

    const openEditDialog = (type: string) => {
        selectedTypeRef.current = type;
        setEditDialog(true)
    }

    return (
        <>
            <IonCol size="12">
                <IonCard className={editable ? "editable reportCard weekCard" : "reportCard weekCard"} onClick={editable ? () => openEditDialog("company_activities") : undefined}>
                    <IonCardHeader >
                        <IonCardTitle>
                            Betriebliche Tätigkeiten
                        </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <div className="weeklyEntryContainer">
                            {reportWeek.company_activities ?
                                <QuillDisplayer
                                    ref={companyDisplayRef}
                                    defaultValue={JSON.parse(reportWeek.company_activities)}
                                /> :
                                <i>Noch nichts eingetragen</i>}
                        </div>
                    </IonCardContent>
                </IonCard>
            </IonCol>
            <IonCol size="12">
                <IonCard className={editable ? "editable reportCard weekCard" : "reportCard weekCard"} onClick={editable ? () => openEditDialog("school_activities") : undefined}>
                    <IonCardHeader >
                        <IonCardTitle>
                            Berufsschule (Unterrichtsthemen)
                        </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <div className="weeklyEntryContainer">
                            {reportWeek.school_activities ?
                                <QuillDisplayer
                                    ref={schoolDisplayRef}
                                    defaultValue={JSON.parse(reportWeek.school_activities)}
                                /> :
                                <i>Noch nichts eingetragen</i>}
                        </div>
                    </IonCardContent>
                </IonCard>
            </IonCol>
            <IonCol size="12">
                <IonCard className={editable ? "editable reportCard weekCard" : "reportCard weekCard"} onClick={editable ? () => openEditDialog("other_activities") : undefined}>
                    <IonCardHeader >
                        <IonCardTitle>
                            Unterweisungen, Lehrgespräche, betrieblicher Unterricht, außerbetriebliche Schulungsveranstaltungen, Zwischenprüfung
                        </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <div className="weeklyEntryContainer">
                            {reportWeek.other_activities ?
                                <QuillDisplayer
                                    ref={otherDisplayRef}
                                    defaultValue={JSON.parse(reportWeek.other_activities)}
                                /> :
                                <i>Noch nichts eingetragen</i>}
                        </div>
                    </IonCardContent>
                </IonCard>
            </IonCol>

            <ReportWeekEntryDialog
                isOpen={editDialog}
                setIsOpen={setEditDialog}
                callback={callback}
                value={reportWeek[selectedTypeRef.current]}
                type={selectedTypeRef.current}
            />
        </>
    )
}

export default RecordBookWeek;