//regex for email validation
export const isValidEmail = (email: string) => {
  // eslint-disable-next-line no-useless-escape
  return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
};

export const handleEmailValidation = (email: string) => {
  const isValid = isValidEmail(email);

  if (isValid) {
    return isValid;
  } else {
    return "Keine gültige E-Mail Adresse";
  }
};

export const isValidPassword = (password: string) => {
  return /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&_]{8,}$/.test(password);
  //return /^(?=.*[A-Za-z])(?=.*\d).{8,}$/.test(password);
  //return /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z_@./#&!?ß*+-\d]{8,}$/.test(password);
};

export function trimHtml(
  html: string,
  options: {
    limit?: any;
    preserveTags?: any;
    wordBreak?: any;
    suffix?: any;
    moreLink?: any;
  }
) {
  options = options || {};

  var limit = options.limit || 100,
    preserveTags =
      typeof options.preserveTags !== "undefined" ? options.preserveTags : true,
    wordBreak =
      typeof options.wordBreak !== "undefined" ? options.wordBreak : false,
    suffix = options.suffix || "...",
    moreLink = options.moreLink || "";

  var arr = html
    .replace(/</g, "\n<")
    .replace(/>/g, ">\n")
    .replace(/\n\n/g, "\n")
    .replace(/^\n/g, "")
    .replace(/\n$/g, "")
    .split("\n");

  var sum = 0,
    row,
    cut,
    add,
    tagMatch,
    tagName,
    tagStack = [],
    more = false;

  for (var i = 0; i < arr.length; i++) {
    row = arr[i];
    // count multiple spaces as one character
    let rowCut = row.replace(/[ ]+/g, " ");

    if (!row.length) {
      continue;
    }

    if (row[0] !== "<") {
      if (sum >= limit) {
        row = "";
      } else if (sum + rowCut.length >= limit) {
        cut = limit - sum;

        if (row[cut - 1] === " ") {
          while (cut) {
            cut -= 1;
            if (row[cut - 1] !== " ") {
              break;
            }
          }
        } else {
          add = row.substring(cut).split("").indexOf(" ");

          // break on halh of word
          if (!wordBreak) {
            if (add !== -1) {
              cut += add;
            } else {
              cut = row.length;
            }
          }
        }

        row = row.substring(0, cut) + suffix;

        if (moreLink) {
          row += '<a href="' + moreLink + '" style="display:inline">»</a>';
        }

        sum = limit;
        more = true;
      } else {
        sum += rowCut.length;
      }
    } else if (!preserveTags) {
      row = "";
    } else if (sum >= limit) {
      tagMatch = row.match(/[a-zA-Z]+/);
      tagName = tagMatch ? tagMatch[0] : "";

      if (tagName) {
        if (row.substring(0, 2) !== "</") {
          tagStack.push(tagName);
          row = "";
        } else {
          while (tagStack[tagStack.length - 1] !== tagName && tagStack.length) {
            tagStack.pop();
          }

          if (tagStack.length) {
            row = "";
          }

          tagStack.pop();
        }
      } else {
        row = "";
      }
    }

    arr[i] = row;
  }

  return {
    html: arr.join("\n").replace(/\n/g, ""),
    more: more,
  };
}
