import { RouteComponentProps } from "react-router"
import { RecordBookPreviewContent } from "../../components";
import { useCallback, useEffect, useState } from "react";
import { User } from "../../models/userModels";
import { useAppDispatch, useAppSelector } from "../../data/redux/hooks";
import { getTrainees } from "../../data/redux/traineeListSlice";
import LoadingPage from "../loading/LoadingPage";

interface TraineeReportDetailProps extends RouteComponentProps<{
    traineeId: string,
    filter?: string
}> { }


const TraineeReportPreview: React.FC<TraineeReportDetailProps> = (props) => {
    const traineeId = props.match.params.traineeId;
    const [selectedUser, setSelectedUser] = useState<User>();
    const { traineesWithOpenEntries: userList } = useAppSelector((state) => state.traineeList)
    const dispatch = useAppDispatch()

    const getTrainee = (id: string) => {
        const trainee = userList.find(x => String(x.id) === String(id))
        window.history.replaceState(null, "Meine Azubis", "/tabs/azubis/" + id)
        setSelectedUser(trainee)
    }

    const initTraineeList = useCallback(() => {
        dispatch(getTrainees())
    }, [dispatch])

    useEffect(() => {
        if (userList.length === 0) {
            initTraineeList()
        }

    }, [initTraineeList, userList])

    useEffect(() => {
        const getTraineeInitial = (id: string) => {
            const trainee = userList.find(x => String(x.id) === String(id))
            setSelectedUser(trainee)
        }
        if (traineeId && userList.length > 0) {
            getTraineeInitial(traineeId)
        }

    }, [traineeId, userList])



    if (selectedUser && userList.length > 0) {
        return (<RecordBookPreviewContent selectedUser={selectedUser} stats={selectedUser.stats} setSelectedUser={getTrainee} {...props} userList={userList} />)
    }
    return (<LoadingPage />);
}

export default TraineeReportPreview