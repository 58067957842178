import { IonButton } from "@ionic/react"
import { ReactNode } from "react";

import "./RoundedButton.scss";

interface RoundedButtonProps {
    children?: ReactNode,
    fill?: "clear" | "default" | "outline" | "solid" | undefined,
    color?: string,
    routerLink?: string
}

const RoundedButton: React.FC<RoundedButtonProps & React.HTMLProps<HTMLButtonElement>> = ({ children, fill = undefined, color, routerLink, ...props }: RoundedButtonProps) => {
    return (<IonButton color={color} className="roundedBtn" fill={fill} expand="block" shape="round" routerLink={routerLink} {...props}>{children}</IonButton>)
}

export default RoundedButton