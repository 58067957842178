import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonItem, IonLabel, IonModal, IonSelect, IonSelectOption, IonTitle, IonToolbar } from "@ionic/react"
import { useEffect, useMemo } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

interface FilterModalProps {
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void,
    group: string,
    setGroup: (group: string) => void,
    sort: string,
    setSort: (sort: string) => void
}

interface FilterTraineesFormData {
    group: string,
    sort: string
}

const FilterModal: React.FC<FilterModalProps> = ({ isOpen, setIsOpen, group, setGroup, sort, setSort }: FilterModalProps) => {
    const { control, handleSubmit, reset } = useForm<FilterTraineesFormData>({
        mode: "onTouched",
        reValidateMode: "onChange",
        defaultValues: useMemo(() => {
            return ({
                group: group,
                sort: sort
            })
        }, [group, sort])
    });

    useEffect(() => {
        reset({
            group: group,
            sort: sort
        })

        return () => {
            reset()
        }
    }, [reset, group, sort])

    const onSubmit: SubmitHandler<FilterTraineesFormData> = (data: FilterTraineesFormData) => {
        setGroup(data.group)
        setSort(data.sort)
        setIsOpen(false);
    }

    return (
        <IonModal isOpen={isOpen} onDidDismiss={() => setIsOpen(false)}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Filter</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <form onSubmit={handleSubmit(onSubmit)}>

                    {/* Gruppierung */}
                    <IonItem>
                        <IonLabel position="floating">Gruppierung</IonLabel>
                        <Controller
                            name="group"
                            control={control}
                            render={({ field }) => <IonSelect
                                interface="popover"
                                onIonChange={(ev) => field.onChange(ev.detail.value)}
                                value={field.value}
                            >
                                <IonSelectOption value="">Keine Gruppierung</IonSelectOption>
                                <IonSelectOption value="job">Berufsgruppe</IonSelectOption>
                                <IonSelectOption value="trainee_year">Lehrjahr</IonSelectOption>
                                <IonSelectOption value="location_id">Standort</IonSelectOption>
                                <IonSelectOption value="department_id">Abteilung</IonSelectOption>
                            </IonSelect>}
                        />
                    </IonItem>

                    {/* Sortierung */}
                    <IonItem>
                        <IonLabel position="floating">Sortierung</IonLabel>
                        <Controller
                            name="sort"
                            control={control}
                            render={({ field }) => <IonSelect
                                interface="popover"
                                onIonChange={(ev) => field.onChange(ev.detail.value)}
                                value={field.value}
                            >
                                <IonSelectOption value="submitted">Einträge unkontrolliert</IonSelectOption>
                                <IonSelectOption value="open">Einträge offen</IonSelectOption>
                                <IonSelectOption value="first_name">Name alphabetisch</IonSelectOption>
                            </IonSelect>}
                        />
                    </IonItem>

                </form>

            </IonContent>
            <IonFooter>
                <IonToolbar>
                    <IonButtons slot="end">
                        <IonButton role="cancel" onClick={() => setIsOpen(false)}>Abbrechen</IonButton>
                        <IonButton fill="solid" color="primary" onClick={handleSubmit(onSubmit)}>OK</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonFooter>
        </IonModal>
    )
}

export default FilterModal;