import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonIcon, IonItem, IonLoading, IonPage, IonRow, IonSelect, IonSelectOption } from "@ionic/react";
import { RouteComponentProps } from "react-router";
import { BackButtonRow, PageContainer, RoundedButton } from "../../components";

import "./PrintReportBook.scss";
import { printOutline } from "ionicons/icons";
import { useAppDispatch, useAppSelector } from "../../data/redux/hooks";
import { endOfWeek, format, startOfWeek } from "date-fns";
import ReportService from "../../data/services/ReportService";
import { PrintReportParams } from "../../models/reportModels";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { isPlatform } from '@ionic/react';
import { downloadPDFInWeb, downloadPDFNative, getWeeksArray } from "../../util/pdf-helper";
import { User } from "../../models/userModels";
import UserSelect from "../../components/admin/userSelect/UserSelect";
import { getTrainees } from "../../data/redux/traineeListSlice";
import { error500Text } from "../../util/error-helper";
import { setError } from "../../data/redux/errorSlice";


interface PrintReportFormData {
    start: string,
    end: string
}

interface PrintRecordBookProps extends RouteComponentProps<{
    traineeId?: string
}> { }

const PrintRecordBook: React.FC<PrintRecordBookProps> = ({ match }) => {
    const { user } = useAppSelector((state) => state.user)
    const { traineeId } = match.params;
    const { control, handleSubmit, formState: { errors }, reset } = useForm<PrintReportFormData>({
        mode: "onTouched",
        reValidateMode: "onChange",
        defaultValues: {
            start: "",
            end: ""
        }
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<User>();

    const [startArray, setStartArray] = useState<Date[]>([])
    const [endArray, setEndArray] = useState<Date[]>([])
    const { traineesWithOpenEntries: userList } = useAppSelector((state) => state.traineeList)
    const dispatch = useAppDispatch()

    useEffect(() => {
        let ignore = false;
        if (user && user.role !== "trainee" && userList.length <= 0) {
            if (!ignore) {
                dispatch(getTrainees())
            }
        }
        return () => {
            ignore = true;
        };
    }, [dispatch, user, userList.length])

    useEffect(() => {
        const getTraineeInitial = (id: string) => {
            const trainee = userList.find(x => String(x.id) === String(id))
            setSelectedUser(trainee)
            return trainee
        }

        if (user && user.role === "trainee") {
            setSelectedUser(user)
            setStartArray(getWeeksArray((user!.report_start === null ? user!.trainee_start! : user!.report_start!), format(new Date(), "yyyy-MM-dd")))
            setEndArray(getWeeksArray((user!.report_start === null ? user!.trainee_start! : user!.report_start!), format(new Date(), "yyyy-MM-dd")))
        } else {
            //TODO
            if (traineeId && userList.length > 0) {
                let trainee = getTraineeInitial(traineeId)
                setStartArray(getWeeksArray((trainee!.report_start === null ? trainee!.trainee_start! : trainee!.report_start!), format(new Date(), "yyyy-MM-dd")))
                setEndArray(getWeeksArray((trainee!.report_start === null ? trainee!.trainee_start! : trainee!.report_start!), format(new Date(), "yyyy-MM-dd")))
            }
        }
    }, [user, traineeId, userList])


    useEffect(() => {
        return () => {
            reset({
                start: "",
                end: ""
            })
        }
    }, [reset])

    const printReport = async (data: PrintReportParams) => {
        setLoading(true)
        try {
            const res = await ReportService.printReport(data);
            if (res.data.error) {
                dispatch(setError({
                    isOpen: true,
                    message: res.data.error
                }))
            } else if (res.status === 200) {
                if (!isPlatform('mobile') || isPlatform('mobileweb')) {
                    downloadPDFInWeb(res.data)
                } else {
                    downloadPDFNative(res.data)
                }
            } else {
                dispatch(setError({
                    isOpen: true,
                    message: error500Text
                }))
            }
        } catch (err) {
            dispatch(setError({
                isOpen: true,
                message: error500Text
            }))
        } finally {
            setLoading(false)
        }
    }


    const onSubmit: SubmitHandler<PrintReportFormData> = (data) => {
        const dataToSend: PrintReportParams = {
            start_week: format(new Date(data.start), "II"),
            start_year: format(new Date(data.start), "yyyy"),
            end_week: format(new Date(data.end), "II"),
            end_year: format(new Date(data.end), "yyyy")
        }
        if (user && user.role !== "trainee" && selectedUser) {
            dataToSend.user_id = selectedUser.id
        }
        printReport(dataToSend);
    }

    const handleStartChange = (e: { detail: { value: any; }; }, field: { onChange: (arg0: any) => void; }) => {
        field.onChange(e.detail.value);
        let temp = getWeeksArray(e.detail.value, format(new Date(), "yyyy-MM-dd"))
        setEndArray(temp)
    }

    const handleEndChange = (e: { detail: { value: any; }; }, field: { onChange: (arg0: any) => void; }) => {
        field.onChange(e.detail.value);
        if (user && user.role === "trainee") {
            let temp = getWeeksArray((user!.report_start === null ? user!.trainee_start! : user!.report_start), e.detail.value)
            setStartArray(temp)
        } else {
            let temp = getWeeksArray((selectedUser!.report_start === null ? selectedUser!.trainee_start! : selectedUser!.report_start), e.detail.value)
            setStartArray(temp)
        }

    }

    const getTrainee = (id: string) => {
        const trainee = userList.find(x => String(x.id) === String(id))
        window.history.replaceState(null, "Meine Azubis", "/tabs/azubis/" + id + "/berichtsheft-drucken")
        setSelectedUser(trainee)
        setStartArray(getWeeksArray((trainee.report_start === null ? trainee.trainee_start! : trainee.report_start!), format(new Date(), "yyyy-MM-dd")))
        setEndArray(getWeeksArray((trainee.report_start === null ? trainee.trainee_start! : trainee.report_start!), format(new Date(), "yyyy-MM-dd")))
    }

    return (
        <IonPage>
            <PageContainer name="Berichtsheft drucken" backButton={true} backButtonPath={(user && user.role !== "trainee" && selectedUser) ? `tabs/azubis/${selectedUser.id}` : "tabs/berichtsheft"} >
                <>
                    <IonGrid>

                        <BackButtonRow path={(user && user.role !== "trainee" && selectedUser) ? `/tabs/azubis/${selectedUser!.id}` : "/tabs/berichtsheft"} />

                        <IonRow className="ion-hide-lg-down">
                            <IonCol>
                                <IonCard>
                                    <IonCardHeader>
                                        <div className="reportDesktopHeader">
                                            <div className="minWidthCol">
                                                <IonCardTitle className="headline">Berichtsheft drucken</IonCardTitle>
                                            </div>
                                            <div className="minWidthCol" style={{ textAlign: "right" }}>
                                                {user && user.role !== "trainee" && userList && selectedUser &&
                                                    <UserSelect selectedUserId={selectedUser.id} setSelectedUserId={getTrainee} userList={userList} />
                                                }
                                            </div>
                                        </div>

                                    </IonCardHeader>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonCard>
                                    <IonCardHeader>
                                        <IonCardTitle>PDF erzeugen</IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        {user && user.role !== "trainee" && userList && selectedUser &&
                                            <div style={{ marginBottom: 12 }} className="ion-hide-lg-up">
                                                <div>Azubi</div>
                                                <UserSelect selectedUserId={selectedUser.id} setSelectedUserId={getTrainee} userList={userList} />
                                            </div>
                                        }

                                        Zeitraum
                                        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                                            <IonGrid className="ion-no-padding">
                                                <IonRow>
                                                    <IonCol sizeXs="12" sizeMd="6">
                                                        <IonItem className="ion-text-wrap">
                                                            <Controller
                                                                name="start"
                                                                control={control}
                                                                rules={{
                                                                    required: { value: true, message: "Bitte gib einen Startzeitpunkt an" },
                                                                }}
                                                                render={({ field }) => <IonSelect
                                                                    aria-label="Beginn"
                                                                    label="Beginn"
                                                                    labelPlacement="floating"
                                                                    interface="alert"
                                                                    interfaceOptions={{
                                                                        message: "Wähle Beginn",
                                                                        translucent: true,
                                                                        cssClass: "printReportSelectAlert"
                                                                    }}
                                                                    value={field.value}
                                                                    aria-invalid={!!errors.start}
                                                                    okText="OK"
                                                                    cancelText="Abbrechen"
                                                                    onIonChange={(e) => { handleStartChange(e, field) }}
                                                                    onIonBlur={() => field.onBlur()}
                                                                >{startArray.map((week) => {
                                                                    return (
                                                                        <IonSelectOption
                                                                            className="ion-text-wrap"
                                                                            key={format(week, "yyyy-MM-dd")}
                                                                            value={format(week, "yyyy-MM-dd")}>
                                                                            KW{format(week, "II")} {format(startOfWeek(week, { weekStartsOn: 1 }), "dd.MM")}-{format(endOfWeek(week, { weekStartsOn: 1 }), "dd.MM.yyyy")}
                                                                        </IonSelectOption>
                                                                    )
                                                                })}

                                                                </IonSelect>}
                                                            />
                                                        </IonItem>
                                                        <div className="errorText">{errors.start?.message}</div>
                                                    </IonCol>
                                                    <IonCol sizeXs="12" sizeMd="6">
                                                        <IonItem className="ion-text-wrap">
                                                            <Controller
                                                                name="end"
                                                                control={control}
                                                                rules={{
                                                                    required: { value: true, message: "Bitte gib einen Endzeitpunkt an" },
                                                                }}
                                                                render={({ field }) => <IonSelect
                                                                    aria-label="Ende"
                                                                    label="Ende"
                                                                    labelPlacement="floating"
                                                                    interface="alert"
                                                                    interfaceOptions={{
                                                                        message: "Wähle Ende",
                                                                        translucent: true,
                                                                        cssClass: "printReportSelectAlert"
                                                                    }}
                                                                    value={field.value}
                                                                    aria-invalid={!!errors.start}
                                                                    okText="OK"
                                                                    cancelText="Abbrechen"
                                                                    onIonChange={(e) => { handleEndChange(e, field) }}
                                                                    onIonBlur={() => field.onBlur()}
                                                                >{endArray.map((week) => {
                                                                    return (
                                                                        <IonSelectOption
                                                                            className="ion-text-wrap"
                                                                            key={format(week, "yyyy-MM-dd")}
                                                                            value={format(week, "yyyy-MM-dd")}
                                                                        >
                                                                            KW{format(week, "II")} {format(startOfWeek(week, { weekStartsOn: 1 }), "dd.MM")}-{format(endOfWeek(week, { weekStartsOn: 1 }), "dd.MM.yyyy")}
                                                                        </IonSelectOption>)
                                                                })}

                                                                </IonSelect>}
                                                            />
                                                        </IonItem>
                                                        <div className="errorText">{errors.end?.message}</div>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <div className="printBtnContainer">
                                                            <RoundedButton type="submit" >
                                                                PDF erzeugen
                                                                <IonIcon icon={printOutline} />
                                                            </RoundedButton>
                                                        </div>
                                                    </IonCol>
                                                </IonRow>

                                            </IonGrid>
                                        </form>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <IonLoading
                        isOpen={loading}
                        message="Lädt..."
                    />
                </>
            </PageContainer>
        </IonPage >
    )
}

export default PrintRecordBook;