import { intervalToDuration } from "date-fns";
import {
  alertCircleOutline,
  checkmarkCircleOutline,
  checkmarkDoneCircleOutline,
  ellipseOutline,
} from "ionicons/icons";
import { ReportDay } from "../models/reportModels";

export const mapColorIonVar = (key: string) => {
  switch (key) {
    case "open":
      return "var(--ion-color-warning)";
    case "dismissed":
      return "var(--ion-color-danger)";
    case "submitted":
      return "var(--ion-color-primary)";
    case "completed":
      return "var(--ion-color-success)";
    default:
      return "var(--ion-color-warning)";
  }
};

export const mapColor = (key: string) => {
  switch (key) {
    case "open":
      return "warning";
    case "dismissed":
      return "danger";
    case "submitted":
      return "primary";
    case "completed":
      return "success";
    default:
      return "warning";
  }
};

export const mapIcon = (key: string) => {
  switch (key) {
    case "open":
      return ellipseOutline;
    case "dismissed":
      return alertCircleOutline;
    case "submitted":
      return checkmarkCircleOutline;
    case "completed":
      return checkmarkDoneCircleOutline;
    default:
      return ellipseOutline;
  }
};

export const mapAzubiStateName = (key: string) => {
  switch (key) {
    case "open":
      return "offen";
    case "dismissed":
      return "abgelehnt";
    case "submitted":
      return "eingereicht";
    case "completed":
      return "erledigt";
    default:
      return "offen";
  }
};

export const stateList = ["completed", "submitted", "dismissed", "open"];
export const stateListSmall = ["submitted", "open"]

/* Report Day Helper -> Duration calc*/
export const secToHHMM = (sec: number) => {
  let dateObj = intervalToDuration({ start: 0, end: sec * 1000 });
  //console.log(dateObj)
  let hours = dateObj.hours ?  formatDuration(dateObj.hours) : "00";
  let minutes = dateObj.minutes ? formatDuration(dateObj.minutes) : "00"
  return hours + ":" + minutes;
};

export const formatDuration = (val: number | undefined) => {
  if (String(val).length < 2) {
    return "0" + val;
  }
  return val;
};

export function toSeconds(time: string) {
  var hours = Number(time.slice(0, 2));
  var minutes = Number(time.slice(3, 5));
  return hours * 60 * 60 + minutes * 60;
}

export function sum(a: number, b: number) {
  return a + b;
}

export const calcTotalTime = (day: ReportDay) => {
  let totalSeconds = 0;
  if (day.entries.length > 0) {
    totalSeconds = day.entries
      .map((entry) => toSeconds(entry.duration))
      .reduce(sum);
  }
  return totalSeconds;
};
