import { IonContent, IonPage, IonSpinner } from "@ionic/react";

const LoadingPage = () => {
    return (
        <IonPage>
            <IonContent>
                <div className="initialisingContainer">
                    <img src="./assets/images/logo_woodbook_login_dark.png" alt="Woodbook Logo" />
                    <div><IonSpinner color="primary" ></IonSpinner></div>
                </div>
            </IonContent>
        </IonPage>
    )
}

export default LoadingPage;