import { IonIcon, IonLabel, IonSegment, IonSegmentButton, IonToolbar } from "@ionic/react"
import { Stat } from "../../models/reportModels"
import { mapColor, mapIcon, stateList, stateListSmall } from "../../util/report-helper"
import { useAppSelector } from "../../data/redux/hooks"

type SegmentToolbarProps = {
    val: Stat,
    setVal: (val: Stat) => void,
    stats: Stat | undefined
}

const SegmentToolbar: React.FC<SegmentToolbarProps> = ({ val, setVal, stats }: SegmentToolbarProps) => {
    const { user } = useAppSelector((state) => state.user)

    const handlSegmentChange = (e: any) => {
        let temp: Stat = {
            completed: 0, dismissed: 0, submitted: 0, open: 0
        };
        if (e.detail.value && e.detail.value !== "all") {
            temp[e.detail.value] = 1;
        }
        setVal(temp);
    }

    const getVal = () => {
        for (const [key, value] of Object.entries(val)) {
            if (value === 1) {
                return key;
            }
        }
        return "all"
    }

    return (
        <IonToolbar className="ion-hide-lg-up">
            <IonSegment value={getVal()} onIonChange={handlSegmentChange} scrollable={true}>
                <IonSegmentButton value="all">
                    <IonLabel>
                        Alle
                    </IonLabel>
                </IonSegmentButton>

                {(user && user.company_id) ? stateList.map(state => {
                    return (
                        <IonSegmentButton layout="icon-start" value={state} key={state}>
                            <IonIcon color={mapColor(state)} icon={mapIcon(state)} />
                            <IonLabel>
                                <b>{stats && stats[state]}</b>
                            </IonLabel>
                        </IonSegmentButton>
                    )
                }) : stateListSmall.map(state => {
                    return (
                        <IonSegmentButton layout="icon-start" value={state} key={state}>
                            <IonIcon color={mapColor(state)} icon={mapIcon(state)} />
                            <IonLabel>
                                <b>{stats && stats[state]}</b>
                            </IonLabel>
                        </IonSegmentButton>
                    )
                })}
            </IonSegment>
        </IonToolbar>
    )
}

export default SegmentToolbar