import { IonAvatar, IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonImg, IonItem, IonMenuButton, IonRouterLink, IonTitle, IonToast, IonToolbar } from "@ionic/react"
import { isPlatform } from '@ionic/react';
import "./PageContainer.scss"
import SideMenuDesktop from "../menus/SideMenuDesktop"
import Notifications from "../notifications/Notifications"
import Footer from "../footer/Footer"
import SegmentToolbar from "../segmentToolbar/SegmentToolbar"
import { Stat, WeekSwitcherProps } from "../../models/reportModels"
import WeekSwitcher from "../reportComponents/WeekSwitcher"
import { mapProfileRoleText } from "../../util/profile-helper";
import useWindowDimensions from "../../data/customHooks/useWindowDimensions";
import { useAppDispatch, useAppSelector } from "../../data/redux/hooks";
import { setError } from "../../data/redux/errorSlice";

interface PageContainerProps {
    children: React.ReactElement,
    name: string,
    backButton: boolean,
    backButtonPath?: string,
    otherButton?: boolean
    otherButtonIcon?: string,
    otherButtonAction?: () => void;
    reportToolbar?: boolean,
    reportToolbarVal?: Stat,
    reportToolbarCallback?: (val: Stat) => void,
    reportToolbarStats?: Stat,
    weekSwitcherToolbar?: boolean,
    weekSwitcherProps?: WeekSwitcherProps
}


const Profile = () => {
    const { user } = useAppSelector((state) => state.user)

    if (user) {
        return (
            <IonItem routerLink="/tabs/profil" style={{ textDecoration: "none" }} lines="none">
                <div className="profileContainer" >
                    <div className="profileTestContainer">
                        <span className="profileTitle">{user.first_name + " " + user.name}</span><br />
                        <span className="profileSubtitle">{mapProfileRoleText(user)}</span>
                    </div>
                    <IonAvatar>
                        <img src={user.image ? user.image : "./assets/images/profile_placeholder.jpg"} alt="Profilbild" />
                    </IonAvatar>
                </div>
            </IonItem>
        )
    }
    return null

}

const PageContainer: React.FC<PageContainerProps> = ({ children, name, backButton = false, backButtonPath, otherButton = false, otherButtonIcon, otherButtonAction, reportToolbar, reportToolbarVal, reportToolbarCallback, reportToolbarStats, weekSwitcherToolbar, weekSwitcherProps }: PageContainerProps) => {
    const { width } = useWindowDimensions();
    const error = useAppSelector((state) => state.error.error)
    const dispatch = useAppDispatch();

    const dismissToast = () => {
        dispatch(setError({
            message: "", isOpen: false
        }))
    }


    return (
        <>

            <IonHeader>
                {width < 992 &&
                    <IonToolbar /* className="ion-hide-lg-up" */>
                        <IonTitle>{name}</IonTitle>

                        {backButton &&
                            <IonButtons slot="start">
                                <IonBackButton text={isPlatform('ios') ? "zurück" : undefined} /* defaultHref={backButtonPath ? backButtonPath : undefined} */ ></IonBackButton>
                            </IonButtons>
                        }

                        <IonButtons slot="end">
                            {otherButton && <IonButton onClick={otherButtonAction}>
                                <IonIcon icon={otherButtonIcon} />
                            </IonButton>}
                            <IonMenuButton />
                        </IonButtons>
                    </IonToolbar>
                }

                {reportToolbar && reportToolbarVal && reportToolbarCallback &&
                    <SegmentToolbar val={reportToolbarVal} setVal={reportToolbarCallback} stats={reportToolbarStats} />
                }

                {weekSwitcherToolbar && weekSwitcherProps &&
                    <IonToolbar className="ion-hide-lg-up">
                        <div className="toolbarWeekSwitcherContainer">
                            <WeekSwitcher {...weekSwitcherProps} />
                        </div>
                    </IonToolbar>
                }


                {width >= 992 && <IonToolbar className="dektopToolbar">
                    <div className="dektopToolbarContainer">
                        <IonTitle>
                            <IonRouterLink routerLink="/tabs/dashboard">
                                <IonImg src={"./assets/images/logo_woodbook.png"} alt="Woodbook" />
                            </IonRouterLink>
                        </IonTitle>
                        {backButton &&
                            <IonButtons slot="start">
                                <IonBackButton defaultHref={backButtonPath} />
                            </IonButtons>
                        }

                        <div slot="end">
                            <Profile />
                        </div>
                    </div>

                </IonToolbar>}
            </IonHeader>
            <IonContent>
                <div className="contentContainer">
                    <IonHeader collapse="condense">
                        <IonToolbar>
                            <IonTitle>{name}</IonTitle>
                        </IonToolbar>
                    </IonHeader>

                    <div className="wrapper">
                        {width >= 992 && <div className="sidebar" role="complementary">
                            <div>
                                <SideMenuDesktop />
                            </div>
                            <div style={{ marginTop: 30 }}>
                                <Notifications />
                            </div>
                        </div>}
                        <div className="main" role="main">
                            {children}
                        </div>

                    </div>

                    <IonToast
                        message={error.message}
                        isOpen={error.isOpen}
                        layout="stacked"
                        duration={8000}
                        color={"danger"}
                        buttons={[
                            {
                                text: 'Schließen',
                                role: 'cancel',
                                handler: dismissToast,
                            },
                        ]}
                        onDidDismiss={dismissToast}
                    />

                    <Footer />
                </div>
            </IonContent>
        </>
    )
}

export default PageContainer;