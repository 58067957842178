import { format } from "date-fns";
import { useAppSelector } from "../../data/redux/hooks";

const Greeting = () => {
    const hour = parseInt(format(new Date(), "H"));
    const { user } = useAppSelector((state) => state.user);

    const getDateTimeGreeting = () => {
        if (hour >= 5 && hour <= 11) {
            return "Guten Morgen"
        } else if (hour >= 18 && hour <= 23) {
            return "Guten Abend"
        } else {
            return "Hallo"
        }
    }


    return (
        <h2 className="greeting">{getDateTimeGreeting()} {user && user.first_name}</h2>
    )
}

export default Greeting;