import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Company } from "../../models/companyModels";
import CompanyService from "../services/CompanyService";
import LocationService from "../services/LocationService";
import DepartmentService from "../services/DepartmentService";
import { getGroupedAndSortedTrainees, getTrainees } from "./traineeListSlice";
import { getReportStats } from "./reportStatsSlice";
import { setCompanyName } from "./userSlice";
import UserService from "../services/UserService";
import { setError } from "./errorSlice";
import { error500Text } from "../../util/error-helper";

interface CompanyState {
  loading: boolean;
  error: undefined | string;
  company: Company | undefined;
  success: undefined | string;
}

const initialState: CompanyState = {
  loading: false,
  error: undefined,
  company: undefined,
  success: undefined
};

export const getCompany = createAsyncThunk(
  "company/getCompany",
  async (_data, { rejectWithValue, dispatch }) => {
    try {
      const res = await CompanyService.getCompany();

      if (res.error) {
        dispatch(setError({
          isOpen: true,
          message: res.error
        }))
        return rejectWithValue(res.error);
      } else if (res.result) {
        return res.result;
      } else {
        dispatch(setError({
          isOpen: true,
          message: error500Text
        }))
        return rejectWithValue(error500Text);
      }
    } catch (err) {
      dispatch(setError({
        isOpen: true,
        message: error500Text
      }))
      return rejectWithValue(err);
    }
  }, {
  condition: (_data, { getState }) => {
    /* @ts-ignore */
    const { company } = getState();
    if (company.company || company.loading === true) {
      return false
    }
  }
}
);

export const editCompany = createAsyncThunk(
  "company/editCompany",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const res = await CompanyService.editCompany(data);
      if (res.error) {
        dispatch(setError({
          isOpen: true,
          message: res.error
        }))
        return rejectWithValue(res.error);
      } else if (res.company) {
        dispatch(setCompanyName(res.company.name));
        return res.company;
      } else {
        dispatch(setError({
          isOpen: true,
          message: error500Text
        }))
        return rejectWithValue(error500Text);
      }
    } catch (err) {
      dispatch(setError({
        isOpen: true,
        message: error500Text
      }))
      return rejectWithValue(error500Text);
    }
  }
)

export const addEmployee = createAsyncThunk(
  "company/addEmployee",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const res = await CompanyService.addEmployee(data);
      if (res.error) {
        dispatch(setError({
          isOpen: true,
          message: res.error
        }))
        return rejectWithValue(res.error);
      } else if (res.user) {
        res.user.role = data.role;
        return res.user;
      } else {
        dispatch(setError({
          isOpen: true,
          message: error500Text
        }))
        return rejectWithValue(error500Text);
      }
    } catch (err) {
      dispatch(setError({
        isOpen: true,
        message: error500Text
      }))
      return rejectWithValue(err);
    }
  }
);

export const deleteEmployee = createAsyncThunk(
  "company/deleteEmployee",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const res = await CompanyService.deleteEmployee(data);
      if (res.error) {
        dispatch(setError({
          isOpen: true,
          message: res.error
        }))
        return rejectWithValue(res.error);
      } else if (res.success) {
        return data;
      } else {
        dispatch(setError({
          isOpen: true,
          message: error500Text
        }))
        return rejectWithValue(error500Text);
      }
    } catch (err) {
      dispatch(setError({
        isOpen: true,
        message: error500Text
      }))
      rejectWithValue(err);
    }
  }
);

export const connectTraineeAndTrainer = createAsyncThunk(
  "company/connectTraineeAndTrainer",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const res = await CompanyService.connectTraineeAndTrainer(data);
      if (res.error) {
        dispatch(setError({
          isOpen: true,
          message: res.error
        }))
        return rejectWithValue(res.error);
      } else if (res.result) {
        if (data.reload) {
          dispatch(getTrainees());
          dispatch(
            getGroupedAndSortedTrainees({ group: "", sort: "first_name" })
          );
          dispatch(getReportStats())
        }
        return res.result;
      } else {
        dispatch(setError({
          isOpen: true,
          message: error500Text
        }))
        return rejectWithValue(error500Text);
      }
    } catch (err) {
      dispatch(setError({
        isOpen: true,
        message: error500Text
      }))
      rejectWithValue(err);
    }
  }
);

export const disconnectTraineeAndTrainer = createAsyncThunk(
  "company/disconnectTraineeAndTrainer",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const res = await CompanyService.disconnectTraineeAndTrainer(data);
      if (res.error) {
        dispatch(setError({
          isOpen: true,
          message: res.error
        }))
        return rejectWithValue(res.error);
      } else if (res.result) {
        if (data.reload) {
          dispatch(getTrainees());
          dispatch(
            getGroupedAndSortedTrainees({ group: "", sort: "first_name" })
          );
          dispatch(getReportStats())
        }
        return res.result;
      } else {
        dispatch(setError({
          isOpen: true,
          message: error500Text
        }))
        return rejectWithValue(error500Text);
      }
    } catch (err) {
      dispatch(setError({
        isOpen: true,
        message: error500Text
      }))
      rejectWithValue(err);
    }
  }
);

/* Location */
export const createLocation = createAsyncThunk(
  "company/createLocation",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const res = await LocationService.createLocation(data);
      if (res.error) {
        dispatch(setError({
          isOpen: true,
          message: res.error
        }))
        return rejectWithValue(res.error);
      } else if (res.location) {
        return res.location;
      } else {
        dispatch(setError({
          isOpen: true,
          message: error500Text
        }))
        return rejectWithValue(error500Text);
      }
    } catch (err) {
      dispatch(setError({
        isOpen: true,
        message: error500Text
      }))
      rejectWithValue(err);
    }
  }
);

//TODO update azubis
export const deleteLocation = createAsyncThunk(
  "company/deleteLocation",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const res = await LocationService.deleteLocation(data);
      if (res.error) {
        dispatch(setError({
          isOpen: true,
          message: res.error
        }))
        return rejectWithValue(res.error);
      } else if (res.success) {
        return data;
      } else {
        dispatch(setError({
          isOpen: true,
          message: error500Text
        }))
        return rejectWithValue(error500Text);
      }
    } catch (err) {
      dispatch(setError({
        isOpen: true,
        message: error500Text
      }))
      rejectWithValue(err);
    }
  }
);

//TODO update azubis
export const editLocation = createAsyncThunk(
  "company/editLocation",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const res = await LocationService.editLocation(data);
      if (res.error) {
        dispatch(setError({
          isOpen: true,
          message: res.error
        }))
        return rejectWithValue(res.error);
      } else if (res.location) {
        return res.location;
      } else {
        dispatch(setError({
          isOpen: true,
          message: error500Text
        }))
        return rejectWithValue(error500Text);
      }
    } catch (err) {
      dispatch(setError({
        isOpen: true,
        message: error500Text
      }))
      rejectWithValue(err);
    }
  }
);

/* Department */
export const createDepartment = createAsyncThunk(
  "company/createDepartment",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const res = await DepartmentService.createDepartment(data);
      if (res.error) {
        dispatch(setError({
          isOpen: true,
          message: res.error
        }))
        return rejectWithValue(res.error);
      } else if (res.department) {
        return res.department;
      } else {
        dispatch(setError({
          isOpen: true,
          message: error500Text
        }))
        return rejectWithValue(error500Text);
      }
    } catch (err) {
      dispatch(setError({
        isOpen: true,
        message: error500Text
      }))
      rejectWithValue(err);
    }
  }
);


//TODO update azubis
export const deleteDepartment = createAsyncThunk(
  "company/deleteDepartment",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const res = await DepartmentService.deleteDepartment(data);
      if (res.error) {
        dispatch(setError({
          isOpen: true,
          message: res.error
        }))
        return rejectWithValue(res.error);
      } else if (res.success) {
        dispatch(
          getGroupedAndSortedTrainees({ group: "", sort: "first_name" })
        );
        return data;
      } else {
        dispatch(setError({
          isOpen: true,
          message: error500Text
        }))
        return rejectWithValue(error500Text);
      }
    } catch (err) {
      dispatch(setError({
        isOpen: true,
        message: error500Text
      }))
      rejectWithValue(err);
    }
  }
);

//TODO update azubis
export const editDepartment = createAsyncThunk(
  "company/editDepartment",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const res = await DepartmentService.editDepartment(data);
      if (res.error) {
        dispatch(setError({
          isOpen: true,
          message: res.error
        }))
        return rejectWithValue(res.error);
      } else if (res.department) {
        dispatch(
          getGroupedAndSortedTrainees({ group: "", sort: "first_name" })
        );
        return res.department;
      } else {
        dispatch(setError({
          isOpen: true,
          message: error500Text
        }))
        return rejectWithValue(error500Text);
      }
    } catch (err) {
      dispatch(setError({
        isOpen: true,
        message: error500Text
      }))
      rejectWithValue(err);
    }
  }
);

export const editTrainee = createAsyncThunk(
  "company/editTrainee",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const res = await CompanyService.addUserToLocationDepartment(data);
      if (res.error) {
        dispatch(setError({
          isOpen: true,
          message: res.error
        }))
        return rejectWithValue(res.error);
      } else if (res.user) {
        dispatch(
          getGroupedAndSortedTrainees({ group: "", sort: "first_name" })
        );
        return res.user;
      } else {
        dispatch(setError({
          isOpen: true,
          message: error500Text
        }))
        return rejectWithValue(error500Text);
      }
    } catch (err) {
      dispatch(setError({
        isOpen: true,
        message: error500Text
      }))
      rejectWithValue(err);
    }
  }
);

export const deleteTraineeInvitation = createAsyncThunk(
  "company/deleteTraineeInvitation",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const res = await UserService.deleteTraineeInvitation(data);
      if (res.error) {
        dispatch(setError({
          isOpen: true,
          message: res.error
        }))
        return rejectWithValue(res.error);
      } else if (res.token) {
        return res.token;
      } else {
        dispatch(setError({
          isOpen: true,
          message: error500Text
        }))
        return rejectWithValue(error500Text);
      }
    } catch (err) {
      dispatch(setError({
        isOpen: true,
        message: error500Text
      }))
      rejectWithValue(err);
    }
  }
);

export const inviteTrainee = createAsyncThunk(
  "company/inviteTrainee",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const res = await UserService.inviteTrainee(data);
      if (res.error) {
        dispatch(setError({
          isOpen: true,
          message: res.error
        }))
        return rejectWithValue(res.error);
      } else if (res.token) {
        return res;
      } else {
        dispatch(setError({
          isOpen: true,
          message: error500Text
        }))
        return rejectWithValue(error500Text);
      }
    } catch (err) {
      dispatch(setError({
        isOpen: true,
        message: error500Text
      }))
      rejectWithValue(err);
    }
  }
);


export const changeCompanyLogo = createAsyncThunk(
  "company/changeCompanyLogo",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const res = await CompanyService.changeCompanyLogo(data);
      if (res.error) {
        dispatch(setError({
          isOpen: true,
          message: res.error
        }))
        return rejectWithValue(res.error)
      } else if (res.company) {
        return res.company.image
      } else {
        dispatch(setError({
          isOpen: true,
          message: error500Text
        }))
        return rejectWithValue(error500Text)
      }
    } catch (err) {
      dispatch(setError({
        isOpen: true,
        message: error500Text
      }))
      return rejectWithValue(err)
    }
  }
)


const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /* get Company */
    builder.addCase(getCompany.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(getCompany.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.company = payload;
    });
    builder.addCase(getCompany.rejected, (state, { payload }) => {
      state.loading = false;
      /* @ts-ignore */
      state.error = payload;
    });

    /* change Company Logo */
    builder.addCase(changeCompanyLogo.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(changeCompanyLogo.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (state.company) {
        state.company.company.image = payload;
      }
    });
    builder.addCase(changeCompanyLogo.rejected, (state, { payload }) => {
      state.loading = false;
      /* @ts-ignore */
      state.error = payload;
    });

    /* edit Company */
    builder.addCase(editCompany.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(editCompany.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (state.company) {
        state.company.company.name = payload.name;
      }
    });
    builder.addCase(editCompany.rejected, (state, { payload }) => {
      state.loading = false;
      /* @ts-ignore */
      state.error = payload;
    });

    /* add Employee */
    builder.addCase(addEmployee.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(addEmployee.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      /* @ts-ignore */
      state.company[payload.role + "s"].push(payload);
    });
    builder.addCase(addEmployee.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });

    /* delete Employee */
    builder.addCase(deleteEmployee.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(deleteEmployee.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      /* @ts-ignore */
      if (payload.role === "trainee") {
        /* @ts-ignore */
        state.company[payload.role] = state.company[payload.role].filter(
          (el: any) => el.id !== payload.id
        );
      } else {
        /* @ts-ignore */
        state.company[payload.role + "s"] = state.company[
          payload.role + "s"
        ].filter((el: any) => el.id !== payload.id);
      }
    });
    builder.addCase(deleteEmployee.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });

    /* connect Trainee and Trainer */
    builder.addCase(connectTraineeAndTrainer.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(
      connectTraineeAndTrainer.fulfilled,
      (state, { payload }: any) => {
        state.loading = false;
        state.company = payload;
      }
    );
    builder.addCase(
      connectTraineeAndTrainer.rejected,
      (state, { payload }: any) => {
        state.loading = false;
        state.error = payload;
      }
    );

    /* disconnect Trainee and Trainer */
    builder.addCase(disconnectTraineeAndTrainer.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(
      disconnectTraineeAndTrainer.fulfilled,
      (state, { payload }: any) => {
        state.loading = false;
        /* @ts-ignore */
        state.company = payload;
      }
    );
    builder.addCase(
      disconnectTraineeAndTrainer.rejected,
      (state, { payload }: any) => {
        state.loading = false;
        state.error = payload;
      }
    );

    /* create location */
    builder.addCase(createLocation.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(createLocation.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.company!.locations.push({ name: payload.name, id: payload.id });
    });
    builder.addCase(createLocation.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });

    /* delete location */
    builder.addCase(deleteLocation.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(deleteLocation.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.company!.locations = state.company!.locations.filter(
        (el: any) => el.id !== payload.id
      );
    });
    builder.addCase(deleteLocation.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });

    /* edit location */
    builder.addCase(editLocation.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(editLocation.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.company!.locations = state.company!.locations.map((el) =>
        el.id === payload.id
          ? Object.assign({}, el, { name: payload.name })
          : el
      );
    });
    builder.addCase(editLocation.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });

    /* create department */
    builder.addCase(createDepartment.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(createDepartment.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.company!.departments.push({ name: payload.name, id: payload.id });
    });
    builder.addCase(createDepartment.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });

    /* delete department */
    builder.addCase(deleteDepartment.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(deleteDepartment.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.company!.departments = state.company!.departments.filter(
        (el: any) => el.id !== payload.id
      );
    });
    builder.addCase(deleteDepartment.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });

    /* edit department */
    builder.addCase(editDepartment.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(editDepartment.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.company!.departments = state.company!.departments.map((el) =>
        el.id === payload.id
          ? Object.assign({}, el, { name: payload.name })
          : el
      );
    });
    builder.addCase(editDepartment.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });

    /* edit Trainee */
    builder.addCase(editTrainee.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(editTrainee.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.company!.trainee = state.company!.trainee.map((el) =>
        el.id === payload.id
          ? Object.assign({}, el, {
            location_id: payload.location_id,
            department_id: payload.department_id,
          })
          : el
      );
    });
    builder.addCase(editTrainee.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });


    /* delete trainee invitation */
    builder.addCase(deleteTraineeInvitation.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(deleteTraineeInvitation.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.company!.tokens = state.company!.tokens.filter(
        (el: any) => el.id !== payload.id
      );
    });
    builder.addCase(deleteTraineeInvitation.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.error = payload;
    });

    /* add trainee invitation */
    builder.addCase(inviteTrainee.pending, (state) => {
      state.loading = true;
      state.error = undefined;
      state.success = undefined;
    });
    builder.addCase(inviteTrainee.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.company!.tokens.push(payload.token);
      state.success = payload.success;
    });
    builder.addCase(inviteTrainee.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.error = payload;
      state.success = undefined;
    });
  },
});

export default companySlice.reducer;
