import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonInput, IonItem, IonModal,  IonTitle, IonToolbar } from "@ionic/react"
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { EmailFormData } from "../../../models/userModels";
import { handleEmailValidation } from "../../../util/helper";
import { useAppDispatch } from "../../../data/redux/hooks";
import { inviteTrainee } from "../../../data/redux/companySlice";

interface MailInvitationModalProps {
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void
}

const MailInvitationModal: React.FC<MailInvitationModalProps> = ({ isOpen, setIsOpen }: MailInvitationModalProps) => {
    const { control, handleSubmit, formState: { errors } } = useForm<EmailFormData>({
        mode: "onTouched",
        reValidateMode: "onChange"
    });
    const dispatch = useAppDispatch();


    const onSubmit: SubmitHandler<EmailFormData> = (data: EmailFormData) => {
        dispatch(inviteTrainee(data))
        setIsOpen(false)
    }


    return (
        <IonModal isOpen={isOpen} aria-labelledby="inviteTraineeTitle" onDidDismiss={() => setIsOpen(false)}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle id="inviteTraineeTitle">
                        Azubi einladen
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <p>Bitte geben Sie die E-Mail Ihres Azubis ein.</p>
                <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                        <IonItem className="centeredPreAuthInput">
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: { value: true, message: "Bitte geben Sie eine E-Mail-Adresse an" },
                                    validate: { value: handleEmailValidation }
                                }}

                                render={({ field }) => <IonInput
                                    label="E-Mail"
                                    labelPlacement="floating"
                                    type="email"
                                    aria-invalid={!!errors.email}
                                    /* @ts-ignore */
                                    onIonChange={(e) => field.onChange(e.detail.value)}
                                    onIonBlur={() => field.onBlur()}
                                />}
                            />
                        </IonItem>
                        {errors.email && <div className="errorText">{errors.email.message}</div>}

                </form>

            </IonContent>
            <IonFooter>
                <IonToolbar>
                    <IonButtons slot="end" className="ion-padding">
                        <IonButton onClick={() => setIsOpen(false)}>Abbrechen</IonButton>
                        <IonButton type="submit" onClick={handleSubmit(onSubmit)} fill="solid" color="primary">Senden</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonFooter>
        </IonModal>
    )
}

export default MailInvitationModal;