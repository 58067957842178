import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonItem, IonModal, IonSelect, IonSelectOption, IonTitle, IonToolbar } from "@ionic/react";
import { Department, Location, Trainee } from "../../../models/companyModels";
import { useAppDispatch, useAppSelector } from "../../../data/redux/hooks";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useEffect } from "react";
import { editTrainee } from "../../../data/redux/companySlice";

interface EditAzubiModalProps {
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void,
    selectedTrainee?: Trainee,
}

interface UserLocationDepartmentFormData {
    user_id: number,
    location_id: number | null,
    department_id: number | null
}

const EditAzubiModal: React.FC<EditAzubiModalProps> = ({ isOpen, setIsOpen, selectedTrainee }) => {
    const { company } = useAppSelector((state) => state.company)
    const { control, handleSubmit, reset } = useForm<UserLocationDepartmentFormData>({
        mode: "onTouched",
        reValidateMode: "onChange",
        defaultValues: {
            location_id: null,
            department_id: null
        }
    });
    const dispatch = useAppDispatch();

    const onSubmit: SubmitHandler<UserLocationDepartmentFormData> = (data: UserLocationDepartmentFormData) => {
        const dataToSend = {
            user_id: selectedTrainee?.id,
            location_id: data.location_id || null,
            department_id: data.department_id || null
        }
        dispatch(editTrainee(dataToSend))
        setIsOpen(false);
    }

    useEffect(() => {
        if (isOpen && selectedTrainee) {
            reset({
                location_id: selectedTrainee.location_id || null,
                department_id: selectedTrainee.department_id || null
            })
        }
    }, [reset, isOpen, selectedTrainee])

    return (
        <IonModal isOpen={isOpen} onDidDismiss={() => setIsOpen(false)}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Azubi bearbeiten</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <p>Hier können Sie Ihren Azubi <b>{selectedTrainee?.first_name} {selectedTrainee?.name}</b> einer Abteilung oder einem Standort zuweisen.</p>
                <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    
                        <IonItem>
                            <Controller
                                name="location_id"
                                control={control}
                                render={({ field }) => <IonSelect
                                    label="Standort"
                                    labelPlacement="floating"
                                    cancelText="Abbrechen"
                                    value={field.value}
                                    onIonChange={(e) => field.onChange(e.detail.value)}
                                >
                                    <IonSelectOption value={null}></IonSelectOption>
                                    {company?.locations.map((location: Location) => <IonSelectOption
                                        key={location.id}
                                        value={location.id}
                                    >
                                        {location.name}
                                    </IonSelectOption>)}
                                </IonSelect>}
                            />
                        </IonItem>

                        <IonItem>
                            <Controller
                                name="department_id"
                                control={control}
                                render={({ field }) => <IonSelect
                                    label="Abteilung"
                                    labelPlacement="floating"
                                    cancelText="Abbrechen"
                                    value={field.value}
                                    onIonChange={(e) => field.onChange(e.detail.value)}
                                >
                                    <IonSelectOption value={null}></IonSelectOption>
                                    {company?.departments.map((department: Department) => <IonSelectOption
                                        key={department.id}
                                        value={department.id}
                                    >
                                        {department.name}
                                    </IonSelectOption>)}
                                </IonSelect>}
                            />
                        </IonItem>
                </form>
            </IonContent>
            <IonFooter>
                <IonToolbar>
                    <IonButtons slot="end">
                        <IonButton role="cancel" onClick={() => setIsOpen(false)}>Abbrechen</IonButton>
                        <IonButton type="submit" fill="solid" color="primary" onClick={handleSubmit(onSubmit)}>Speichern</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonFooter>
        </IonModal >
    )
}

export default EditAzubiModal;