import { IonCol, IonContent, IonGrid, IonIcon, IonImg, IonPage, IonRouterLink, IonRow } from "@ionic/react";
import { RouteComponentProps } from "react-router";

import "../centeredPreAuth.scss";
import { RoundedButton } from "../../../components";
import { arrowForwardOutline } from "ionicons/icons";
import Footer from "../components/Footer";

const PleaseVerifyEmail: React.FC<RouteComponentProps> = () => {
    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <div className="centeredPreAuthContainer centeredPreAuthContainerShort">
                                <div className="imageContainer">
                                    <IonRouterLink href="/login">
                                        <IonImg
                                            src="./assets/images/logo_woodbook_login_dark.png"
                                            alt="Woodbook Logo"
                                        ></IonImg>
                                    </IonRouterLink>
                                </div>
                                <div className="centeredPreAuthContent">
                                <h3 >Vielen Dank für deine Registrierung!</h3>
                                <p>Bitte bestätige deine E-Mailadresse, um unser digitales Ausbildungs-Berichtsheft nutzen zu können.</p>
                                <RoundedButton routerLink="login" style={{ marginTop: 30 }}>zum Login
                                    <IonIcon icon={arrowForwardOutline} />
                                </RoundedButton>
                                </div>
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <Footer />
            </IonContent>
        </IonPage>
    )
}

export default PleaseVerifyEmail;