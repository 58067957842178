import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonIcon } from "@ionic/react"
import { mapColorIonVar, mapIcon } from "../../../util/report-helper"


import "./LatestReports.scss";
import { useEffect } from "react";
import { Report } from "../../../models/reportModels";
import { useHistory } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../data/redux/hooks";
import { getLatestReports } from "../../../data/redux/latestReportsSlice";

type LatestReportEntryProps = {
    report: Report
}

const LatestReportEntry: React.FC<LatestReportEntryProps> = ({ report }: LatestReportEntryProps) => {
    const history = useHistory();
    return (
        <div onClick={() => history.push(`/tabs/berichtsheft/${report.year}/${report.week}`)} className="latestReportEntry" style={{ background: mapColorIonVar(report.state) }}>
            <div>
                <IonIcon icon={mapIcon(report.state)} />
            </div>
            <div>KW<br />{report.week}</div>
        </div>
    )
}


type LatestReportProps = {
    userId: number
}

const LatestReports: React.FC<LatestReportProps> = ({ userId }: LatestReportProps) => {
    const latestReports = useAppSelector((state) => state.latestReports.latestReports);
    const dispatch = useAppDispatch();

    useEffect(() => {
        let ignore = false;

        if (userId && !ignore && latestReports.length === 0) {
            dispatch(getLatestReports({userId: userId}))
        }

        return () => {
            ignore = true;
        }

    }, [userId,  dispatch, latestReports])


    return (
        <IonCard>
            <IonCardHeader>
                <IonCardTitle className="subheadline">
                    Letzte Einträge
                </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <div className="latestReportsContainer">
                    {[...latestReports].reverse().map((report: Report) => {
                        return (
                            <LatestReportEntry report={report} key={report.id} />
                        )
                    })}
                </div>
            </IonCardContent >

        </IonCard >
    )
}

export default LatestReports;