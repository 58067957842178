import { Directory, Filesystem, GetUriResult, WriteFileResult } from "@capacitor/filesystem";
import { FileOpener } from "@capawesome-team/capacitor-file-opener";
import { addWeeks, isBefore, isSameWeek, isSameYear } from "date-fns";

export const downloadPDFInWeb = (res: any) => {
    let fileUrl = 'data:application/octet-stream;base64,' + res;

    let link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', "berichtsheft.pdf");
    document.body.appendChild(link);
    link.click();
};

export const downloadPDFNative = async (res: any) => {
    const currentDate = new Date().toLocaleString().replace(/[,:\s/]/g, '-');
    const filename = `berichtsheft-${currentDate}.pdf`;
    await Filesystem.writeFile({
        path: filename,
        data: res,
        directory: Directory.Documents,
        recursive: true
    }).then((res: WriteFileResult) => {
        Filesystem.getUri({
            directory: Directory.Documents,
            path: filename
        }).then((uriRes: GetUriResult) => {
            const path = uriRes.uri;
            FileOpener.openFile({
                path: path,
                /* mimeType: 'application/pdf' */
            })
        })

    }).catch((err) => console.debug(err))
}

export const getWeeksArray = (start: string, end: string) => {
    let weeks = [];
    const minDate = new Date(start);
    const maxDate = new Date(end);

    for (let i = minDate; isBefore(i, maxDate) || (isSameWeek(i, maxDate) && isSameYear(i, maxDate)); i = addWeeks(i, 1)) {
        weeks.push(i);
    }
    return weeks;
}
