import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Stat } from "../../models/reportModels";
import ReportService from "../services/ReportService";
import { setError } from "./errorSlice";
import { error500Text } from "../../util/error-helper";

interface ReportStatsState {
  reportStats: undefined | Stat,
  loading: boolean,
  error: undefined | string
}

const initialState: ReportStatsState = {
  reportStats: undefined,
  loading: false,
  error: undefined
};

export const getReportStats = createAsyncThunk(
  "reportStats/getReportStats",
  async (_data, { rejectWithValue, dispatch }) => {
    try {
      const res = await ReportService.getReportStats()
      if (res.error) {
        dispatch(setError({
          isOpen: true,
          message: res.error
        }))
        return rejectWithValue(res.error);
      } else if (res.stats) {
        return res.stats;
      } else {
        dispatch(setError({
          isOpen: true,
          message: error500Text
        }))
        return rejectWithValue(error500Text);
      }
    } catch (err) {
      dispatch(setError({
        isOpen: true,
        message: error500Text
      }))
      return rejectWithValue(error500Text);
    }
  }
)

export const reportStatsSlice = createSlice({
  name: "reportStats",
  initialState,
  reducers: {
    setReportStats: (action, payload) => { },
    changeReportStats: (action, payload) => { },
  },
  extraReducers: (builder) => {
    /* getReportStats */
    builder.addCase(getReportStats.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(getReportStats.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.reportStats = payload;
    });
    builder.addCase(getReportStats.rejected, (state, { payload }) => {
      state.loading = false;
      /* @ts-ignore */
      state.error = payload;
    });
  }
});

export const { setReportStats, changeReportStats } = reportStatsSlice.actions;

export default reportStatsSlice.reducer;
