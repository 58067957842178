import { IonCol, IonContent, IonGrid, IonIcon, IonImg, IonLoading, IonPage, IonRouterLink, IonRow, IonToast } from "@ionic/react";
import { RouteComponentProps } from "react-router";
import { RoundedButton } from "../../../components";
import { arrowForwardOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import AuthService from "../../../data/services/AuthService";
import { Toast } from "../../../models/helperModels";

import "../centeredPreAuth.scss";
import Footer from "../components/Footer";
import { error500Text } from "../../../util/error-helper";

interface VerifyEmailProps extends RouteComponentProps<{
    userId: string,
    hash: string
}> { }

const VerifyEmail: React.FC<VerifyEmailProps> = ({ history, match }) => {
    const [loading, setLoading] = useState(false);
    const { userId, hash } = match.params;
    const [toast, setToast] = useState<Toast>({ isOpen: false, message: "", color: "danger" })
    const [verified, setVerified] = useState<boolean>();

    useEffect(() => {
        let ignore = false;
        const verifyEmail = async () => {
            setLoading(true);
            try {
                const res = await AuthService.verifyEmail(userId, hash);
                if (!ignore) {
                    try {
                        if (res.error) {
                            setToast({ isOpen: true, message: res.error, color: "danger" })
                            setVerified(false)
                        } else {
                            setVerified(true)
                        }
                    } catch (err) {
                        setToast({ isOpen: true, message: error500Text, color: "danger" })
                        console.log(err)
                    }

                }

            } catch (err) {
                console.log(err)
            } finally {
                setLoading(false)
            }
        }

        verifyEmail()

        return () => {
            ignore = true;
        }
    }, [userId, hash, setLoading, setVerified])

    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <div className="centeredPreAuthContainer centeredPreAuthContainerShort">
                                <div className="imageContainer">
                                    <IonRouterLink href="/login">
                                        <IonImg
                                            src="./assets/images/logo_woodbook_login_dark.png"
                                            alt="Woodbook Logo"
                                        ></IonImg>
                                    </IonRouterLink>
                                </div>
                                <div className="centeredPreAuthContent ">
                                    {verified && !loading && <>
                                        <h3>Willkommen bei Woodbook! Deinem digitalen Ausbildungs-Berichtsheft.</h3>
                                        <p>Du hast deine E-Mailadresse erfolgreich bestätigt und kannst dich jetzt mit deinen Zugangsdaten einloggen.</p>
                                        <RoundedButton style={{ marginTop: 40 }} onClick={(e) => {
                                            e.preventDefault();
                                            history.push('/login');
                                        }}>zum Login <IonIcon icon={arrowForwardOutline} /></RoundedButton>
                                    </>}
                                    {loading && verified === undefined && <p>E-Mail wird verifziert...</p>}
                                    {!loading && verified === false &&
                                        <>
                                            <p>Hoppla, hier ist etwas schief gelaufen. Es gab einen Fehler beim Verifizieren deiner E-Mail.</p>
                                            <RoundedButton routerLink="/login"><IonIcon />zurück zum Login</RoundedButton>
                                        </>
                                    }
                                </div>
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>

                <Footer />

                <IonLoading
                    isOpen={loading}
                    message="Lädt..."
                />
                <IonToast
                    duration={5000}
                    message={toast.message}
                    isOpen={toast.isOpen}
                    color={toast.color}
                    onDidDismiss={() => setToast({ isOpen: false, message: "", color: "danger" })}
                />
            </IonContent>
        </IonPage>
    )
}

export default VerifyEmail;