import { IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonMenu, IonMenuToggle, IonTitle, IonToolbar } from "@ionic/react";
import RoundedButton from "../roundedButton/RoundedButton";
import { logOutOutline } from "ionicons/icons";
import { routes } from "./routes";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../data/redux/hooks";
import { logout } from "../../data/redux/userSlice";

const SideMenu = () => {
    let path = useLocation().pathname;
    const dispatch = useAppDispatch();
    const { user } = useAppSelector((state) => state.user);

    return (
        <IonMenu contentId="main-content" side="end" maxEdgeStart={0}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Woodbook</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent >
                <div style={{ padding: 10 }}>
                    <IonList lines="none">
                        {user && routes.map(route => {
                            if (route.sideMenuMobile && route.roles.includes(user.role) && !route.legal) {
                                return (
                                    <IonMenuToggle autoHide={true} key={route.path}>
                                        <IonItem routerLink={route.path} detail={true}>
                                            <IonLabel className="sideMenuLabel" color={path.startsWith(route.path) ? "primary" : undefined}>{route.label}</IonLabel>
                                        </IonItem>
                                    </IonMenuToggle>
                                )
                            } return null;
                        })}
                    </IonList>
                    <div className='menuSpacer' style={{ margin: 0 }}></div>
                    <IonList lines="none">
                        {user && routes.map(route => {
                            if (route.sideMenuMobile && route.roles.includes(user.role) && route.legal) {
                                return (
                                    <IonMenuToggle autoHide={true} key={route.path}>
                                        <IonItem routerLink={route.path} detail={true}>
                                            <IonLabel className="legalSideMenuLabel" color={path.startsWith(route.path) ? "primary" : undefined}>{route.label}</IonLabel>
                                        </IonItem>
                                    </IonMenuToggle>
                                )
                            } return null;
                        })}
                    </IonList>
                    <div className='menuSpacer'></div>
                    <IonMenuToggle>
                        <RoundedButton type="button" onClick={() => dispatch(logout())}>
                            abmelden
                            <IonIcon icon={logOutOutline} />
                        </RoundedButton>
                    </IonMenuToggle>
                </div>

            </IonContent>
        </IonMenu>
    )
}

export default SideMenu;