import { IonImg, IonRouterLink } from "@ionic/react"

const Footer = () => {
    return (
        <div className="loginFooter">
            Ein Projekt von
            <IonRouterLink href="https://holztalente.de/" target="_blank" rel="noreferrer">
                <IonImg className="footerImage" src="./assets/images/logo_holztalente.png" alt="Holztalente Logo" />
            </IonRouterLink>
            <div className="officialLinks">
                <IonRouterLink color="dark" routerLink="/impressum">Impressum</IonRouterLink>
                <IonRouterLink color="dark" routerLink="/datenschutz">Datenschutz</IonRouterLink>
                <IonRouterLink color="dark" routerLink="/haftungsausschluss">Disclaimer</IonRouterLink>
            </div>
        </div>
    )
}

export default Footer;