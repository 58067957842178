import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { User } from "../../models/userModels";
import AuthService from "../services/AuthService";
import { Preferences } from "@capacitor/preferences";
import UserService from "../services/UserService";
import { setError } from "./errorSlice";
import { error500Text } from "../../util/error-helper";

interface UserState {
    user: undefined | User,
    token: undefined | string,
    loading: boolean,
    error: undefined | string,
    success: boolean,
    updating: boolean
}

const initialState: UserState = {
    user: undefined,
    token: undefined,
    /* loading: true, */
    loading: false,
    error: undefined,
    success: false,
    updating: false
}

export const login = createAsyncThunk(
    "user/login",
    async (dataToSend: any, { rejectWithValue }) => {
        /* @ts-ignore */
        const { data, history } = dataToSend;
        try {
            const res = await AuthService.login(data);
            if (res.token) {
                const token = res.token
                await Preferences.set({
                    key: 'token',
                    value: token,
                });
                try {
                    const res = await UserService.getCurrentUser()
                    if (res.status && res.status === 'Token is Expired') {
                        console.log("Logout needed")
                        logout()
                        return rejectWithValue("Sitzung abgelaufen")
                    } else if (res.error) {
                        return rejectWithValue(res.error)
                    } else {
                        const user = res.user
                        history.push("/tabs/dashboard")
                        return { token, user }
                    }
                } catch (err) {
                    return rejectWithValue(err);
                }
            } else if (res.error) {
                return rejectWithValue(res.error);
            } else {
                return rejectWithValue(error500Text);
            }


        } catch (err) {
            return rejectWithValue(err);
        }
    }
)

export const logout = createAsyncThunk(
    "user/logout",
    async (_data, { rejectWithValue }) => {
        try {
            await Preferences.remove({
                key: 'token',
            });
            //dispatch(reset())
            return "success"
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

export const getUser = createAsyncThunk(
    "user/getUser",
    async (_data, { rejectWithValue }) => {

        try {
            const preferences = await Preferences.get({ key: 'token' })
            const token = preferences.value
            if (token) {
                const res = await UserService.getCurrentUser()
                if (res.status && res.status === 'Token is Expired') {
                    console.log("Sitzung abgelaufen")
                    await Preferences.remove({
                        key: 'token',
                    });
                    return rejectWithValue("Sitzung ist abgelaufen")
                } else if (res.error) {
                    return rejectWithValue(res.error)
                } else if (res.user) {
                    const user = res.user
                    return { token, user }
                } else {
                    await Preferences.remove({
                        key: 'token',
                    });
                    return rejectWithValue(error500Text)
                }
            } else {
                return rejectWithValue(undefined)
            }

        } catch (err) {
            console.log(err)
            return rejectWithValue(err)
        }
    }, {
    condition: (_data, { getState }) => {
        // @ts-ignore 
        const { user } = getState()
        if (user.user || user.loading === true) {
            // Already fetched or in progress, don't need to re-fetch
            return false
        }
    },
}
)

export const editUser = createAsyncThunk(
    "user/editUser",
    async (data: any, { rejectWithValue, dispatch }) => {
        try {
            delete data.image;
            const res = await UserService.editUser(data)
            if (res.error) {
                dispatch(setError({
                    isOpen: true,
                    message: res.error
                }))
                return rejectWithValue(res.error)
            } else if (res.user) {
                return res.user;
            } else {
                dispatch(setError({
                    isOpen: true,
                    message: error500Text
                }))
                return rejectWithValue(error500Text)
            }
        } catch (err) {
            dispatch(setError({
                isOpen: true,
                message: error500Text
            }))
            return rejectWithValue(err)
        }
    }
)

export const changeProfileImage = createAsyncThunk(
    "user/changeProfileImage",
    async (data: any, { rejectWithValue, dispatch }) => {
        try {
            const res = await UserService.changeProfileImage(data);
            if (res.error) {
                dispatch(setError({
                    isOpen: true,
                    message: res.error
                }))
                return rejectWithValue(res.error)
            } else if (res.user) {
                return res.user
            } else {
                dispatch(setError({
                    isOpen: true,
                    message: error500Text
                }))
                return rejectWithValue(error500Text)
            }
        } catch (err) {
            dispatch(setError({
                isOpen: true,
                message: error500Text
            }))
            return rejectWithValue(err)
        }
    }
)

export const connectTraineeWithCompany = createAsyncThunk(
    "user/connectTraineeWithCompany",
    async (data: any, { rejectWithValue, dispatch }) => {
        try {
            const res = await UserService.connectTraineeToCompany(data)
            if (res.error) {
                dispatch(setError({
                    isOpen: true,
                    message: res.error
                }))
                return rejectWithValue(res.error)
            } if (res.user) {
                return res.user;
            } else {
                dispatch(setError({
                    isOpen: true,
                    message: error500Text
                }))
                return rejectWithValue(error500Text)
            }

        } catch (err) {
            dispatch(setError({
                isOpen: true,
                message: error500Text
            }))
            return rejectWithValue(err)
        }
    }
)

export const changePassword = createAsyncThunk(
    "user/changePassword",
    async (data: any, { rejectWithValue, dispatch }) => {
        try {
            const res = await UserService.changePassword(data)
            //console.log(res)
            if (res.error) {
                dispatch(setError({
                    isOpen: true,
                    message: res.error
                }))
                return rejectWithValue(res.error)
            } else if (res.user) {
                return "success"
            } else {
                dispatch(setError({
                    isOpen: true,
                    message: error500Text
                }))
                return rejectWithValue(error500Text)
            }
        } catch (err) {
            dispatch(setError({
                isOpen: true,
                message: error500Text
            }))
            return rejectWithValue(err)
        }
    }
)

export const deleteUser = createAsyncThunk(
    "user/deleteUser",
    async (data: any, { rejectWithValue, dispatch }) => {
        try {
            const res = await UserService.deleteUser(data)
            //console.log(res)
            if (res.error) {
                dispatch(setError({
                    isOpen: true,
                    message: res.error
                }))
                return rejectWithValue(res.error)
            } else if (res.success) {
                dispatch(logout())
                return "success"
            } else {
                dispatch(setError({
                    isOpen: true,
                    message: error500Text
                }))
                return rejectWithValue(error500Text)
            }
        } catch (err) {
            dispatch(setError({
                isOpen: true,
                message: error500Text
            }))
            return rejectWithValue(err)
        }
    }
)

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setCompanyName: (state, { payload }) => {
            if (state.user) {
                state.user.company_name = payload
            }
        }
    },
    extraReducers: (builder) => {
        /* Login */
        builder.addCase(login.pending, (state) => {
            state.loading = true;
            state.error = undefined
        })
        builder.addCase(login.fulfilled, (state, { payload }) => {
            /* @ts-ignore */
            state.token = payload.token;
            /* @ts-ignore */
            state.user = payload.user;
            state.loading = false;
        })
        builder.addCase(login.rejected, (state, { payload }) => {
            state.loading = false;
            /* @ts-ignore */
            state.error = payload;
        })

        /* get User */
        builder.addCase(getUser.pending, (state) => {
            state.loading = true;
            state.error = undefined;
        })
        builder.addCase(getUser.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.token = payload.token;
            state.user = payload.user
            state.success = true;
        })
        builder.addCase(getUser.rejected, (state, { payload }) => {
            state.loading = false;
            /* @ts-ignore */
            state.error = payload;
            state.success = false;
        })

        /* logout */
        builder.addCase(logout.pending, (state) => {
            state.loading = true;
            state.error = undefined
        })
        builder.addCase(logout.fulfilled, (state) => {
            state.token = undefined;
            state.loading = false;
            state.user = undefined;
            state.success = false;
        })
        builder.addCase(logout.rejected, (state, { payload }) => {
            /* @ts-ignore */
            state.error = payload;
            state.loading = false;
        })

        /* edit User */
        builder.addCase(editUser.pending, (state) => {
            state.updating = true;
            state.error = undefined
        })
        builder.addCase(editUser.fulfilled, (state, { payload }) => {
            state.updating = false;
            /* @ts-ignore */
            state.user = payload
        })
        builder.addCase(editUser.rejected, (state, { payload }) => {
            state.updating = false;
            /* @ts-ignore */
            state.error = payload;
        })

        /* change profile image */
        builder.addCase(changeProfileImage.pending, (state) => {
            state.updating = true;
            state.error = undefined
        })
        builder.addCase(changeProfileImage.fulfilled, (state, { payload }) => {
            state.updating = false;
            /* @ts-ignore */
            state.user.image = payload.image
        })
        builder.addCase(changeProfileImage.rejected, (state, { payload }) => {
            state.updating = false;
            /* @ts-ignore */
            state.error = payload;
        })

        /* connectTraineeWithCompany */
        builder.addCase(connectTraineeWithCompany.pending, (state) => {
            state.updating = true;
            state.error = undefined
        })
        builder.addCase(connectTraineeWithCompany.fulfilled, (state, { payload }) => {
            state.updating = false;
            state.user = payload
        })
        builder.addCase(connectTraineeWithCompany.rejected, (state, { payload }) => {
            state.updating = false;
            /* @ts-ignore */
            state.error = payload;
        })

        /* change Password */
        builder.addCase(changePassword.pending, (state) => {
            state.updating = true;
            state.error = undefined
        })
        builder.addCase(changePassword.fulfilled, (state) => {
            state.updating = false;
        })
        builder.addCase(changePassword.rejected, (state, { payload }) => {
            state.updating = false;
            /* @ts-ignore */
            state.error = payload;
        })

        /* delete User */
        builder.addCase(deleteUser.pending, (state) => {
            state.updating = true;
            state.error = undefined
        })
        builder.addCase(deleteUser.fulfilled, (state) => {
            state.updating = false;
        })
        builder.addCase(deleteUser.rejected, (state, { payload }) => {
            state.updating = false;
            /* @ts-ignore */
            state.error = payload;
        })
    }
});

export const {
    setCompanyName,
} = userSlice.actions;

export default userSlice.reducer;