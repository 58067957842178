import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonIcon, IonLoading, IonPage, IonRouterLink, IonRow, IonSkeletonText } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import { DoughnutChart, DoughnutChartLegend, Greeting, LatestReports, Notificaitons, OpenAzubiEntries, PageContainer } from '../../components';

import "./Dashboard.scss";
import useWindowDimensions from '../../data/customHooks/useWindowDimensions';
import { useAppDispatch, useAppSelector } from '../../data/redux/hooks';
import { useEffect, useState } from 'react';
import { getReportStats } from '../../data/redux/reportStatsSlice';
import { calendarOutline, chevronForwardOutline } from 'ionicons/icons';
import { NewsArticle } from '../../models/newsModel';
import NewsService from '../../data/services/NewsService';
import { format } from 'date-fns';
import { setError } from '../../data/redux/errorSlice';
import { error500Text } from '../../util/error-helper';


const Dashboard: React.FC<RouteComponentProps> = () => {
  const { user } = useAppSelector((state) => state.user)
  const { width } = useWindowDimensions();
  const { loading } = useAppSelector((state) => state.traineeList)
  const { reportStats } = useAppSelector((state) => state.reportStats)
  const dispatch = useAppDispatch();
  const [news, setNews] = useState<NewsArticle[]>()

  useEffect(() => {
    if (reportStats === undefined) {
      dispatch(getReportStats());
    }
  }, [dispatch, reportStats])

  useEffect(() => {
    let ignore = false;
    const fetchNews = async () => {
      const res = await NewsService.getNews(1, 3);
      if (!ignore) {
        if (res.result) {
          setNews(res.result.data)
        } else if (res.error) {
          dispatch(setError({
            isOpen: true,
            message: res.error
          }))
        } else {
          dispatch(setError({
            isOpen: true,
            message: error500Text
          }))
        }

      }
    }
    fetchNews();

    return () => {
      ignore = true;
    }
  }, [dispatch])


  const returnSkeletons = () => {
    const skeletons = [];
    for (let i = 0; i < 3; i++) {
      skeletons.push(
        <IonCard className="newsTeaser" key={i}>
          <IonSkeletonText animated={true} className="newsTeaserImage" style={{ marginTop: 0 }} />

          <div style={{ marginTop: 10 }}>
            <IonSkeletonText animated={true}></IonSkeletonText>
            <IonSkeletonText animated={true}></IonSkeletonText>
            <IonSkeletonText animated={true} style={{ width: "30%" }}></IonSkeletonText>
          </div>
        </IonCard>
      );
    }
    return skeletons;
  }


  return (
    <IonPage>

      <PageContainer name='Dashboard' backButton={false}>
        <>
          <IonGrid>
            <IonRow>
              <IonCol>
                <Greeting />
              </IonCol>
            </IonRow>




            <IonRow>
              <IonCol className='ion-hide-lg-down'>
                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle className='headline'>Dashboard</IonCardTitle>
                  </IonCardHeader>
                </IonCard>
              </IonCol>
              {user && <IonCol size='12'>
                {user.role !== null ?
                  <IonCard>
                    <IonCardHeader>
                      <IonCardTitle className="subheadline">Gesamt</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>

                      <IonGrid>
                        {reportStats && user && <IonRow>
                          <IonCol sizeXs='12' sizeMd='6' sizeLg='6'>
                            <DoughnutChart stats={reportStats} user={user} />
                          </IonCol>
                          <IonCol>
                            <DoughnutChartLegend stats={reportStats} user={user} />
                          </IonCol>
                        </IonRow>}

                      </IonGrid>

                    </IonCardContent>
                  </IonCard>
                  :
                  <IonCard>
                    <IonCardContent>
                      {(user.role === "trainer" || user.role === "company_admin") ? "Ihnen sind derzeit keine Azubis zugeordnet" : "Bitte füge in den Einstellungen ein Startdatum für deine Ausbildung ein"}
                    </IonCardContent>
                  </IonCard>}
              </IonCol>}

              {width < 992 && <IonCol size='12'>
                <Notificaitons />
              </IonCol>}

              {user && <IonCol>

                {user.role === "trainee" &&
                  <LatestReports userId={user.id} />
                }

                {(user.role === "trainer" || user.role === "company_admin") && <OpenAzubiEntries />}
              </IonCol>
              }
            </IonRow>


            {/* Latest News */}
            <IonRow>
              <IonCol>
                <IonCard>
                  <IonCardHeader>
                    <div className='newsTeaserHeadlineContainer'>
                      <div>
                        <IonCardTitle className="subheadline ">Aktuelle News</IonCardTitle>
                      </div>
                      <div className="newsTeaserShowAll">
                        <IonRouterLink routerLink="/tabs/news">
                          <div style={{ display: "flex", alignItems: "center" }}>
                            alle anzeigen <IonIcon icon={chevronForwardOutline} />
                          </div>
                        </IonRouterLink>
                      </div>
                    </div>
                  </IonCardHeader>
                  <IonCardContent>

                    <div className='newsTeaserContainer'>

                      {news && news.length > 0 ? news?.map((newsEntry, index) => {
                        return (
                          <IonCard key={newsEntry.id} className={`newsTeaser newsTeaser-${index}`} routerLink={`/tabs/news/${newsEntry.id}`}>
                            <div>
                              <div>
                                <img className="newsTeaserImage" src={newsEntry.image!} alt="Vorschau Neswartikel"></img>
                              </div>
                              <div>
                                <div className='newsTeaserHeadline'>
                                  {newsEntry.headline}
                                </div>
                                <div style={{ display: 'flex', alignItems: "center", gap: 4 }}><IonIcon icon={calendarOutline} /> <span>{format(new Date(newsEntry.datum), "dd.MM")}</span></div>
                              </div>
                            </div>
                          </IonCard>
                        )
                      }) : returnSkeletons()}
                    </div>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>


            {user && <IonRow>
              <IonCol>
                <IonCard color="primary">
                  <IonCardContent>
                    {user.role === "trainee" ? <p>
                      Solltest Du Probleme mit der Anwendung oder Verbesserungsvorschläge haben, schreibe uns
                      bitte eine Nachricht an <IonRouterLink style={{ color: "white" }} href="mailto:feedback@woodbook.info">feedback@woodbook.info</IonRouterLink>.
                    </p> : <p>
                      Sollten Sie Probleme mit der Anwendung oder Verbesserungsvorschläge haben, schreiben Sie uns
                      bitte eine Nachricht an <IonRouterLink style={{ color: "white" }} href="mailto:feedback@woodbook.info">feedback@woodbook.info</IonRouterLink>.
                    </p>}
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>}

          </IonGrid>
          {(user && user.role !== "trainee") && <IonLoading
            message="Lädt..."
            isOpen={loading}
          />}

        </>
      </PageContainer>

    </IonPage >
  );
};

export default Dashboard;