import { CapacitorHttp, HttpOptions, HttpResponse } from "@capacitor/core";
import baseHttpOptions from "../../util/http-common";

const createLocation = async (reqData: any) => {
  const options: HttpOptions = await baseHttpOptions("location/create");

  options.data = reqData;

  const response: HttpResponse = await CapacitorHttp.post(options);
  const { data } = response;
  return data;
};

const editLocation = async (reqData: any) => {
  const options: HttpOptions = await baseHttpOptions("location/edit");

  options.data = reqData;

  const response: HttpResponse = await CapacitorHttp.post(options);
  const { data } = response;
  return data;
};

const deleteLocation = async (reqData: any) => {
  const options: HttpOptions = await baseHttpOptions("location/delete");

  options.data = reqData;

  const response: HttpResponse = await CapacitorHttp.post(options);
  const { data } = response;
  return data;
};

const LocationService = {
  createLocation,
  editLocation,
  deleteLocation,
};

export default LocationService;
