import { createSlice } from "@reduxjs/toolkit";
import { ReportDay, ReportWeek } from "../../models/reportModels";

const initialState = {
  reportWeek: {
    created_at: "",
    days: [] as ReportDay[],
    id: null,
    lastMessage: [],
    state: "",
    updated_at: "",
    user_id: null,
    week: null,
    year: null,
  } as ReportWeek,
};

export const reportWeekSlice = createSlice({
  name: "reportWeek",
  initialState,
  reducers: {
    setReportWeek: (state, action) => {
      state.reportWeek = action.payload;
    },
    updateReportWeekState: (state, action) => {
      state.reportWeek.state = action.payload.state;
      state.reportWeek.updated_at = action.payload.updated_at;
    },
    addReportWeekEntry: (state, action) => {
      let index = state.reportWeek.days.findIndex(
        (day) => day.id === action.payload.report_day_id
      );
      if (index !== -1) {
        state.reportWeek.days[index].entries.push(action.payload);
      }
    },
    editReportWeekEntry: (state, action) => {
      let index = state.reportWeek.days.findIndex(
        (day) => day.id === action.payload.report_day_id
      );
      if (index !== -1) {
        let entryIndex = state.reportWeek.days[index].entries.findIndex(
          (entry) => entry.id === action.payload.id
        );
        if (entryIndex !== -1) {
          state.reportWeek.days[index].entries[entryIndex] = action.payload;
        }
      }
    },
    deleteReportWeekEntry: (state, action) => {
      let index = state.reportWeek.days.findIndex(
        (day) => day.id === action.payload.report_day_id
      );
      if (index !== -1) {
        let newEntries = state.reportWeek.days[index].entries.filter(
          (entry) => entry.id !== action.payload.id
        );
        //console.log(newEntries);
        state.reportWeek.days[index].entries = newEntries;
      }
    },
  },
});

export const {
  setReportWeek,
  updateReportWeekState,
  addReportWeekEntry,
  editReportWeekEntry,
  deleteReportWeekEntry,
} = reportWeekSlice.actions;

export default reportWeekSlice.reducer;
