import { IonButton, IonCard, IonCardContent, IonCol, IonDatetime, IonDatetimeButton, IonGrid, IonInput, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPage, IonRow } from "@ionic/react";
import { RouteComponentProps } from "react-router";
import { BackButtonRow, PageContainer } from "../../../components";
import { useAppDispatch, useAppSelector } from "../../../data/redux/hooks";
import { TrainingFormData } from "../../../models/userModels";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { format, formatISO } from "date-fns";
import { copyRequiredUserData } from "../../../util/profile-helper";
import { editUser } from "../../../data/redux/userSlice";
import ReportSettingsForm from "./components/ReportSettingsForm";
import { getReportStats } from "../../../data/redux/reportStatsSlice";
import { getLatestReports } from "../../../data/redux/latestReportsSlice";
import ReportService from "../../../data/services/ReportService";
import { setReportList } from "../../../data/redux/reportListSlice";

interface TrainingSettingsProps extends RouteComponentProps { }

const TrainingSettings: React.FC<TrainingSettingsProps> = () => {
    const { user, updating } = useAppSelector((state) => state.user)
    const { control, handleSubmit, formState: { errors } } = useForm<TrainingFormData>({
        mode: "onTouched",
        reValidateMode: "onChange",
        defaultValues: {
            job: (user && user.job) ? user.job : "",
            trainee_start: (user && user.trainee_start) ? user.trainee_start : "",
            trainee_end: (user && user.trainee_end) ? user.trainee_end : undefined,
            trainee_year: (user && user.trainee_year) ? user.trainee_year : 1,
        }
    });
    const dispatch = useAppDispatch();

    const fetchReports = async (userId: number) => {
        const res = await ReportService.getReports(userId, 1, 25);
        if (res.report) {
            dispatch(setReportList(res.report.data))
        }
    }

    const onSubmit: SubmitHandler<TrainingFormData> = (data: TrainingFormData) => {
        let userClone = copyRequiredUserData(user!);
        userClone.job = data.job;
        userClone.trainee_year = data.trainee_year;

        if (data.trainee_start !== undefined) {
            userClone.trainee_start = format(new Date(data.trainee_start), "yyyy-MM-dd")
        }
        if (data.trainee_end !== undefined) {
            userClone.trainee_end = format(new Date(data.trainee_end), "yyyy-MM-dd")
        }


        dispatch(editUser(userClone)).then(async () => {
            dispatch(getReportStats())
            dispatch(getLatestReports({ userId: userClone.id }))
            fetchReports(userClone.id)
        })
    }

    const maxYear = () => {
        const date = new Date();
        date.setFullYear(date.getFullYear() + 20);
        return String(formatISO(date));
    }

    return (
        <IonPage>
            <PageContainer name="Einstellungen" backButton={true}>
                <>
                    <IonGrid>
                        <BackButtonRow path="/tabs/profil" />
                        <IonRow>
                            <IonCol>
                                <IonCard>
                                    <IonCardContent>
                                        <IonLabel>
                                            <h3><b>Ausbildungsinfos bearbeiten</b></h3>
                                            <p>Hier kannst du die Infos zu deiner Ausbildung bearbeiten</p>
                                        </IonLabel>

                                        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                                            <IonList>
                                                <IonItem className="registerInput">
                                                    <Controller
                                                        name="job"
                                                        control={control}
                                                        render={({ field }) => <IonInput
                                                            label="Beruf"
                                                            labelPlacement="floating"
                                                            type="text"
                                                            value={field.value}
                                                            aria-invalid={!!errors.job}
                                                            onIonChange={(e: any) => field.onChange(e.detail.value)}
                                                            onIonBlur={() => field.onBlur()}
                                                        />}
                                                    />
                                                </IonItem>
                                                <div className="errorText">{errors.job?.message}</div>

                                                <IonItem className="registerInput">
                                                    <IonLabel>Ausbildungsstart*</IonLabel>
                                                    <Controller
                                                        name="trainee_start"
                                                        control={control}
                                                        rules={{
                                                            required: { value: true, message: "Bitte gebe den Beginn deiner Ausbildung an." }

                                                        }}
                                                        /* @ts-ignore */
                                                        render={({ field }) => <>
                                                            <IonDatetimeButton datetime="trainee_start"></IonDatetimeButton>

                                                            <IonModal keepContentsMounted={true}>
                                                                <IonDatetime
                                                                    presentation="date"
                                                                    id="trainee_start"
                                                                    firstDayOfWeek={1}
                                                                    aria-invalid={!!errors.trainee_start}
                                                                    onIonChange={(e: any) => field.onChange(e.detail.value)}
                                                                    showDefaultButtons={true}
                                                                    doneText="OK"
                                                                    cancelText="Abbrechen"
                                                                    value={field.value}
                                                                ></IonDatetime>
                                                            </IonModal>
                                                        </>}
                                                    />
                                                </IonItem>
                                                <div className="errorText">{errors.trainee_start?.message}</div>

                                                <IonItem className="registerInput">
                                                    <Controller
                                                        name="trainee_year"
                                                        control={control}
                                                        rules={{
                                                            required: { value: true, message: "Bitte gib an, in welchem Lehrjahr du bist." }
                                                        }}
                                                        /* @ts-ignore */
                                                        render={({ field }) => <IonInput
                                                            label="Ausbildungsjahr"
                                                            labelPlacement="floating"
                                                            type="number"
                                                            aria-invalid={!!errors.trainee_year}
                                                            onIonChange={(e: any) => field.onChange(e.detail.value)}
                                                            onIonBlur={() => field.onBlur()}
                                                            value={field.value}
                                                        />}
                                                    />
                                                </IonItem>
                                                <div className="errorText">{errors.trainee_year?.message}</div>


                                                <IonItem className="registerInput">
                                                    <IonLabel>Ausbildungsende</IonLabel>
                                                    <Controller
                                                        name="trainee_end"
                                                        control={control}
                                                        /* @ts-ignore */
                                                        render={({ field }) => <>
                                                            <IonDatetimeButton datetime="trainee_end"></IonDatetimeButton>

                                                            <IonModal keepContentsMounted={true}>
                                                                <IonDatetime
                                                                    presentation="date"
                                                                    id="trainee_end"
                                                                    firstDayOfWeek={1}
                                                                    aria-invalid={!!errors.trainee_end}
                                                                    onIonChange={(e: any) => field.onChange(e.detail.value)}
                                                                    showDefaultButtons={true}
                                                                    doneText="OK"
                                                                    cancelText="Abbrechen"
                                                                    value={field.value}
                                                                    max={maxYear()}
                                                                ></IonDatetime>
                                                            </IonModal>
                                                        </>}
                                                    />
                                                </IonItem>
                                                <div className="errorText">{errors.trainee_end?.message}</div>

                                            </IonList>


                                            <div style={{ textAlign: "right" }}>
                                                <IonButton type="submit">Speichern</IonButton>
                                            </div>
                                        </form>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                            <IonCol size="12">
                                <ReportSettingsForm user={user} />
                            </IonCol>
                        </IonRow>
                    </IonGrid>

                    <IonLoading
                        isOpen={updating}
                        message="Lädt..."
                    />
                </>
            </PageContainer>
        </IonPage>
    )
}

export default TrainingSettings;