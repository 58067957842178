import { IonIcon, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from "@ionic/react";
import ProtectedRoute from "../protectedRoute/ProtectedRoute";
import { Route } from "react-router";
import { routes } from "./routes";
import { useAppSelector } from "../../data/redux/hooks";
import { User } from "../../models/userModels";
import { NotFoundPageWithAuth } from "../../pages/404/404";

const TabMenu = () => {
    const { user } = useAppSelector((state) => state.user)

    const tabsShouldHide = (userVar: User | undefined) => {
        if (userVar === undefined) {
            return true
        } else {
            if (userVar.role === "trainee" && userVar.trainee_start === null) {
                return true
            }
            return false
        }
    }

    return (
        <IonTabs>
            {/* @ts-ignore */}
            <IonRouterOutlet id="main-content" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                {routes.map((route) => {
                    if (route.protected) {
                        const Component = route.component;
                        return (<Route key={route.path} exact path={route.path} render={(props) => {
                            return (<ProtectedRoute {...props}>
                                <Component {...props} />
                            </ProtectedRoute>)
                        }} />)
                    }
                    return null;
                })}
                {/* <Route component={NotFoundPageWithAuth}/>  */}
                <Route render={(props) => {
                    return (<ProtectedRoute {...props}>
                        <NotFoundPageWithAuth {...props} />
                    </ProtectedRoute>)
                }} />
            </IonRouterOutlet>
            <IonTabBar slot="bottom" id="main-content" /* className="ion-hide-lg-up" */ className={tabsShouldHide(user) ? "ion-hide" : "ion-hide-lg-up"}>

                {user && routes.map((route) => {
                    if (route.istab && route.roles.includes(user.role)) {
                        return (
                            <IonTabButton key={route.path} tab={route.label} href={route.path} aria-label={route.label}>
                                <IonIcon icon={route.icon} />
                            </IonTabButton>
                        )
                    }
                    return null;
                })}

            </IonTabBar>
        </IonTabs>
    )
}

export default TabMenu;