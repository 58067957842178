import { IonImg } from "@ionic/react";

const Logo = () => {
    return (
        <div className="logoContainer">
            <IonImg className="transparentLoginLogo" src="./assets/images/logo_woodbook_login_dark.png" alt="Woodbook Logo" />
        </div>
    )
}

export default Logo;