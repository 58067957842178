import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonInput, IonItem, IonList, IonLoading, IonPage, IonRouterLink, IonRow, IonToast } from "@ionic/react"
import { RouteComponentProps } from "react-router";

import "./CompanyRegistration.scss"
import Footer from "../components/Footer";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { handleEmailValidation } from "../../../util/helper";
import CompanyService from "../../../data/services/CompanyService";
import { useState } from "react";
import { Toast } from "../../../models/helperModels";
import { closeOutline } from "ionicons/icons";
import { error500Text } from "../../../util/error-helper";

const InfoForCompany = () => {
    return (
        <>
            <p>Auf dieser Webseite können Sie Ihr Unternehmen für die DeSH-Anwendung WOODbook - Berichtsheft online registrieren.</p>

            <p>
                Nach Prüfung Ihrer Registrierung erhalten Sie Zugangsdaten für Ihre Personalverwaltung. 
                Dort können Sie Ausbilderinnen und Ausbilder, Standorte und Abteilungen anlegen. Ebenso 
                finden Sie hier die wichtigste Funktion: Die Einladung für Azubis.
            </p>
            <p>
                Sobald sich Ihr(e) Auszubildende(r) angemeldet hat/haben, kann er/sie sofort mit den Berichtsheften 
                loslegen. Weisen Sie Ihrem Azubi eine Ausbilderin oder einen Ausbilder, eine Abteilung und einen 
                Standort zu und führen Sie ab sofort alle Berichtshefte online.
            </p>

            <p>Diese Version von WOODbook ist die Version 1.0 - weitere Updates wie z.B.:</p>
            <ul>
                <li>komplette Übersicht für Personalverantwortliche über alle Auszubildenden</li>
                <li>weitere Individualisierungs- und Änderungsmöglichkeiten über die Funktion "Profil"</li>
            </ul>
            <p>sind in Arbeit bzw. geplant.</p>

            <p>Sollten Sie Wünsche und Anregungen haben, senden Sie diese bitte an <IonRouterLink href="mailto:feedback@woodbook.info">feedback@woodbook.info</IonRouterLink></p>
            <p>Wir sind sehr daran interessiert, das WOODbook im Sinne unserer Mitglieder weiterzuentwickeln.</p>
            <p>Die Nutzung für Unternehmen ist in der Version 1.0 des WOODbooks auf die Mitgliedsbetriebe des Deutsche Säge- und Holzindustrie Bundesverband e. V. beschränkt. </p>
            <p>Das Projekt wird gefördert durch die Rudolf-Bleichert-Stiftung.</p>
            <p>Wir freuen uns sehr auf Ihre Teilnahme.</p>
        </>
    )
}

interface CompanyRegistrationFormData {
    first_name: string | undefined,
    name: string,
    email: string,
    company_name: string
}

const CompanyRegistration: React.FC<RouteComponentProps> = () => {
    const { control, handleSubmit, formState: { errors } } = useForm<CompanyRegistrationFormData>({
        mode: "onTouched",
        reValidateMode: "onChange",
        defaultValues: {
            first_name: "",
            name: "",
            email: "",
            company_name: ""
        },
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [toast, setToast] = useState<Toast>({ message: "", color: "danger", isOpen: false });
    const [successToast, setSuccessToast] = useState<Toast>({ message: "", color: "success", isOpen: false });

    const createCompany = async (data: CompanyRegistrationFormData) => {
        setLoading(true)
        try {
            const res = await CompanyService.createCompany(data);
            console.log(res)
            if (res.error) {
                setToast({ message: res.error, color: "danger", isOpen: true })
            } else if (res.success) {
                //success
                setSuccessToast({ message: res.success, color: "success", isOpen: true })
            } else {
                setToast({ message: error500Text, color: "danger", isOpen: true })
            }
        } catch (err) {
            setToast({ message: error500Text, color: "danger", isOpen: true })
        }
        finally {
            setLoading(false)
        }
    }

    const onSubmit: SubmitHandler<CompanyRegistrationFormData> = (data: CompanyRegistrationFormData) => {
        //console.log(data);
        createCompany(data)
    };


    return (
        <IonPage>
            <IonContent>
                <div className="companyRegistration">
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <div style={{ textAlign: "center" }}>
                                    <img src="./assets/images/logo_woodbook_login_dark.png" alt="Woodbook Logo" />
                                </div>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonButton routerLink="/login" fill="clear">zum Login</IonButton>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol sizeXs="12" sizeSm="12" sizeMd="6">
                                <IonCard>
                                    <IonCardContent>
                                        <div className="formattedText">
                                            <InfoForCompany />
                                        </div>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                            <IonCol>
                                <IonCard>
                                    <IonCardHeader>
                                        <IonCardTitle>Anfrage für WOODBook Zugang</IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

                                            <IonList>
                                                <IonItem className="centeredPreAuthInput">
                                                    <Controller
                                                        name="first_name"
                                                        control={control}

                                                        render={({ field }) => <IonInput
                                                            label="Vorname"
                                                            labelPlacement="floating"
                                                            type="text"
                                                            aria-invalid={!!errors.first_name}
                                                            /* @ts-ignore */
                                                            onIonChange={(e) => field.onChange(e.detail.value)}
                                                            onIonBlur={() => field.onBlur()}
                                                        />}
                                                    />
                                                </IonItem>
                                                <div className="errorText">{errors.first_name?.message}</div>

                                                <IonItem className="centeredPreAuthInput">
                                                    <Controller
                                                        name="name"
                                                        control={control}
                                                        rules={{
                                                            required: { value: true, message: "Bitte geben Sie Ihren Nachnamen an" },
                                                        }}
                                                        render={({ field }) => <IonInput
                                                            label="Nachname*"
                                                            labelPlacement="floating"
                                                            type="text"
                                                            aria-invalid={!!errors.name}
                                                            /* @ts-ignore */
                                                            onIonChange={(e) => field.onChange(e.detail.value)}
                                                            onIonBlur={() => field.onBlur()}
                                                        />}
                                                    />
                                                </IonItem>
                                                <div className="errorText">{errors.name?.message}</div>

                                                <IonItem className="centeredPreAuthInput">
                                                    <Controller
                                                        name="email"
                                                        control={control}
                                                        rules={{
                                                            required: { value: true, message: "Bitte geben Sie eine E-Mail-Adresse an" },
                                                            validate: { value: handleEmailValidation }
                                                        }}

                                                        render={({ field }) => <IonInput
                                                            label="E-Mail*"
                                                            labelPlacement="floating"
                                                            type="email"
                                                            aria-invalid={!!errors.email}
                                                            /* @ts-ignore */
                                                            onIonChange={(e) => field.onChange(e.detail.value)}
                                                            onIonBlur={() => field.onBlur()}
                                                        />}
                                                    />
                                                </IonItem>
                                                <div className="errorText">{errors.email?.message}</div>

                                                <IonItem className="centeredPreAuthInput">
                                                    <Controller
                                                        name="company_name"
                                                        control={control}
                                                        rules={{
                                                            required: { value: true, message: "Bitte geben Sie ein Unternehmen an" },
                                                        }}

                                                        render={({ field }) => <IonInput
                                                            label="Name des Unternehmens*"
                                                            labelPlacement="floating"
                                                            type="text"
                                                            aria-invalid={!!errors.company_name}
                                                            /* @ts-ignore */
                                                            onIonChange={(e) => field.onChange(e.detail.value)}
                                                            onIonBlur={() => field.onBlur()}
                                                        />}
                                                    />
                                                </IonItem>
                                                <div className="errorText">{errors.company_name?.message}</div>
                                            </IonList>
                                            <div style={{ textAlign: "right" }}>
                                                <IonButton type="submit">Senden</IonButton>
                                            </div>

                                        </form>

                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>

                        <IonRow>

                        </IonRow>

                    </IonGrid>

                    <Footer />
                </div>

                <IonLoading
                    isOpen={loading}
                    message={"Lädt..."}
                />

                <IonToast
                    message={toast.message}
                    duration={3000}
                    onDidDismiss={() => setToast({ message: "", color: "danger", isOpen: false })}
                    color={toast.color}
                    isOpen={toast.isOpen}
                />


                <IonToast
                    message={successToast.message}
                    onDidDismiss={() => setSuccessToast({ message: "", color: "success", isOpen: false })}
                    color={"success"}
                    isOpen={successToast.isOpen}
                    buttons={[{
                        icon: closeOutline,
                        handler: () => setSuccessToast({ message: "", color: "success", isOpen: false }),
                        role: "cancel"
                    }]}
                />




            </IonContent>
        </IonPage>
    )
}

export default CompanyRegistration;