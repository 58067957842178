import { IonRangeCustomEvent } from "@ionic/core";
import { IonButton, IonButtons, IonContent, IonFooter, IonIcon, IonModal, IonRange, IonToolbar, RangeChangeEventDetail } from "@ionic/react";
import { addOutline, refreshOutline, removeOutline } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
// @ts-ignore
import AvatarEditor from 'react-avatar-editor';

import "./ImageCropper.scss";

interface ImageCropperProps {
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void,
    img: string,
    imageCropperCallback: (img: string) => void
}

const ImageCropper: React.FC<ImageCropperProps> = ({ isOpen, setIsOpen, img, imageCropperCallback }) => {
    const [rotation, setRotation] = useState<number>(0);
    const [scale, setScale] = useState<number>(1.0);
    const cropperRef = useRef();

    const onRotation = () => {
        if (rotation === 270) {
            setRotation(0)
        } else {
            setRotation(rotation + 90)
        }
    }

    useEffect(() => {
        if(!isOpen){
            setScale(1.0);
            setRotation(0);
        }
    }, [isOpen])

    const onScale = (e: IonRangeCustomEvent<RangeChangeEventDetail>) => {
        /* @ts-ignore */
        const val: number = e.detail.value;
        setScale(val / 10)
    }

    const onSave = () => {
        if (cropperRef.current) {
            /* @ts-ignore */
            const canvasScaled = cropperRef.current.getImageScaledToCanvas();
            const croppedImg = canvasScaled.toDataURL();
            imageCropperCallback(croppedImg)
        }
    }

    return (
        <IonModal isOpen={isOpen} onDidDismiss={() => setIsOpen(false)}>
            <IonContent className="ion-padding">
                <div className="cropperEditorContainer">
                    <AvatarEditor
                        width={300}
                        height={300}
                        ref={cropperRef}
                        image={img}
                        border={50}
                        color={[255, 255, 255, 0.6]} // color of the cropping border
                        scale={scale}
                        rotate={rotation}
                        style={{
                            width: "100%",
                            height: "auto",
                            maxWidth: "310px"
                        }}
                    />
                </div>
                <div style={{textAlign: "center"}}>
                    <IonRange
                        aria-label="Zoom"
                        onIonChange={onScale}
                        min={10}
                        max={30}
                        label="Zoom"
                    >
                        <IonIcon slot="start" icon={removeOutline}></IonIcon>
                        <IonIcon slot="end" icon={addOutline}></IonIcon>
                    </IonRange>
                    <IonButton onClick={onRotation}>
                        <IonIcon icon={refreshOutline} slot="start"/> Drehen
                    </IonButton>
                </div>

            </IonContent>
            <IonFooter>
                <IonToolbar>
                    <IonButtons slot="end">
                        <IonButton role="cancel" onClick={() => setIsOpen(false)}>Abbrechen</IonButton>
                        <IonButton fill="solid" color="primary" type="submit" onClick={onSave}>Speichern</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonFooter>
        </IonModal>
    )
}

export default ImageCropper;