import { IonAlert } from "@ionic/react";
import { Location } from "../../../models/companyModels";
import { useAppDispatch } from "../../../data/redux/hooks";
import { deleteLocation } from "../../../data/redux/companySlice";

interface DeleteLococationAlertProps {
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void,
    selectedLocation?: Location
}

const DeleteLocationAlert: React.FC<DeleteLococationAlertProps> = ({ isOpen, setIsOpen, selectedLocation }) => {
    const dispatch = useAppDispatch();

    const handleDelete = () => {
        if (selectedLocation) {
            dispatch(deleteLocation({ id: selectedLocation.id }))
        }
    }

    return (
        <IonAlert
            isOpen={isOpen}
            header={`Standort entfernen`}
            message={`Sind Sie sicher, dass Sie <b>${selectedLocation?.name}</b> entfernen möchten?`}
            buttons={[
                {
                    text: "Abbrechen",
                    role: "cancel"
                }, {
                    text: "Entfernen",
                    handler: handleDelete
                },
            ]}
            onDidDismiss={() => setIsOpen(false)}
        >
        </IonAlert>
    )
}

export default DeleteLocationAlert;