import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonInput, IonItem, IonModal, IonTitle, IonToolbar } from "@ionic/react"
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { handleEmailValidation } from "../../../util/helper";
import { useAppDispatch } from "../../../data/redux/hooks";
import { addEmployee } from "../../../data/redux/companySlice";

interface AddEmployeeModalProps {
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void,
    role: string
}

interface AddEmployeeData {
    name: string,
    first_name: string,
    role: string,
    email: string
}

const AddEmployeeModal: React.FC<AddEmployeeModalProps> = ({ isOpen, setIsOpen, role }: AddEmployeeModalProps) => {
    const { control, handleSubmit, formState: { errors } } = useForm<AddEmployeeData>({
        mode: "onTouched",
        reValidateMode: "onChange"
    });
    const dispatch = useAppDispatch()

    const onSubmit: SubmitHandler<AddEmployeeData> = async (data: AddEmployeeData) => {
        data.role = role;
        dispatch(addEmployee(data))
        setIsOpen(false)
    }

    return (
        <IonModal isOpen={isOpen}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle id="inviteTraineeTitle">
                        {role === "company_admin" && "Personalleiter hinzufügen"}
                        {role === "trainer" && "Ausbilder hinzufügen"}
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">

                <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

                        <IonItem className="centeredPreAuthInput">
                            <Controller
                                name="first_name"
                                control={control}
                                rules={{
                                    required: { value: true, message: "Bitte geben Sie einen Vornamen an" },
                                }}

                                render={({ field }) => <IonInput
                                    label="Vorname"
                                    labelPlacement="floating"
                                    type="text"
                                    aria-invalid={!!errors.first_name}
                                    /* @ts-ignore */
                                    onIonChange={(e) => field.onChange(e.detail.value)}
                                    onIonBlur={() => field.onBlur()}
                                />}
                            />
                        </IonItem>
                        <div className="errorText">{errors.first_name?.message}</div>

                        <IonItem className="centeredPreAuthInput">
                            <Controller
                                name="name"
                                control={control}
                                rules={{
                                    required: { value: true, message: "Bitte geben Sie einen Nachnamen an" },
                                }}

                                render={({ field }) => <IonInput
                                    label="Nachname"
                                    labelPlacement="floating"
                                    type="text"
                                    aria-invalid={!!errors.name}
                                    /* @ts-ignore */
                                    onIonChange={(e) => field.onChange(e.detail.value)}
                                    onIonBlur={() => field.onBlur()}
                                />}
                            />
                        </IonItem>
                        <div className="errorText">{errors.name?.message}</div>


                        <IonItem className="centeredPreAuthInput">
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: { value: true, message: "Bitte geben Sie eine E-Mail-Adresse an" },
                                    validate: { value: handleEmailValidation }
                                }}

                                render={({ field }) => <IonInput
                                    label="E-Mail"
                                    labelPlacement="floating"
                                    type="email"
                                    aria-invalid={!!errors.email}
                                    /* @ts-ignore */
                                    onIonChange={(e) => field.onChange(e.detail.value)}
                                    onIonBlur={() => field.onBlur()}
                                />}
                            />
                        </IonItem>
                        <div className="errorText">{errors.email?.message}</div>
                </form>
            </IonContent>
            <IonFooter>
                <IonToolbar>
                    <IonButtons slot="end" className="ion-padding">
                        <IonButton onClick={() => setIsOpen(false)}>Abbrechen</IonButton>
                        <IonButton type="submit" onClick={handleSubmit(onSubmit)} fill="solid" color="primary">Senden {/* {loading && <IonSpinner ></IonSpinner>} */}</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonFooter>
        </IonModal>
    )
}

export default AddEmployeeModal