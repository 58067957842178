import { IonCol, IonContent, IonGrid, IonImg, IonInput, IonItem, IonList, IonPage, IonRouterLink, IonRow, IonToast } from "@ionic/react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { RouteComponentProps } from "react-router";
import { EmailFormData } from "../../../models/userModels";
import { handleEmailValidation } from "../../../util/helper";
import { RoundedButton } from "../../../components";

import "../centeredPreAuth.scss"
import { useState } from "react";
import AuthService from "../../../data/services/AuthService";
import { Toast } from "../../../models/helperModels";
import Footer from "../components/Footer";
import { error500Text } from "../../../util/error-helper";

const ForgotPassword: React.FC<RouteComponentProps> = () => {
    const { control, handleSubmit, formState: { errors } } = useForm<EmailFormData>({
        mode: "onTouched",
        reValidateMode: "onChange"
    });
    const [alreadyFired, setAlreadyFired] = useState(false);
    const [toast, setToast] = useState<Toast>({ isOpen: false, message: "", color: "danger" })

    const onSubmit: SubmitHandler<EmailFormData> = async (data: EmailFormData) => {
        //console.log(data)
        try {
            const res = await AuthService.requestPasswordReset(data.email);
            if (res.error) {
                console.log(res.error)
                setToast({ isOpen: true, message: res.error, color: "danger" })
            } else if (res.success) {
                setToast({ isOpen: true, message: res.success, color: "success" })
            } else{
                setToast({ isOpen: true, message: error500Text, color: "danger" })
            }
        } catch (err) {
            console.log(err)
            setToast({ isOpen: true, message: error500Text, color: "danger" })
        } finally {
            setAlreadyFired(true);
        }
    };

    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <div className="centeredPreAuthContainer">
                                <div className="imageContainer">
                                    <IonImg
                                        src="./assets/images/logo_woodbook_login_dark.png"
                                        alt="Woodbook Logo"
                                    ></IonImg>
                                </div>
                                <div className="centeredPreAuthContent">
                                    <h3>Passwort vergessen? Kein Problem!</h3>
                                    <p>Gib einfach unten deine Email-Adresse an und wir senden dir einen Link zu, mit dem du dein Passwort zurück setzen kannst.</p>
                                    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                                        <IonList lines="inset" className="inputList">
                                            <IonItem className="centeredPreAuthInput">
                                                <Controller
                                                    name="email"
                                                    control={control}
                                                    rules={{
                                                        required: { value: true, message: "Bitte gib eine E-Mail-Adresse an" },
                                                        validate: { value: handleEmailValidation }
                                                    }}

                                                    render={({ field }) => <IonInput
                                                        label="E-Mail"
                                                        labelPlacement="floating"
                                                        type="email"
                                                        aria-invalid={!!errors.email}
                                                        /* @ts-ignore */
                                                        onIonChange={(e) => field.onChange(e.detail.value)}
                                                        onIonBlur={() => field.onBlur()}
                                                    />}
                                                />
                                            </IonItem>
                                            {errors.email && <div className="errorText">{errors.email.message}</div>}
                                        </IonList>

                                        {/* Workaround weil shadow dom submit button onEnter kein submit event abfeuert */}
                                        <input type="submit" className="submit-enter" />

                                        <RoundedButton type="submit">{alreadyFired ? "erneut senden" : "Absenden"}</RoundedButton>
                                        {alreadyFired && <p>Keine Mail bekommen? Klicke oben auf erneut senden.</p>}
                                        <div style={{ marginTop: 30 }}>
                                            <IonRouterLink color="dark" routerLink="login">Zurück zum Login</IonRouterLink>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>

                <Footer />

                <IonToast
                    duration={3000}
                    message={toast.message}
                    isOpen={toast.isOpen}
                    color={toast.color}
                    onDidDismiss={() => setToast({ isOpen: false, message: "", color: "danger" })}
                />

            </IonContent>
        </IonPage>
    )
}

export default ForgotPassword;