import { IonButton, IonCheckbox, IonCol, IonContent, IonGrid, IonIcon, IonInput, IonItem, IonList, IonLoading, IonPage, IonPopover, IonRouterLink, IonRow, IonToast } from "@ionic/react";
import { RouteComponentProps } from "react-router";
import Logo from "../components/Logo";
import Footer from "../components/Footer";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { RegisterFormData } from "../../../models/userModels";
import { isValidEmail, isValidPassword } from "../../../util/helper";
import { useState } from "react";
import { eyeOffOutline, eyeOutline, informationOutline, logInOutline } from "ionicons/icons";

import "./Register.scss";
import { RoundedButton } from "../../../components";
import AuthService from "../../../data/services/AuthService";
import { Toast } from "../../../models/helperModels";
import { error500Text } from "../../../util/error-helper";

interface RegisterFormProps extends RouteComponentProps<{
    companyToken?: string
}> {
    setLoading: (loading: boolean) => void,
    setToast: (toast: Toast) => void
}

const RegisterForm: React.FC<RegisterFormProps> = ({ history, setLoading, setToast, match }: RegisterFormProps) => {
    const { control, handleSubmit, formState: { errors }, watch } = useForm<RegisterFormData>({
        mode: "onTouched",
        reValidateMode: "onChange",
        defaultValues: {
            first_name: "",
            name: "",
            email: "",
            password: "",
            password_confirmation: "",
            company_token: match.params.companyToken,
            agb: false
        },
    });
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showPasswordConfirmation, setShowPasswordConfirmation] = useState<boolean>(false);

    const register = async (data: RegisterFormData) => {
        setLoading(true)
        try {
            const res = await AuthService.register(data);
            console.log(res)
            if (res.error) {
                setToast({ isOpen: true, message: res.error, color: "danger" })
            } else if (res.user) {
                history.push("/email-verifizieren")
            } else {
                setToast({ isOpen: true, message: error500Text, color: "danger" })
            }
        } catch (err) {
            console.log(err)
            setToast({ isOpen: true, message: error500Text, color: "danger" })
        } finally {
            setLoading(false)
        }

    }

    const onSubmit: SubmitHandler<RegisterFormData> = (data: RegisterFormData) => {
        register(data);
    };

    const handleEmailValidation = (email: string) => {
        const isValid = isValidEmail(email);

        if (isValid) {
            return isValid;
        } else {
            return "Keine gültige E-Mail Adresse"
        }
    };

    const handlePasswordValidation = (password: string) => {
        const isValid = isValidPassword(password);

        if (isValid) {
            return isValid;
        } else {
            return "Das Password muss mindestens einen Buchstaben und eine Zahl enthalten"
        }
    }

    const handlePasswordConfirmValidation = (passwordConfirm: string) => {
        if (watch("password") !== passwordConfirm) {
            return "Die Passwörter stimmen nicht überein"
        }
    }

    return (
        <div style={{ maxWidth: 355 }}>
            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <IonList lines="inset" className="registerList">
                    <div style={{ display: "flex", gap: 20 }}>
                        <div>
                            <IonItem className="registerInput">
                                <Controller
                                    name="first_name"
                                    control={control}
                                    rules={{
                                        required: { value: true, message: "Bitte gib deinen Vornamen an" },
                                    }}
                                    /* @ts-ignore */
                                    render={({ field }) => <IonInput
                                        label="Vorname*"
                                        labelPlacement="floating"
                                        type="text"
                                        aria-invalid={!!errors.first_name}
                                        onIonChange={(e: any) => field.onChange(e.detail.value)}
                                        onIonBlur={() => field.onBlur()}
                                    />}
                                />
                            </IonItem>
                            <div className="errorText">{errors.first_name?.message}</div>
                        </div>
                        <div>
                            <IonItem className="registerInput">
                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{
                                        required: { value: true, message: "Bitte gib deinen Nachnamen an" },
                                    }}
                                    /* @ts-ignore */
                                    render={({ field }) => <IonInput
                                        label="Nachname*"
                                        labelPlacement="floating"
                                        type="text"
                                        aria-invalid={!!errors.name}
                                        onIonChange={(e: any) => field.onChange(e.detail.value)}
                                        onIonBlur={() => field.onBlur()}
                                    />}
                                />
                            </IonItem>
                            <div className="errorText">{errors.name?.message}</div>
                        </div>
                    </div>

                    {/* Email */}
                    <IonItem className="registerInput">
                        <Controller
                            name="email"
                            control={control}
                            rules={{
                                required: { value: true, message: "Bitte gib eine E-Mail-Adresse an" },
                                validate: { value: handleEmailValidation }
                            }}
                            /* @ts-ignore */
                            render={({ field }) => <IonInput
                                label="E-Mail*"
                                labelPlacement="floating"
                                type="email"
                                aria-invalid={!!errors.email}
                                onIonChange={(e: any) => field.onChange(e.detail.value)}
                                onIonBlur={() => field.onBlur()}
                            />}
                        />
                    </IonItem>
                    <div className="errorText">{errors.email?.message}</div>

                    {/* Password */}
                    <IonItem className="registerInput">
                        <Controller
                            name="password"
                            control={control}
                            rules={{
                                required: { value: true, message: "Bitte gib ein Passwort ein" },
                                validate: { value: handlePasswordValidation },
                                minLength: { value: 8, message: "Das Passwort muss mindestens 8 Zeichen lang sein" }
                            }}

                            render={({ field }) => <IonInput
                                label="Passwort*"
                                labelPlacement="floating"
                                aria-invalid={!!errors.password}
                                type={showPassword ? "text" : "password"}
                                /* @ts-ignore */
                                onIonChange={(e) => field.onChange(e.detail.value)}
                                onIonBlur={() => field.onBlur()}
                            />}
                        />

                        <IonButton fill="clear" aria-label="Passwort anzeigen" onClick={() => setShowPassword(!showPassword)}>
                            <IonIcon color="dark" aria-hidden="true" icon={showPassword ? eyeOffOutline : eyeOutline} />
                        </IonButton>
                    </IonItem>
                    <div className="errorText">{errors.password?.message}</div>




                    {/* Password Confirmation */}
                    {/* Password */}
                    <IonItem className="registerInput">
                        <Controller
                            name="password_confirmation"
                            control={control}
                            rules={{
                                required: { value: true, message: "Bitte bestätige dein Passwort" },
                                validate: { handlePasswordConfirmValidation }
                            }}

                            render={({ field }) => <IonInput
                                label="Passwort bestätigen*"
                                labelPlacement="floating"
                                aria-invalid={!!errors.password_confirmation}
                                type={showPasswordConfirmation ? "text" : "password"}
                                /* @ts-ignore */
                                onIonChange={(e) => field.onChange(e.detail.value)}
                                onIonBlur={() => field.onBlur()}
                            />}
                        />

                        <IonButton fill="clear" aria-label="Passwort anzeigen" onClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)}>
                            <IonIcon color="dark" aria-hidden="true" icon={showPasswordConfirmation ? eyeOffOutline : eyeOutline} />
                        </IonButton>
                    </IonItem>
                    <div className="errorText">{errors.password_confirmation?.message}</div>

                    {/* Company Token */}
                    <IonItem className="registerInput">
                        <Controller
                            name="company_token"
                            control={control}
                            /* @ts-ignore */
                            render={({ field }) => <IonInput
                                label="Unternehmens Token"
                                labelPlacement="floating"
                                type="text"
                                value={field.value}
                                aria-invalid={!!errors.company_token}
                                onIonChange={(e: any) => field.onChange(e.detail.value)}
                                onIonBlur={() => field.onBlur()}
                            />}
                        />
                        <IonButton id="info-click-trigger" fill="clear" aria-label="Passwort anzeigen" >
                            <IonIcon color="dark" aria-hidden="true" icon={informationOutline} />
                        </IonButton>
                        <IonPopover trigger="info-click-trigger" triggerAction="click">
                            <IonContent className="ion-padding">
                                Wenn Dein Ausbildungsbetrieb auch bei Woodbook ist, dann kann Dein Ausbilder oder Personalleiter Dir einen Code zukommen lassen, mit dem Du Dich mit Deinem Betrieb verknüpfen kannst.
                            </IonContent>
                        </IonPopover>
                    </IonItem>
                    <div className="errorText">{errors.company_token?.message}</div>


                    {/* Terms & Conditions */}
                    <Controller
                        name="agb"
                        control={control}
                        rules={{
                            required: { value: true, message: "Bitte stimme unserem Haftungsausschluss sowie weiteren Hinweisen und der Dateschutzerklärung zu" }

                        }}
                        render={({ field }) => <div style={{ display: "flex", gap: 20 }}>
                            <IonCheckbox
                                aria-invalid={!!errors.agb}
                                onIonChange={(e: any) => field.onChange(e.detail.checked)}
                                onIonBlur={() => field.onBlur()}
                                aria-labelledby="agbAndDataPrivacy"
                                style={{ marginTop: 2 }}
                            />
                            <div id="agbAndDataPrivacy" style={{ textAlign: "left" }}>
                                Ich bestätige, dass ich die <IonRouterLink href="/datenschutz" target="_blank" rel="noreferrer">Datenschutzerklärung</IonRouterLink> sowie den <IonRouterLink href="/haftungsausschluss" target="_blank" rel="noreferrer">Disclaimer (Haftungsausschluss)</IonRouterLink> von woodbook.info gelesen und verstanden habe und akzeptiere.*
                            </div>
                        </div>}
                    />

                    <div className="errorText">{errors.agb?.message}</div>

                </IonList>

                <RoundedButton type="submit">
                    registrieren
                    <IonIcon icon={logInOutline} slot="end" />
                </RoundedButton>

                <div className="requiredHelper">*Pflichtfeld</div>

            </form>
            <div style={{ textAlign: "left", marginTop: 20 }}>Du hast schon einen Account? <IonRouterLink color="dark" routerLink="/login">Zum Login</IonRouterLink></div>
        </div>
    )
}

const RegisterPage: React.FC<RouteComponentProps> = (props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [toast, setToast] = useState<Toast>({ isOpen: false, message: "", color: "primary" });

    return (
        <IonPage>
            <IonContent>
                <div className="registerContainer" >
                    <IonGrid>
                        <IonRow>
                            <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="5">
                                <Logo />
                            </IonCol>
                            <IonCol className="ion-hide-lg-down" size="2">
                                <div className="specialBorderFading"></div>
                            </IonCol>
                            <IonCol>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <div style={{ maxWidth: 335 }}>
                                        <RegisterForm {...props} setLoading={setLoading} setToast={setToast} />
                                    </div></div>
                            </IonCol>
                        </IonRow>
                    </IonGrid>

                </div>
                <Footer />
                <IonLoading
                    isOpen={loading}
                    message="Lädt..."
                />
                <IonToast
                    color={toast.color}
                    message={toast.message}
                    isOpen={toast.isOpen}
                    duration={3000}
                    onDidDismiss={() => setToast({ isOpen: false, message: "", color: "danger" })}
                >

                </IonToast>
            </IonContent>

        </IonPage>
    )
}

export default RegisterPage;