import { IonAlert, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonIcon, IonPage, IonRow } from "@ionic/react"
import { RouteComponentProps } from "react-router-dom"
import { BackButtonRow, PageContainer, ReportDayCard, RoundedButton, WeekSwitcher } from "../../components"
import { ReportDay, WeekSwitcherProps } from "../../models/reportModels"
import { useEffect, useState } from "react"
import UserSelect from "../../components/admin/userSelect/UserSelect"
import { alertCircleOutline, checkmarkCircleOutline } from "ionicons/icons"
import ReportService from "../../data/services/ReportService"
import { useAppDispatch, useAppSelector } from "../../data/redux/hooks"
import { setReportWeek, updateReportWeekState } from "../../data/redux/reportWeekSlice"
import { changeReportListWeekState } from "../../data/redux/reportListSlice"
import { format } from "date-fns"
import { getTrainees } from "../../data/redux/traineeListSlice"
import { getReportStats } from "../../data/redux/reportStatsSlice"
import { User } from "../../models/userModels"
import { setError } from "../../data/redux/errorSlice"
import { error500Text } from "../../util/error-helper"
import RecordBookWeek from "../recordBook/RecordBookWeek"

interface TraineeReportDetailProps extends RouteComponentProps<{
    traineeId: string,
    year: string,
    week: string,
}> { }


interface Params {
    traineeId: string,
    year: string | number,
    week: string | number
}



const TraineeReportDetailContent: React.FC<TraineeReportDetailProps> = ({ match }) => {
    const [params, setParams] = useState<Params>(match.params)
    const [acceptAlertOpen, setAcceptAlertOpen] = useState(false);
    const [dismissAlertOpen, setDismissAlertOpen] = useState(false);
    const dispatch = useAppDispatch();
    const reportWeek = useAppSelector((state) => state.reportWeek.reportWeek);
    const { traineesWithOpenEntries: userList } = useAppSelector((state) => state.traineeList)
    const [selectedTrainee, setSelectedTrainee] = useState<User | undefined>();


    useEffect(() => {
        dispatch(getTrainees())
    }, [dispatch])

    useEffect(() => {
        const findSelectedTrainee = () => {
            var result = userList.find(trainee => {
                return trainee.id === Number(params.traineeId)
            })
            setSelectedTrainee(result)
        }
        findSelectedTrainee()
    }, [userList, params.traineeId])

    useEffect(() => {
        let ignore = false;

        const setCurrentReportWeek = async () => {
            try {
                const res = await ReportService.getReportWeekEntries(params.traineeId, params.year, params.week)
                if (!ignore) {
                    if (res && res.report) {
                        dispatch(setReportWeek(res.report))
                    } else if (res.error) {
                        dispatch(setError({
                            isOpen: true,
                            message: res.error
                        }))
                        dispatch(setReportWeek(undefined))
                    }
                }
            } catch (err) {
                dispatch(setError({
                    isOpen: true,
                    message: error500Text
                }))
                console.log(err)
            }

        }

        if (params.year && params.week && params.traineeId) {
            setCurrentReportWeek();
        }

        return () => {
            ignore = true;
        }
    }, [params, dispatch])

    const userSelectCallback = (id: string) => {
        setParams({
            ...params,
            traineeId: id
        })
        window.history.replaceState(null, "Meine Azubis", `/tabs/azubis/${id}/${params.year}/${params.week}`)
    }

    const weekSwitcherCallback = (year: string | number, week: string | number) => {
        setParams({
            ...params,
            year: year,
            week: week
        })
        window.history.replaceState(null, "Berichtsheft", `/tabs/azubis/${params.traineeId}/${year}/${week}`)
    }

    const getTrainee = (id: string) => {
        const trainee = userList.find(x => String(x.id) === String(id))
        return trainee?.trainee_start
    }

    const weekSwitcherProps: WeekSwitcherProps = {
        year: params.year,
        week: params.week,
        changeWeek: weekSwitcherCallback,
        state: reportWeek && reportWeek.state,
        trainingStart: getTrainee(params.traineeId) || undefined
    }

    const acceptCalendarWeek = async () => {
        if (reportWeek && reportWeek.id) {
            const res = await ReportService.setReportWeekState(reportWeek.id, "completed")
            //console.log(res)
            if (res.report) {
                dispatch(updateReportWeekState({ state: res.report.state, updated_at: res.report.updated_at }))
                dispatch(changeReportListWeekState({ state: res.report.state, updated_at: res.report.updated_at, id: res.report.id }))
                dispatch(getReportStats())
                dispatch(getTrainees())
            }
        }
    }

    const dismissCalendarWeek = async (e: any) => {
        //console.log(e[0])
        if (reportWeek && reportWeek.id) {
            const res = await ReportService.setReportWeekState(reportWeek.id, "dismissed", e[0])
            if (res.report) {
                dispatch(updateReportWeekState({ state: res.report.state, updated_at: res.report.updated_at }))
                dispatch(changeReportListWeekState({ state: res.report.state, updated_at: res.report.updated_at, id: res.report.id }))
                dispatch(getReportStats())
                dispatch(getTrainees())
            }
        }
    }

    const getStateButton = (state: string) => {
        switch (state) {
            case "submitted":
                return (
                    <>
                        <RoundedButton type="button" color="danger" onClick={() => setDismissAlertOpen(true)}>
                            KW ablehnen
                            <IonIcon icon={alertCircleOutline} />
                        </RoundedButton>
                        <RoundedButton type="button" color="primary" onClick={() => setAcceptAlertOpen(true)}>
                            KW signieren
                            <IonIcon icon={checkmarkCircleOutline} />
                        </RoundedButton>
                    </>
                )
            case "completed":
                return (
                    <RoundedButton type="button" color="success" fill="outline" disabled>
                        KW{params.week} am {format(new Date(reportWeek.updated_at), "dd.MM.yy")} signiert
                        <IonIcon icon={checkmarkCircleOutline} />
                    </RoundedButton>
                )
            default: return null
        }
    }

    return (
        <IonPage>
            <PageContainer
                name="Meine Azubis"
                backButton={true}
                weekSwitcherToolbar={true}
                weekSwitcherProps={weekSwitcherProps}
            >
                <IonGrid>
                    <BackButtonRow path={`/tabs/azubis/${params.traineeId}`} />
                    <IonRow>
                        <IonCol size="12" >
                            <IonCard>
                                <IonCardHeader>
                                    <div className="reportWeekDesktopHeader">
                                        <div className="minWidthCol ion-hide-lg-down">
                                            <IonCardTitle className='headline'>Meine Azubis</IonCardTitle>
                                        </div>
                                        <div className="ion-hide-lg-down">
                                            <WeekSwitcher week={params.week} year={params.year} changeWeek={weekSwitcherCallback} state={reportWeek && reportWeek.state} trainingStart={getTrainee(params.traineeId) || undefined} />
                                        </div>
                                        <div className="minWidthCol">
                                            {userList && <UserSelect selectedUserId={params.traineeId} setSelectedUserId={userSelectCallback} userList={userList} />}
                                        </div>
                                    </div>
                                </IonCardHeader>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        {selectedTrainee && selectedTrainee.report_type === "daily" && <IonCol>
                            {reportWeek &&
                                <>
                                    {reportWeek.state === "submitted" || reportWeek.state === "completed" ?
                                        reportWeek.days.map((day: ReportDay) => {
                                            return (
                                                <IonCol size="12" key={day.id}>
                                                    <ReportDayCard day={day} />
                                                </IonCol>
                                            )
                                        }
                                        )
                                        :
                                        <IonCol size="12">
                                            <IonCard>
                                                <IonCardContent>
                                                    {reportWeek.state === "open" && "Ihr Azubi hat den Wochen-Bericht noch nicht für Sie freigegeben."}
                                                    {reportWeek.state === "dismissed" && "Sie haben den Bericht abglehnt. Ihr Azubi hat jetzt Zeit den Bericht noch mal zu bearbeiten und gibt ihn dann erneut für Sie frei."}
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    }
                                </>

                            }
                        </IonCol>}

                        {selectedTrainee && selectedTrainee.report_type === "weekly" &&
                            <>
                                {reportWeek &&
                                    <>
                                        {reportWeek.state === "submitted" || reportWeek.state === "completed" ?
                                            <RecordBookWeek reportWeek={reportWeek} editable={false} />
                                            :
                                            <IonCol size="12">
                                                <IonCard>
                                                    <IonCardContent>
                                                        {reportWeek.state === "open" && "Ihr Azubi hat den Wochen-Bericht noch nicht für Sie freigegeben."}
                                                        {reportWeek.state === "dismissed" && "Sie haben den Bericht abglehnt. Ihr Azubi hat jetzt Zeit den Bericht noch mal zu bearbeiten und gibt ihn dann erneut für Sie frei."}
                                                    </IonCardContent>
                                                </IonCard>
                                            </IonCol>
                                        }
                                    </>

                                }

                            </>
                        }
                    </IonRow>
                    <IonRow>
                        {reportWeek &&
                            <IonCol>
                                <div className="reportDetailBtnContainer">
                                    {getStateButton(reportWeek.state)}
                                </div>
                            </IonCol>
                        }
                    </IonRow>
                </IonGrid>
            </PageContainer>
            <IonAlert
                isOpen={acceptAlertOpen}
                onDidDismiss={() => setAcceptAlertOpen(false)}
                header={`KW${params.week} akzeptieren`}
                message="Möchten Sie diese Kalenderwoche akzeptieren? Der Vorgang kann nicht rückgängig gemacht werden."
                buttons={[
                    {
                        text: 'abbrechen',
                        role: 'cancel',
                        handler: () => setAcceptAlertOpen(false)
                    },
                    {
                        text: "akzeptieren",
                        role: 'confirm',
                        handler: acceptCalendarWeek
                    }
                ]}
            />

            <IonAlert
                isOpen={dismissAlertOpen}
                onDidDismiss={() => setDismissAlertOpen(false)}
                header={`KW${params.week} ablehnen`}
                inputs={[
                    {
                        type: "textarea",
                        placeholder: "Kommentar"
                    }
                ]}
                buttons={[
                    {
                        text: 'abbrechen',
                        role: 'cancel',
                        handler: () => setDismissAlertOpen(false)
                    },
                    {
                        text: "ablehnen",
                        role: 'confirm',
                        handler: dismissCalendarWeek
                    }
                ]}
            />
        </IonPage>
    )
}

const TraineeReportDetail: React.FC<TraineeReportDetailProps> = (props: TraineeReportDetailProps) => {
    return (
        <TraineeReportDetailContent
            {...props}
            key={props.match.params.year + props.match.params.week + props.match.params.traineeId}
        />
    )
}

export default TraineeReportDetail;