import reportDayReducer from "./reportDaySlice";
import reportWeekReducer from "./reportWeekSlice";
import reportListReducer from "./reportListSlice";
import reportStatsReducer from "./reportStatsSlice";
import traineeListReducer from "./traineeListSlice";
import messageReducer from "./messageSlice";
import userReducer from "./userSlice";
import companyReducer from "./companySlice";
import latestReportsReducer from "./latestReportsSlice"
import errorReducer from "./errorSlice";
import { combineReducers } from "@reduxjs/toolkit";


const combinedReducer = combineReducers({
    reportDay: reportDayReducer,
    reportWeek: reportWeekReducer,
    reportList: reportListReducer,
    latestReports: latestReportsReducer,
    reportStats: reportStatsReducer,
    traineeList: traineeListReducer,
    message: messageReducer,
    user: userReducer,
    company: companyReducer,
    error: errorReducer
})


/* @ts-ignore */
export const rootReducer = (state, action) => {
    if (action.type === "user/logout/fulfilled") {
        state = undefined
    }
    return combinedReducer(state, action)
}
