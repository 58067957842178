import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonInput, IonItem, IonModal, IonTitle, IonToolbar } from "@ionic/react"
import { useAppDispatch, useAppSelector } from "../../../data/redux/hooks"
import { createDepartment } from "../../../data/redux/companySlice";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { CreateDepartmentFormData } from "../../../models/companyModels";

interface CreateDepartmentModalProps {
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void,
}

const CreateDepartmentModal: React.FC<CreateDepartmentModalProps> = ({ isOpen, setIsOpen }: CreateDepartmentModalProps) => {
    const dispatch = useAppDispatch();
    const { company } = useAppSelector((state) => state.company)
    const { control, handleSubmit, formState: { errors }, reset } = useForm<CreateDepartmentFormData>({
        mode: "onTouched",
        reValidateMode: "onChange",
        defaultValues: { name: "" }
    });

    const onSubmit: SubmitHandler<CreateDepartmentFormData> = (formData) => {
        let data = {
            name: formData.name,
            company_id: company?.company.id
        }

        dispatch(createDepartment(data))
        setIsOpen(false)
        reset({ name: "" })
    }


    return (
        <IonModal isOpen={isOpen} onDidDismiss={() => { setIsOpen(false); reset({name: ""})}}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Abteilung hinzufügen</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                        <IonItem className="centeredPreAuthInput">
                            <Controller
                                name="name"
                                control={control}
                                rules={{
                                    required: { value: true, message: "Bitte geben Sie einen Namen an" },
                                }}

                                render={({ field }) => <IonInput
                                    label="Name"
                                    labelPlacement="floating"
                                    type="text"
                                    aria-invalid={!!errors.name}
                                    value={field.value}
                                    /* @ts-ignore */
                                    onIonInput={(e) => field.onChange(e.detail.value)}
                                    onIonBlur={() => field.onBlur()}
                                />}
                            />
                        </IonItem>
                        <div className="errorText">{errors.name?.message}</div>
                </form>
            </IonContent>
            <IonFooter>
                <IonToolbar >
                    <IonButtons slot="end">
                        <IonButton role="cancel" onClick={() => setIsOpen(false)}>Abbrechen</IonButton>
                        <IonButton fill="solid" color="primary" type="submit" onClick={handleSubmit(onSubmit)}>speichern</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonFooter>


        </IonModal>
    )
}

export default CreateDepartmentModal;