import { CapacitorHttp, HttpOptions, HttpResponse } from "@capacitor/core";

const getNews = async (page: number, amount: number) => {
  const url = `https://schnittholz.saegeindustrie.de/api/news_articles?page=${page}&amount=${amount}`;

  const options: HttpOptions = {
    url: url,
    headers: {
      "Access-Control-Allow-Origin": "https://schnittholz.saegeindustrie.de",
      "Content-Type": "application/json",
    },
  };

  const response: HttpResponse = await CapacitorHttp.get(options);
  const { data } = response;
  return data;
};

const getSingleNews = async (id: string) => {
  const url = `https://schnittholz.saegeindustrie.de/api/news_article?id=${id}`;

  const options: HttpOptions = {
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response: HttpResponse = await CapacitorHttp.get(options);
  const { data } = response;
  return data;
};

const NewsService = {
  getNews,
  getSingleNews,
};

export default NewsService;
