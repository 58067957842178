import { forwardRef, useEffect, useLayoutEffect, useRef } from 'react';
import Quill from 'quill';
import "quill/dist/quill.snow.css";
import { Delta } from 'quill/core';

const toolbarOptions = [['bold', 'italic', 'underline'], [{ 'list': 'ordered' }, { 'list': 'bullet' }],];

interface EditorProps {
  defaultValue: Delta,
  onTextChange,
}
export type Ref = HTMLDivElement;

// Editor is an uncontrolled React component
const Editor = forwardRef<Ref, EditorProps>(
  ({  defaultValue, onTextChange, }, ref) => {
    const containerRef = useRef(null);
    const defaultValueRef = useRef(defaultValue);
    const onTextChangeRef = useRef(onTextChange);

    useLayoutEffect(() => {
      onTextChangeRef.current = onTextChange;
    });

    useEffect(() => {
      const container = containerRef.current;
      const editorContainer = container.appendChild(
        container.ownerDocument.createElement('div'),
      );
      const quill = new Quill(editorContainer, {
        theme: 'snow',
        modules: {
          toolbar: toolbarOptions
        }
      });
      /* @ts-ignore */
      ref.current = quill;

      if (defaultValueRef.current) {
        quill.setContents(defaultValueRef.current);
      }

      quill.on(Quill.events.TEXT_CHANGE, (...args) => {
        let content = quill.getContents()
        onTextChangeRef.current?.(content.ops);
      });

      return () => {
        /* @ts-ignore */
        ref.current = null;
        container.innerHTML = '';
      };
    }, [ref]);

    return <div className="quillEditor" ref={containerRef}></div>;
  },
);

Editor.displayName = 'Editor';

export default Editor;