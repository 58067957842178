import { format } from "date-fns";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <div className="desktopFooter ion-hide-lg-down" role="contentinfo">
            <div>
                © {format(new Date(), "yyyy")} | Deutsche Säge- und Holzindustrie
            </div>
            <div >
                <Link style={{marginRight: 30}} to={"/tabs/datenschutz"}>Datenschutz</Link>
                <Link style={{marginRight: 30}} to={"/tabs/impressum"}>Impressum</Link>
                <Link to={"/tabs/disclaimer"}>Disclaimer</Link>
            </div>
        </div>
    )
}

export default Footer;