import { RouteComponentProps } from "react-router";

import "./ReportBook.scss";
import { RecordBookPreviewContent } from "../../components";
import { useAppDispatch, useAppSelector } from "../../data/redux/hooks";
import { useEffect } from "react";
import { getReportStats } from "../../data/redux/reportStatsSlice";

interface RecordBookPreviewProps extends RouteComponentProps<{
    filter?: string
}> { }

const RecordBookPreview: React.FC<RecordBookPreviewProps> = (props) => {
    const { user } = useAppSelector((state) => state.user)
    const { reportStats } = useAppSelector((state) => state.reportStats)
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (reportStats === undefined) {
            dispatch(getReportStats());
        }
    }, [dispatch, reportStats])

    return (
        <>
            {user && <RecordBookPreviewContent selectedUser={user} stats={reportStats} {...props} />}
        </>
    )
}



export default RecordBookPreview;
