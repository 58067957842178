import { AccordionGroupCustomEvent, IonAccordion, IonAccordionGroup, IonAvatar, IonButton, IonItem } from "@ionic/react";
import { User } from "../../../models/userModels"
import { useRef } from "react";
import AzubiProgressBar from "../azubiProgressBar/AzubiProgressBar";
import { mapAzubiStateName, stateList } from "../../../util/report-helper";

interface TraineeEntryProps {
    trainee: User
}

const TraineeEntryMobile: React.FC<TraineeEntryProps> = ({ trainee }: TraineeEntryProps) => {
    const listenerOut = useRef<null | HTMLParagraphElement>(null);

    const accordionGroupChange = (ev: AccordionGroupCustomEvent) => {
        const nativeEl = listenerOut.current;
        if (!nativeEl) {
            return;
        }

        const selectedValue = ev.detail.value;

        nativeEl.style.display = selectedValue === undefined ? "none" : "block"
    }


    return (
        <div className="traineeEntryMobile">
            <IonAccordionGroup onIonChange={accordionGroupChange}>
                <IonAccordion value={trainee.id.toString()} className="traineeAccordeon">
                    <IonItem slot="header" /* routerLink={`/tabs/azubis/${trainee.id}`} */>
                        <div className="traineeEntryHead">
                            <div className="profile">
                                <IonAvatar>
                                    <img src={trainee.image ? trainee.image : "./assets/images/profile_placeholder.jpg"} alt="Profilbild" />
                                </IonAvatar>
                                <div className="info">
                                    <div className="name">{trainee.first_name} {trainee.name}</div>
                                    <div className="attributeRow">
                                        {trainee.job && <div>{trainee.job}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </IonItem>
                    <div slot="content" className="dropdownContent">
                        <div className="attributeRow">
                            {trainee.trainee_year !== 0 && <div>{trainee.trainee_year}. Lehrjahr</div>}
                            {trainee.location_id && <div>{trainee.location_name}</div>}
                            {trainee.department_id && <div>{trainee.department_name}</div>}

                        </div>
                    </div>
                </IonAccordion>
            </IonAccordionGroup>
            <div style={{ marginTop: 10, marginBottom: 7 }}>
                {trainee.stats && <AzubiProgressBar data={trainee.stats} />}
            </div>
            <div ref={listenerOut} style={{ display: "none" }} >
                <div className="progressBarDescription">
                    {stateList.map(state => {
                        return (
                            <div key={state} style={{textAlign: "center", lineHeight: "1rem", marginBottom:10}}>
                                <div><b>{trainee.stats && trainee.stats[state]}</b></div>
                                <div style={{fontSize: 13}}>{mapAzubiStateName(state)}</div>
                            </div>
                        )
                    })}
                </div>
                <div style={{ textAlign: "right" }} >
                    <IonButton routerLink={`/tabs/azubis/${trainee.id}`}>zum Azubi</IonButton>
                </div>

            </div>
        </div>
    )
}

export default TraineeEntryMobile;