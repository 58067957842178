import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonPage, IonRow } from "@ionic/react"
import { RouteComponentProps } from "react-router"

import "./official.scss"
import Footer from "../preAuth/components/Footer"
import { PageContainer } from "../../components"

const DisclaimerContent: React.FC = () => {
    return (
        <>
            <h3>Hinweis zur Haftung</h3>
            <p>Haftungsansprüche, die sich auf Schäden materieller oder ideeller Art beziehen, die sich durch die Nutzung oder Nichtnutzung aller Informationen auf App und ihren Unterseiten unmittelbar oder mittelbar verursacht wurden, sind ausgeschlossen. Der Haftungsausschluss gilt nicht für den Fall des Vorliegens eines den Verantwortlichen der App zuzurechnenden vorsätzlichen oder grob fahrlässigen Verhaltens.</p>
            <p>Insbesondere kann keine Haftung für die Datensicherung übernommen werden. Für die Datensicherung und Datenwiederherstellung hat der Nutzer Sorge zu tragen. </p>
            <p>Die Inhalte fremder Webseiten, auf die mittels eines Hyperlinks verwiesen wird, dienen lediglich der Information. Die Verantwortlichkeit für diese fremden Inhalte liegt alleine bei dem Anbieter, der die Inhalte bereithält.</p>
            <p>Vor dem Einrichten des entsprechenden Verweises sind die Webseiten der anderen Anbieter mit großer Sorgfalt und nach bestem Wissen und Gewissen überprüft worden. Es kann jedoch keine Gewähr für die Vollständigkeit und Richtigkeit von Informationen übernommen werden, die über weiterführende Hyperlinks erreicht werden.</p>
            <p>Der Inhalt der anderen Webseiten kann jederzeit ohne Wissen des DeSH geändert werden. Falls diese Webseite auf Seiten verweisen sollte, deren Inhalt Anlass zur Beanstandung gibt, bittet der DeSH um Mitteilung.</p>
            <p>Der DeSH ist berechtigt, ohne vorherige Ankündigung Änderungen oder Ergänzungen der bereitgestellten Informationen vorzunehmen, gegebenenfalls auch Informationen zu entfernen.</p>
            <p>Die Informationen auf dieser Webseite stellen keinen Rechtsrat dar und ersetzen keinesfalls eine rechtskundige Beratung. Die Anwendung aller Hinweise ist im Einzelfall vom Anwender zu prüfen und an den jeweiligen Einzelfall anzupassen.</p>

            <h3>Gendering-Hinweis</h3>
            <p>Für den DeSH ist es selbstverständlich, dass mit jeder Formulierung alle Geschlechter angesprochen werden.</p>

            <h3>Hinweise zu Urheber- und Kennzeichenrechten</h3>
            <ol type="a">
                <li>Nutzungsbedingungen: Die Inhalte von dieser Webseite werden durch das Urheberrecht geschützt. Ohne vorherige Genehmigung durch den DeSH und außerhalb der gesetzlich zulässigen Verwertungshandlungen sind sowohl die kommerzielle Vervielfältigung, Verbreitung, öffentliche Wiedergabe und Bearbeitung der auf diesem Server unterhalb der Domain liegenden Webseiten als auch die kommerzielle Nutzung unserer Datenbanken ausdrücklich untersagt.</li>
                <li>Kennzeichenrechte Dritter bleiben unberührt; insbesondere die Markenrechte der in dieser Webseite angesprochenen Produkte und Lieferanten.</li>
                <li>Quellenangaben für die verwendeten Bilder und Grafiken soweit nicht anders vermerkt: Deutsche Säge- und Holzindustrie Bundesverband e. V.</li>
            </ol>


        </>
    )
}

export const DisclaimerPage: React.FC<RouteComponentProps> = () => {
    return (
        <IonPage>
            <IonContent>
                <div className="officialUnauthContainer">
                    <IonGrid>

                        <IonRow>
                            <IonCol>
                                <div style={{ textAlign: "center" }}>
                                    <img src="./assets/images/logo_woodbook_login_dark.png" alt="Woodbook Logo" />
                                </div>
                            </IonCol>
                        </IonRow>

                        <IonRow>
                            <IonCol>
                                <IonButton routerLink="/login" fill="clear">zum Login</IonButton>
                            </IonCol>
                        </IonRow>

                        <IonRow>
                            <IonCol>
                                <IonCard>
                                    <IonCardHeader>
                                        <IonCardTitle className='headline'>
                                            Haftungsausschluss sowie weitere Hinweise
                                        </IonCardTitle>
                                    </IonCardHeader>
                                </IonCard>
                            </IonCol>
                        </IonRow>

                        <IonRow>
                            <IonCol>
                                <IonCard>
                                    <IonCardContent>
                                        <div className="formattedText">
                                            <DisclaimerContent />
                                        </div>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <Footer />
                </div>
            </IonContent>
        </IonPage>
    )
}

export const DisclaimerPageAuthenticated: React.FC<RouteComponentProps> = () => {
    return (
        <IonPage>
            <PageContainer name="Disclaimer" backButton={false}>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonCard>
                                <IonCardHeader>
                                    <IonCardTitle className='headline'>
                                    HAFTUNGSAUSSCHLUSS SOWIE WEITERE HINWEISE
                                    </IonCardTitle>
                                </IonCardHeader>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>
                            <IonCard>
                                <IonCardContent >
                                    <div className="formattedText">
                                        <DisclaimerContent />
                                    </div>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </PageContainer>
        </IonPage>
    )
}