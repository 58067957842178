import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { User } from "../../models/userModels";
import TraineeService from "../services/TraineeService";
import { setError } from "./errorSlice";
import { error500Text } from "../../util/error-helper";

interface GroupedUser {
  group_name: string;
  users: User[];
}

interface TraineesState {
  loading: boolean;
  error: undefined | string;
  groupedAndSortedTrainees: GroupedUser[];
  traineesWithOpenEntries: User[];
}

const initialState: TraineesState = {
  groupedAndSortedTrainees: [],
  loading: false,
  error: undefined,
  traineesWithOpenEntries: [],
};

export const getGroupedAndSortedTrainees = createAsyncThunk(
  "trainees/getGroupedAndSortedTrainees",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {

      const res = await TraineeService.getGroupedAndSortedTrainees(
        data.group,
        data.sort
      );
      if (res.error) {
        dispatch(setError({
          isOpen: true,
          message: res.error
        }))
        return rejectWithValue(res.error);
      } else if (res.users) {
        return res.users;
      } else {
        dispatch(setError({
          isOpen: true,
          message: error500Text
        }))
        return rejectWithValue(error500Text);
      }
    } catch (err) {
      dispatch(setError({
        isOpen: true,
        message: error500Text
      }))
      return rejectWithValue(err);
    }
  }
);

export const getTrainees = createAsyncThunk(
  "trainees/getTrainees",
  async (_data, { rejectWithValue, dispatch }) => {
    try {
      const res = await TraineeService.getTrainees();
      if (res.error) {
        dispatch(setError({
          isOpen: true,
          message: res.error
        }))
        return rejectWithValue(res.error);
      } else if (res.users) {
        return res.users;
      } else {
        dispatch(setError({
          isOpen: true,
          message: error500Text
        }))
        return rejectWithValue(error500Text);
      }
    } catch (err) {
      dispatch(setError({
        isOpen: true,
        message: error500Text
      }))
      return rejectWithValue(err);
    }
  }, {
  condition: (_data, { getState }) => {
    /* @ts-ignore */
    const { traineeList } = getState()
    if (traineeList.traineesWithOpenEntries.length > 0 || traineeList.loading === true) {
      // Already fetched or in progress, don't need to re-fetch
      return false
    }
  },
}
);

export const traineeListSlice = createSlice({
  name: "trainees",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /* get Trainees */
    builder.addCase(getGroupedAndSortedTrainees.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(
      getGroupedAndSortedTrainees.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.groupedAndSortedTrainees = payload;
      }
    );
    builder.addCase(
      getGroupedAndSortedTrainees.rejected,
      (state, { payload }) => {
        state.loading = false;
        /* @ts-ignore */
        state.error = payload;
      }
    );

    /* get Trainees with open Entries*/
    builder.addCase(getTrainees.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(getTrainees.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.traineesWithOpenEntries = payload;
    });
    builder.addCase(getTrainees.rejected, (state, { payload }) => {
      state.loading = false;
      /* @ts-ignore */
      state.error = payload;
    });
  },
});

export default traineeListSlice.reducer;
