import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonInput, IonItem, IonModal, IonTitle, IonToolbar } from "@ionic/react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { useAppDispatch, useAppSelector } from "../../../data/redux/hooks"
import { editCompany } from "../../../data/redux/companySlice"
import { useEffect } from "react"

interface EditCompanyNameProps {
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void
}

interface EditCompanyFormData {
    name: string,
}

const EditCompanyNameModal: React.FC<EditCompanyNameProps> = ({ isOpen, setIsOpen }: EditCompanyNameProps) => {
    const { company } = useAppSelector((state) => state.company)
    const { control, handleSubmit, formState: { errors }, reset } = useForm<EditCompanyFormData>({
        mode: "onTouched", 
        reValidateMode: "onChange", 
        defaultValues: {
            name: company ? company.company.name : "",
        }
    })
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (company && isOpen) {
            reset({ name: company?.company.name })
        }
    }, [company, isOpen, reset])


    //TODO Profil Update
    const onSubmit: SubmitHandler<EditCompanyFormData> = (data: EditCompanyFormData) => {
        let dataToSend = {
            name: data.name,
            id: company?.company.id,
        }
        dispatch(editCompany(dataToSend))
        setIsOpen(false);
    }

    return (
        <IonModal isOpen={isOpen} onDidDismiss={() => setIsOpen(false)}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Unternehmen bearbeiten</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <p>Hier können Sie den Namen Ihres Unternehmens bearbeiten.</p>
                <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <IonItem className="centeredPreAuthInput">
                        <Controller
                            name="name"
                            control={control}
                            rules={{
                                required: { value: true, message: "Bitte geben Sie einen Namen an" },
                            }}
                            render={({ field }) => <IonInput
                                label="Name"
                                type="text"
                                labelPlacement="floating"
                                aria-invalid={!!errors.name}
                                value={field.value}
                                onIonInput={(e) => field.onChange(e.detail.value)}
                                onIonBlur={() => field.onBlur()}
                            />}
                        />
                    </IonItem>
                    <div className="errorText">{errors.name?.message}</div>
                </form>
            </IonContent>
            <IonFooter>
                <IonToolbar>
                    <IonButtons slot="end">
                        <IonButton role="cancel" onClick={() => setIsOpen(false)}>Abbrechen</IonButton>
                        <IonButton type="submit" fill="solid" color="primary" onClick={handleSubmit(onSubmit)}>Speichern</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonFooter>
        </IonModal>
    )
}

export default EditCompanyNameModal;

