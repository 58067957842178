import { IonButton, IonIcon } from "@ionic/react"
import { mapColor, mapIcon, stateList, stateListSmall } from "../../util/report-helper"
import { Stat } from "../../models/reportModels"
import { useAppSelector } from "../../data/redux/hooks"

type FilterButtonBarProps = {
    filter: Stat,
    setFilter: (filter: Stat) => void,
    stats?: Stat
}


const FilterButtonBar: React.FC<FilterButtonBarProps> = ({ filter, setFilter, stats }: FilterButtonBarProps) => {
    const { user } = useAppSelector((state) => state.user);

    const handleFilterClick = (val?: string) => {
        let temp: Stat = {
            completed: 0, dismissed: 0, submitted: 0, open: 0
        };
        if (val) {
            temp[val] = 1;
        }
        setFilter(temp);
    }

    const checkAllButtonColor = () => {
        const isEmpty = Object.values(filter).every(x => x === 0);

        if (isEmpty) return "primary"
        return "medium"
    }

    return (
        <div>
            <IonButton
                /* color={filter === "alle" ? "primary" : "medium"} */
                color={checkAllButtonColor()}
                size="small" fill="outline"
                style={{ fontWeight: 600 }}
                onClick={() => handleFilterClick()}
            >
                <span style={{ color: "var(--standard-font-color)" }}>Alle</span>
            </IonButton>
            {(user && user.company_name) ? stateList.map(state => {
                return (
                    <IonButton
                        color={filter[state] === 1 ? mapColor(state) : "medium"}
                        size="small" fill="outline"
                        key={state}
                        style={{ fontWeight: 600 }}
                        onClick={() => handleFilterClick(state)}
                    >
                        <IonIcon icon={mapIcon(state)} color={mapColor(state)} />

                        <span style={{ color: "var(--standard-font-color)", marginLeft: 2 }}>{stats && stats[state]}</span>
                    </IonButton>
                )
            }) : stateListSmall.map(state => {
                return (
                    <IonButton
                        color={filter[state] === 1 ? mapColor(state) : "medium"}
                        size="small" fill="outline"
                        key={state}
                        style={{ fontWeight: 600 }}
                        onClick={() => handleFilterClick(state)}
                    >
                        <IonIcon icon={mapIcon(state)} color={mapColor(state)} />

                        <span style={{ color: "var(--standard-font-color)", marginLeft: 2 }}>{stats && stats[state]}</span>
                    </IonButton>
                )
            })}
        </div>
    )
}

export default FilterButtonBar;