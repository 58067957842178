import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, isPlatform, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Dark Mode CSS */
import '@ionic/react/css/palettes/dark.class.css';

import "./App.scss"

/* Theme variables */
import './theme/variables.css';


import { ForgotPassword, Login, Register, ResetPassword, VerifyEmail } from './pages';
import { ThemeProvider, useTheme } from './data/context/ThemeContext';
import { SideMenu, TabMenu } from './components';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { store } from "./data/redux/store";
import { StatusBar, Style } from '@capacitor/status-bar';
import PleaseVerifyEmail from './pages/preAuth/verifyEmail/PleaseVerifyEmail';
import { useAppDispatch } from './data/redux/hooks';
import { getUser } from './data/redux/userSlice';
import { NotFoundPage } from './pages/404/404';
import { LegalNoticeUnauthenticated } from './pages/official/LegalNotice';
import { PrivacyUnauthenticated } from './pages/official/Privacy';
import { DisclaimerPage } from './pages/official/Disclaimer';
import CompanyRegistration from './pages/preAuth/companyRegistration/CompanyRegistration';

setupIonicReact({
  innerHTMLTemplatesEnabled: true
});

const IonicApp: React.FC = () => {
  const { theme } = useTheme();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getUser())
  }, [dispatch])

  useEffect(() => {
    if (isPlatform('mobile') || isPlatform('tablet')) {
      if (theme === "dark") {
        setStatusBarStyleDark();
      } else {
        setStatusBarStyleLight()
      }
    }
  }, [theme])

  const setStatusBarStyleDark = async () => {
    await StatusBar.setStyle({ style: Style.Dark }).catch(() => { });
    await StatusBar.setBackgroundColor({ color: "#1f1f1f" }).catch(() => { });
  };

  const setStatusBarStyleLight = async () => {
    await StatusBar.setStyle({ style: Style.Light }).catch(() => { });
    await StatusBar.setBackgroundColor({ color: "#ffffff" }).catch(() => { });
  };

  return (
    <IonApp >
      <IonReactRouter>
        <SideMenu />

        {/* @ts-ignore */}
        <IonRouterOutlet id="main-content" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>

          <Route /* exact */ path="/login" render={(props) => <Login {...props} />} />
          {/* <Route path="/login" render={(props) => {
            if (user !== undefined && !loading) {
              return <Redirect to="/tabs/dashboard" />
            } else {
              return <Login {...props} />
            }
          }} /> */}

          {/* <Route exact path="/login" render={(props) => { return (user !== undefined && !loading) ? <Redirect exact from="/login" to="/tabs/dashboard" /> : <Login {...props} /> }} /> */}
          {/* <Route exact path="/login" render={(props) => { return (user !== undefined) ? <Redirect to="/tabs/dashboard" /> : <Login {...props} /> }} /> */}
          <Route exact path="/registrieren" render={(props) => <Register {...props} />} />
          <Route exact path="/invite/:companyToken?" render={(props) => <Register {...props} />} />
          <Route exact path="/passwort-vergessen" render={(props) => <ForgotPassword {...props} />} />
          {/* @ts-ignore */}
          <Route exact path="/verify-email/:userId/:hash" render={(props) => <VerifyEmail {...props} />} />
          <Route exact path="/email-verifizieren" render={(props) => <PleaseVerifyEmail {...props} />} />
          {/* @ts-ignore */}
          <Route exact path="/password_reset/:passwordForgottenToken" render={(props) => <ResetPassword {...props} />} />
          <Route exact path="/unternehmen" render={(props) => <CompanyRegistration {...props} />} />

          {/* Legal Stuff */}
          <Route exact path="/impressum" render={(props) => <LegalNoticeUnauthenticated {...props} />} />
          <Route exact path="/datenschutz" render={(props) => <PrivacyUnauthenticated {...props} />} />
          <Route exact path="/haftungsausschluss" render={(props) => <DisclaimerPage {...props} />} />

          <Route path="/tabs" component={TabMenu} />

          {/* <Route path="/tabs" render={(props) => { return (user === undefined && !loading) ? <Redirect to="/login" /> : <TabMenu /> }} /> */}

          <Route exact path="/">
            <Redirect to="/tabs/dashboard" />
          </Route>

          <Route component={NotFoundPage} />


        </IonRouterOutlet>

      </IonReactRouter>
    </IonApp>
  )
};



const App: React.FC = () => {

  return (
    <ThemeProvider>
      <Provider store={store}>
        <IonicApp />
      </Provider>
    </ThemeProvider >
  )
}

export default App;
