import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonPage, IonRow, } from "@ionic/react";
import { RouteComponentProps } from "react-router";
import { PageContainer } from "../../components";

import "./official.scss"
import Footer from "../preAuth/components/Footer";

const LegalNoticeContent: React.FC = () => {
    return (
        <>
            <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
            <p>Deutsche S&auml;ge- und Holzindustrie Bundesverband e. V. (DeSH)<br />
                Chausseestra&szlig;e 99<br />
                Haus des Holzes<br />
                10115 Berlin</p>

            <p>Vereinsregister: VR 32721 B<br />
                Registergericht: Amtsgericht Berlin-Charlottenburg</p>

            <p><strong>Vertreten durch:</strong><br />
                Pr&auml;sident: J&ouml;rn Kimmich<br />
                Vizepr&auml;sident: Lutz Schmelter<br />
                Vizepr&auml;sident : Manuel Echtle</p>

            <h2>Kontakt</h2>
            <p>Telefon: +49 (0)30 - 2061 3990 - 0<br />
                Telefax: +49 (0)30 - 206 139 - 989<br />
                E-Mail: info@saegeindustrie.de</p>

            <h2>Umsatzsteuer-ID</h2>
            <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
                DE 291437230</p>

            <h2>Redaktionell verantwortlich</h2>
            <p>Lars Schmidt, Hauptgesch&auml;ftsf&uuml;hrer Deutsche S&auml;ge- und Holzindustrie Bundesverband e. V.</p>

            <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
            <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
        </>
    )
}

export const LegalNoticeAuthenticated: React.FC<RouteComponentProps> = () => {
    return (
        <IonPage>
            <PageContainer name="Impressum" backButton={false}>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonCard>
                                <IonCardHeader>
                                    <IonCardTitle className='headline'>
                                        Impressum
                                    </IonCardTitle>
                                </IonCardHeader>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>
                            <IonCard>
                                <IonCardContent >
                                    <div className="formattedText">
                                        <LegalNoticeContent />
                                    </div>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </PageContainer>
        </IonPage>
    )
}

export const LegalNoticeUnauthenticated: React.FC<RouteComponentProps> = () => {
    return (
        <IonPage>

            <IonContent>
                <div className="officialUnauthContainer">
                    <IonGrid>

                        <IonRow>
                            <IonCol>
                                <div style={{ textAlign: "center" }}>
                                    <img src="./assets/images/logo_woodbook_login_dark.png" alt="Woodbook Logo" />
                                </div>
                            </IonCol>
                        </IonRow>

                        <IonRow>
                            <IonCol>
                                <IonButton routerLink="/login" fill="clear">zum Login</IonButton>
                            </IonCol>
                        </IonRow>

                        <IonRow>
                            <IonCol>
                                <IonCard>
                                    <IonCardHeader>
                                        <IonCardTitle className='headline'>
                                            Impressum
                                        </IonCardTitle>
                                    </IonCardHeader>
                                </IonCard>
                            </IonCol>
                        </IonRow>

                        <IonRow>
                            <IonCol>
                                <IonCard>
                                    <IonCardContent>
                                        <div className="formattedText">
                                            <LegalNoticeContent />
                                        </div>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <Footer />
                </div>

            </IonContent>
        </IonPage>
    )
}