import { CapacitorHttp, HttpOptions, HttpResponse } from "@capacitor/core";
import baseHttpOptions from "../../util/http-common";
import { Preferences } from "@capacitor/preferences";

const getCompany = async (
  departments: number = 1,
  locations: number = 1,
  trainees: number = 1,
  trainers: number = 1,
  company_admins: number = 1,
  image = 1
) => {
  const url: string = `company?departments=${departments}&locations=${locations}&trainees=${trainees}&trainers=${trainers}&company_admins=${company_admins}&image=${image}`;

  const options: HttpOptions = await baseHttpOptions(url);
  const response: HttpResponse = await CapacitorHttp.get(options);
  const { data } = response;
  return data;
};

const editCompany = async (reqData: any) => {
  const options: HttpOptions = await baseHttpOptions("company/edit");

  options.data = reqData;

  const response: HttpResponse = await CapacitorHttp.post(options);
  const { data } = response;
  return data;
};

const addEmployee = async (reqData: any) => {
  const options: HttpOptions = await baseHttpOptions("create-user");

  options.data = reqData;

  const response: HttpResponse = await CapacitorHttp.post(options);
  const { data } = response;
  return data;
};

const deleteEmployee = async (reqData: any) => {
  const options: HttpOptions = await baseHttpOptions("delete-user");

  options.data = reqData;

  const response: HttpResponse = await CapacitorHttp.delete(options);
  const { data } = response;
  return data;
};

const connectTraineeAndTrainer = async (reqData: any) => {
  const options: HttpOptions = await baseHttpOptions("add-trainee-to-trainer");

  options.data = reqData;

  const response: HttpResponse = await CapacitorHttp.post(options);
  const { data } = response;
  return data;
};

const disconnectTraineeAndTrainer = async (reqData: any) => {
  const options: HttpOptions = await baseHttpOptions("remove-trainee-to-trainer-relation");

  options.data = reqData;

  const response: HttpResponse = await CapacitorHttp.post(options);
  const { data } = response;
  return data;
}

const addUserToLocationDepartment = async (reqData: any) => {
  const options: HttpOptions = await baseHttpOptions("add-user-to-location-department");

  options.data = reqData;

  const response: HttpResponse = await CapacitorHttp.post(options);
  const { data } = response;
  return data;
}

const createCompany = async (reqData: any) => {
  const options: HttpOptions = await baseHttpOptions("create-company");

  options.data = reqData;

  const response: HttpResponse = await CapacitorHttp.post(options);
  const { data } = response;
  return data;
}

const changeCompanyLogo = async (reqData: any) => {
  const { value } = await Preferences.get({ key: 'token' })

  const options: HttpOptions = await baseHttpOptions("image");
  options.data = reqData;
  options.dataType = "file";

  options.headers = {
    "Content-Type": "multipart/form-data",
    'Authorization': `Bearer ${value}`
  }

  const response: HttpResponse = await CapacitorHttp.post(options);

  const { data } = response;
  return data;
}

const CompanyService = {
  getCompany,
  editCompany,
  addEmployee,
  deleteEmployee,
  connectTraineeAndTrainer,
  disconnectTraineeAndTrainer,
  addUserToLocationDepartment,
  createCompany,
  changeCompanyLogo
};

export default CompanyService;
