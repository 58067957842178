import { IonCard, IonCardContent, IonIcon, IonItem, IonLabel, IonList } from '@ionic/react';
import { routes } from './routes';
import RoundedButton from '../roundedButton/RoundedButton';
import { logOutOutline } from 'ionicons/icons';
import { useLocation } from 'react-router-dom';

import "./Menu.scss";
import { useAppDispatch, useAppSelector } from '../../data/redux/hooks';
import { logout } from '../../data/redux/userSlice';

const SideMenuDesktop = () => {
    let path = useLocation().pathname;

    const dispatch = useAppDispatch();
    const { user } = useAppSelector((state) => state.user);

    return (
        <IonCard>
            <IonCardContent role='navigation' aria-label='Hauptmenü'>
                <IonList className='sideMenuList' lines="none" >
                    {user && routes.map(route => {
                        if (route.sideMenuDesktop && route.roles.includes(user.role)) {
                            return (
                                <IonItem routerLink={route.path} key={route.path} detail={true}>
                                    <IonLabel color={path.startsWith(route.path) ? "primary" : undefined}>{route.label}</IonLabel>
                                </IonItem>
                            )
                        }
                        return null;
                    })}
                </IonList>
                <div className='menuSpacer'></div>
                <RoundedButton type="button" onClick={() => dispatch(logout())}>
                    abmelden
                    <IonIcon icon={logOutOutline} />
                </RoundedButton>
            </IonCardContent>
        </IonCard>
    )
}

export default SideMenuDesktop;