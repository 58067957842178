import { IonAvatar, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonItem, IonList } from "@ionic/react"
import AzubiProgressBar from "../azubiProgressBar/AzubiProgressBar";
import { User } from "../../../models/userModels";

import "./AzubiEntries.scss";
import { useAppDispatch, useAppSelector } from "../../../data/redux/hooks";
import { useEffect } from "react";
import { getTrainees } from "../../../data/redux/traineeListSlice";
import useWindowDimensions from "../../../data/customHooks/useWindowDimensions";
/* import { useTraineesStats } from "../../../data/customHooks/useTraineeStats"; */


type UserEntryProps = {
    user: User
}

const UserEntry: React.FC<UserEntryProps> = ({ user }: UserEntryProps) => {
    const { width } = useWindowDimensions();

    return (
        <IonItem lines="none" routerLink={"/tabs/azubis/" + user.id} className="userEntryItem">

            <div className="userEntry">
                <div className="profile">
                    <IonAvatar>
                        <img src={user.image ? user.image : "./assets/images/profile_placeholder.jpg"} alt="Profilbild" />
                    </IonAvatar>
                    <div className="info">
                        <div className="name">{user.first_name} {user.name}</div>
                        <div className="attributeRow">
                            {user.job && <div>{user.job}</div>}
                            {(width > 500 && user.trainee_year !== 0) && <div>{user.trainee_year}. Lehrjahr</div>}
                        </div>
                    </div>
                </div>
                {user.stats && <AzubiProgressBar data={user.stats} />}
            </div>
        </IonItem>
    )
}


const OpenAzubiEntries: React.FC = () => {
    /* const trainees = useTraineesStats(); */
    const { traineesWithOpenEntries: trainees } = useAppSelector((state => state.traineeList))
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(getTrainees());
    }, [dispatch])

    return (
        <IonCard>
            <IonCardHeader>
                <IonCardTitle className="subheadline">Unkontrollierte Einträge</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <IonList>
                    {trainees.filter(trainee => trainee.stats && trainee.stats?.submitted > 0).length === 0 ? "Keine unkontrollierten Einträge vorhanden" :
                        trainees.filter(trainee => trainee.stats && trainee.stats?.submitted > 0).map(trainee => {
                            return (<UserEntry key={trainee.id} user={trainee} />)
                        })
                    }
                </IonList>
            </IonCardContent>
        </IonCard>
    )
}

export default OpenAzubiEntries;