import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Message } from "../../models/messageModel";
import MessageService from "../services/MessageService";
import { setError } from "./errorSlice";
import { error500Text } from "../../util/error-helper";

const initialState = {
  messages: undefined as Message[] | undefined,
  loading: false,
  error: undefined as String | undefined
};

interface MessageParams {
  amount: number;
  read: number;
}

export const retrieveLatestMessages = createAsyncThunk(
  "messages/get",
  async (data: MessageParams, { rejectWithValue, dispatch }) => {
    const { amount, read } = data;
    try {
      const res = await MessageService.getMessages(amount, read);
      if (res.error) {
        dispatch(setError({
          isOpen: true,
          message: res.error
        }))
        return rejectWithValue(res.error)
      } if (res.messages) {
        return res.messages;
      } else {
        dispatch(setError({
          isOpen: true,
          message: error500Text
        }))
        return rejectWithValue(error500Text)
      }
    } catch (err) {
      return rejectWithValue(error500Text)
    }
  }, {
  condition: (_data, { getState }) => {
    /* @ts-ignore */
    const { message } = getState()
    if (message.user || message.loading === true) {
      // Already fetched or in progress, don't need to re-fetch
      return false
    }
  },
});

export const markMessageAsRead = createAsyncThunk(
  "messages/markAsRead",
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      const res = await MessageService.markAsRead(id);
      if (res.error) {
        dispatch(setError({
          isOpen: true,
          message: res.error
        }))
        rejectWithValue(res.error)
      } else if (res.messages) {
        return res.messages;
      } else {
        dispatch(setError({
          isOpen: true,
          message: error500Text
        }))
        rejectWithValue(error500Text)
      }
    } catch (err) {
      dispatch(setError({
        isOpen: true,
        message: error500Text
      }))
      rejectWithValue(error500Text)
    }

  },
);

export const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /* retrieveLatestMessages */
    builder.addCase(retrieveLatestMessages.pending, (state) => {
      state.loading = true;
      state.error = undefined
    });
    builder.addCase(retrieveLatestMessages.fulfilled, (state, { payload }) => {
      state.messages = payload;
      state.loading = false;
    });
    builder.addCase(retrieveLatestMessages.rejected, (state, { payload }) => {
      state.loading = false;
      /* @ts-ignore */
      state.error = payload
    });

    /* markMessageAsRead */
    builder.addCase(markMessageAsRead.pending, (state) => {
      /* state.loading = true; */
      state.error = undefined
    });
    builder.addCase(markMessageAsRead.fulfilled, (state, { payload }) => {
      state.messages = payload;
      /* state.loading = false; */
    });
    builder.addCase(markMessageAsRead.rejected, (state, { payload }) => {
      /* state.loading = false; */
      /* @ts-ignore */
      state.error = payload
    });
  },
});

//export const { } = messageSlice.actions;

export default messageSlice.reducer;
