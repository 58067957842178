import { CapacitorHttp, HttpOptions, HttpResponse } from "@capacitor/core";
import { LoginFormData, RegisterFormData, ResetPasswordData } from "../../models/userModels";
import baseHttpOptions from "../../util/http-common";

const login = async (reqData: LoginFormData) => {

    const options: HttpOptions = await baseHttpOptions("login");

    options.data = {
        "email": reqData.email,
        "password": reqData.password
    }

    const response: HttpResponse = await CapacitorHttp.post(options);
    const { data } = response;
    return data;
}

const logout = () => {

}

const register = async (reqData: RegisterFormData) => {
    const options: HttpOptions = await baseHttpOptions("register");

    options.data = reqData;

    const response: HttpResponse = await CapacitorHttp.post(options);
    const { data } = response;
    return data;
}

const verifyEmail = async (id: string, hash: string) => {
    let url = `verify-email/${id}/${hash}`
    const options: HttpOptions = await baseHttpOptions(url);

    const response: HttpResponse = await CapacitorHttp.post(options);
    const { data } = response;
    return data;
}

const requestPasswordReset = async (email: string) => {
    let url = `request-password-reset?email=${email}`
    const options: HttpOptions = await baseHttpOptions(url);

    const response: HttpResponse = await CapacitorHttp.post(options);
    const { data } = response;
    return data;
}

const resetPassword = async (reqData: ResetPasswordData) => {
    const options: HttpOptions = await baseHttpOptions("password-reset");

    options.data = reqData;

    const response: HttpResponse = await CapacitorHttp.post(options);
    const { data } = response;
    return data;
}

const AuthService = {
    login,
    logout,
    register,
    verifyEmail,
    requestPasswordReset,
    resetPassword
}

export default AuthService;
