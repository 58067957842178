import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonIcon, IonItem, IonLabel, IonSkeletonText } from "@ionic/react"
import { Message } from "../../models/messageModel";
import { format } from "date-fns";

import "./Notifications.scss"
import { closeOutline } from "ionicons/icons";
import { useAppDispatch, useAppSelector } from "../../data/redux/hooks";
import { useCallback, useEffect } from "react";
import { markMessageAsRead, retrieveLatestMessages } from "../../data/redux/messageSlice";

interface MessageComponentProps {
    message: Message,
    markAsRead: (id: number) => void
}


const MessageComponent: React.FC<MessageComponentProps> = ({ message, markAsRead }: MessageComponentProps) => {
    return (
        <IonItem className="message">
            <IonLabel>
                <div className="messageHeader">
                    <div>
                        <div>
                            {format(new Date(message.updated_at), "dd.MM.yyyy")}
                        </div>
                        <div>{message.from_first_name} {message.from_name}</div>
                    </div>

                    <IonButton aria-label="Benachrichtigung schließen" size="small" fill="clear" color="medium" onClick={() => markAsRead(message.id)}>
                        <IonIcon icon={closeOutline} />
                    </IonButton>
                </div>
                <p className="messageContent ion-text-wrap">{message.message}</p>
            </IonLabel>
        </IonItem>
    )
}

const MessageSkeleton: React.FC = () => {
    return (
        <IonItem className="message">
            <IonLabel>
                <div className="messageHeader" style={{ marginBottom: 2 }}>
                    <div>
                       <IonSkeletonText animated={true} style={{ width: '62px' }}></IonSkeletonText> 
                       <IonSkeletonText animated={true} style={{ width: '80px' }}></IonSkeletonText> 
                    </div>
                    
                    {/* <IonButton aria-label="Benachrichtigung schließen" size="small" fill="clear" color="medium" >
                        <IonIcon icon={closeOutline} />
                    </IonButton> */}
                    <IonSkeletonText animated={true} style={{ width: '18px', marginRight: 10 }}></IonSkeletonText>
                </div>
                <p className="messageContent textTwoLiner ion-text-wrap">
                    <IonSkeletonText animated={true} style={{ width: '100%' }}></IonSkeletonText>
                    <IonSkeletonText animated={true} style={{ width: '100%' }}></IonSkeletonText>
                    <IonSkeletonText animated={true} style={{ width: '100%' }}></IonSkeletonText>
                </p>
            </IonLabel>
        </IonItem>
    )
}


const Notifications = () => {
    const dispatch = useAppDispatch();
    const amount = 3;

    const { messages, loading } = useAppSelector((state) => state.message)

    const handleMarkAsReadClick = (id: number) => {
        dispatch(markMessageAsRead(id));
    }

    const initMessages = useCallback(() => {
        dispatch(retrieveLatestMessages({ amount: 3, read: 0 }))
    }, [dispatch])

    useEffect(() => {
        if (!messages) {
            initMessages()
        }

    }, [initMessages, messages])


    const displaySkeleton = () => {
        const rows = [];
        for (let i = 0; i < amount; i++) {
            rows.push(i)
        }
        return rows;
    }

    return (
        <IonCard>
            <IonCardHeader>
                <IonCardTitle className="subheadline">Neueste Mitteilungen</IonCardTitle>
            </IonCardHeader>
            <IonCardContent className="messageContainer">
                {/* {loading && displaySkeleton()} */}
                {loading && displaySkeleton().map(skeleton => <MessageSkeleton key={skeleton} />)}
                {/* <MessageSkeleton/> */}
                {(messages && messages.length > 0 && !loading) && messages.map((message) => <MessageComponent key={message.id} message={message} markAsRead={handleMarkAsReadClick} />)}
                {(messages && messages.length === 0 && !loading) && <p>Keine Mitteilungen vorhanden</p>}
            </IonCardContent>
        </IonCard>
    )
}

export default Notifications;