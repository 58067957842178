import { IonAlert } from "@ionic/react"
import { useAppDispatch } from "../../../data/redux/hooks";
import { CompanyToken } from "../../../models/companyModels";
import { deleteTraineeInvitation } from "../../../data/redux/companySlice";

interface RemoveInvitationAlertProps {
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void,
    selectedInvitation?: CompanyToken
}

const RemoveInvitationAlert: React.FC<RemoveInvitationAlertProps> = ({ isOpen, setIsOpen, selectedInvitation }) => {
    const dispatch = useAppDispatch();

    const handleDelete = () => {
        if (selectedInvitation) {
            dispatch(deleteTraineeInvitation({ id: selectedInvitation.id }))
        }
    }

    return (
        <IonAlert
            isOpen={isOpen}
            header={`Eingeladenen Azubi entfernen`}
            message={`Sind Sie sicher, dass Sie <b>${selectedInvitation?.email}</b> entfernen möchten?`}
            buttons={[
                {
                    text: "Abbrechen",
                    role: "cancel"
                }, {
                    text: "Entfernen",
                    handler: handleDelete
                },
            ]}
            onDidDismiss={() => setIsOpen(false)}
        >
        </IonAlert>
    )
}

export default RemoveInvitationAlert;