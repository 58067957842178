import { IonItem, IonSelect, IonSelectOption } from "@ionic/react"
import { User } from "../../../models/userModels";

interface UserSelectProps {
    selectedUserId: string|number,
    setSelectedUserId: (id: string) => void
    userList: User[]
}

const UserSelect: React.FC<UserSelectProps> = ({ selectedUserId, setSelectedUserId, userList }) => {

    const handleUserSelect = (e: any) => {
        setSelectedUserId(e.detail.value);
    }

    return (
        <IonItem>
            <IonSelect value={String(selectedUserId)} onIonChange={handleUserSelect} cancelText="Abbrechen" interfaceOptions={{header: "Azubis"}}>
                {userList?.map(userEntry => {
                    return (
                        <IonSelectOption value={String(userEntry.id)} key={userEntry.id}>{userEntry.first_name} {userEntry.name}</IonSelectOption>
                    )
                })}
            </IonSelect>
        </IonItem>
    )
}

export default UserSelect;