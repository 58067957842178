import { CapacitorHttp, HttpOptions, HttpResponse } from "@capacitor/core";
import baseHttpOptions from "../../util/http-common";

const createDepartment = async (reqData: any) => {
  const options: HttpOptions = await baseHttpOptions("department/create");

  options.data = reqData;

  const response: HttpResponse = await CapacitorHttp.post(options);
  const { data } = response;
  return data;
};

const editDepartment = async (reqData: any) => {
  const options: HttpOptions = await baseHttpOptions("department/edit");

  options.data = reqData;

  const response: HttpResponse = await CapacitorHttp.post(options);
  const { data } = response;
  return data;
};

const deleteDepartment = async (reqData: any) => {
  const options: HttpOptions = await baseHttpOptions("department/delete");

  options.data = reqData;

  const response: HttpResponse = await CapacitorHttp.post(options);
  const { data } = response;
  return data;
};

const DepartmentService = {
  createDepartment,
  editDepartment,
  deleteDepartment,
};

export default DepartmentService;
